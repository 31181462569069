import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LinkedCasesList } from '@secca/shared/models/linkedCasesList';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';
import { CoordinationList, CoordinationListAdapter } from '@secca/shared/models/coordination-link';
import { CoordinationCaseDetail, CoordinationCaseDetailAdapter } from '@secca/shared/models/coordination-case-details';
import { ServiceOrder, ServiceOrderAdapter } from '@secca/shared/models/service-order/service-order';
import { CoordinationTransportServiceOrderExtension } from '@secca/shared/models/service-order/coordination-transport-service-order-extension';
import { ICCStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-stakeholders';
import { ServiceTypeEnum, StatusTypes } from '@secca/shared/models/enums';
import { ResultOf, ResultOfAdapter } from '@secca/shared/models/resultOf';

@Injectable({
  providedIn: 'root'
})
export class CoordinationCaseService extends BaseService  {

  constructor(private http: HttpClient,
              private settingsService: SettingsService,
              private coordinationListAdapter: CoordinationListAdapter,
              private coordinationCaseDetailAdapter: CoordinationCaseDetailAdapter,
              private serviceOrderAdapter: ServiceOrderAdapter,
              private resultOfAdapter: ResultOfAdapter
  ) {
    super(settingsService);
  }

  private coordinationCaseChanges = {};
  private coordinationCaseNumberChanges = {};

  public getLinkedCasesForCoordinationCase(caseId: string): Observable<CoordinationList[]> {
    return this.http
      .get(this.baseURL + 'coordination/linked-cases/' + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: LinkedCasesList[]) => data.map(item => this.coordinationListAdapter.adapt(item))));
  }

  public getCoordinationCasesForLinkedCase(caseId: string): Observable<CoordinationList[]> {
    return this.http
      .get(this.baseURL + 'coordination/coordination-cases/' + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: LinkedCasesList[]) => data.map(item => this.coordinationListAdapter.adapt(item))));
  }

  public countLinkedCasesByCoordinationCaseId(caseId: string): Observable<number> {
    return this.http
      .get<number>(this.baseURL + 'coordination/count/linked-cases/' + caseId, { headers: this.jsonHeaders });
  }

  public countCoordinationCasesByLinkedCaseId(caseId: string): Observable<number> {
    return this.http
      .get<number>(this.baseURL + 'coordination/count/coordination-cases/' + caseId, { headers: this.jsonHeaders });
  }

  public linkCases(coordinationCaseId: number, caseNumber: string ): Observable<ResultOf> {
    return this.http
    .post<ResultOf>(this.baseURL + 'coordination/link/' + coordinationCaseId + '/' + caseNumber , { headers: this.jsonHeaders })
    .pipe(map(item => this.resultOfAdapter.adapt(item)));
  }

  public deleteCasesFromCoordinationCase(id: number, deletedFrom: number): Observable<any> {
    return this.http
    .delete<any>(this.baseURL + 'coordination/link/' + deletedFrom + '/' + id);
  }

  public getCoordinationCasesChanges(caseId: string): Observable<number> {
    this.initCoordinationCaseChangesChangeBehaviorSubject(caseId);
    return this.coordinationCaseChanges[caseId].asObservable();
  }

  public coordinationCasesChanged(caseId: string, numberOfLinkes: number) {
    this.initCoordinationCaseChangesChangeBehaviorSubject(caseId);
    this.coordinationCaseChanges[caseId].next(numberOfLinkes);
  }

  private initCoordinationCaseChangesChangeBehaviorSubject(caseId: string) {
    if (this.coordinationCaseChanges[caseId] == null) {
      this.coordinationCaseChanges[caseId] = new BehaviorSubject(undefined);
    }
  }

  public getLinkedCaseDetails(linkedCaseId: string): Observable<CoordinationCaseDetail> {
    return this.http
      .get(this.baseURL + 'coordination/linked-case-details/' + linkedCaseId, { headers: this.jsonHeaders })
      .pipe(map((data: CoordinationCaseDetail) => this.coordinationCaseDetailAdapter.adapt(data)));
  }

  public getLinkedCasesDetails(coordinationCaseId: string): Observable<CoordinationCaseDetail[]> {
    return this.http
      .get(this.baseURL + 'coordination/linked-cases-details/' + coordinationCaseId, { headers: this.jsonHeaders })
      .pipe(map((data: CoordinationCaseDetail[]) => data.map(item => this.coordinationCaseDetailAdapter.adapt(item))));
  }

  public getCoordinationCaseServiceOrdersFromLinkedCase(linkedCaseId: number): Observable<ServiceOrder[]> {
    return this.http
      .get<ServiceOrder[]>(this.baseURL + 'coordination/coordination-service-orders/linked/' + linkedCaseId)
      .pipe(map((data: any[]) => data.map(item => this.serviceOrderAdapter.adapt(item))));
  }

  serviceOrderUsedInCoordinationTransportCase(serviceOrderId: number, serviceOrders: ServiceOrder[]): boolean {
    let serviceOrderUsedInCoordinationTransport = false;
    let iccStakeholders: ICCStakeholders[] = [];

    serviceOrders.filter(serviceOrder => serviceOrder.type === ServiceTypeEnum.COORDINATION_TRANSPORT).forEach(serviceOrders => {
      let coordinationTransportServiceOrderExtension: CoordinationTransportServiceOrderExtension = serviceOrders.extension as CoordinationTransportServiceOrderExtension;
      iccStakeholders.push(serviceOrders.status === StatusTypes.COMMITTED  ? coordinationTransportServiceOrderExtension.iccConfirmationFlightStakeholders : coordinationTransportServiceOrderExtension.iccExpectedFlightStakeholders);
    })
    iccStakeholders.forEach(stakeholders => {
      stakeholders.stakeholders.forEach(stakeholder => {
        if(+stakeholder.serviceOrderId === serviceOrderId) {
          serviceOrderUsedInCoordinationTransport = true;
        }
      })
    })

    return serviceOrderUsedInCoordinationTransport;
  }

  public getCoordinationCaseNumberChanges(caseId: string): Observable<string> {
    this.initCoordinationCaseNumberChangesChangeBehaviorSubject(caseId);
    return this.coordinationCaseNumberChanges[caseId].asObservable();
  }

  public coordinationCaseNumberChanged(caseId: string, caseNumber: string) {
    this.initCoordinationCaseNumberChangesChangeBehaviorSubject(caseId);
    this.coordinationCaseNumberChanges[caseId].next(caseNumber);
  }

  private initCoordinationCaseNumberChangesChangeBehaviorSubject(caseId: string) {
    if (this.coordinationCaseNumberChanges[caseId] == null) {
      this.coordinationCaseNumberChanges[caseId] = new BehaviorSubject(undefined);
    }
  }

  public getCoordinationCaseServiceOrdersFromLinkedServiceOrder(linkedCaseId: number, linkedServiceOrder: ServiceOrder): Observable<ServiceOrder> {
    return this.http
      .get<ServiceOrder[]>(this.baseURL + 'coordination/coordination-service-orders/linked/' + linkedCaseId)
      .pipe(
        map((data) => data.map(item => this.serviceOrderAdapter.adapt(item))),
        map((mappedServiceOrders: ServiceOrder[]) => mappedServiceOrders.find(so =>  this.linkedToIcc(linkedServiceOrder, mappedServiceOrders))));
  }

  linkedToIcc(linkedServiceOrder: ServiceOrder, iccServiceOrders: ServiceOrder[]) : ServiceOrder {
    if (!iccServiceOrders) {
      return null;
    }
    return iccServiceOrders.find(so =>  (so.extension as CoordinationTransportServiceOrderExtension).getFlightStakeHolders(so.status).hasEndUserServiceOrder(""+linkedServiceOrder.id));
  }

}
