import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';

import { LinkedCoordinationCaseModalComponent } from './linked-coordination-case-modal/linked-coordination-case-modal.component';
import { CoordinationCaseService } from '@secca/core/services/coordination-case.service';
import { CoordinationList } from '@secca/shared/models/coordination-link';
import { HandlingAreaStatusEnum } from '@secca/shared/models/enums';
import { CaseStateService } from '@secca/core/state-services/case-state.service';

@Component({
  selector: 'app-linked-coordination-cases',
  templateUrl: './linked-coordination-cases.component.html',
  styleUrls: ['./linked-coordination-cases.component.scss']
})
export class LinkedCoordinationCasesComponent {

  @Input()
  set caseId(CaseId: string) {
    if (CaseId !== undefined) {
      this._caseId = CaseId;
      this.getCoordinationCases();
    }
  }

  get caseId(): string {
    return this._caseId;
  }

  @Input() caseNumber: string;
  private _caseId: string;

  isCaseNumberAssigned = true;
  areLinkedCasesLoaded = false;
  existingLinkedCaseNumbersOnCase: string[] = [];
  coordinationList: CoordinationList[] = [];

  constructor(private modalService: NgbModal,
              private router: Router,
              private coordinationCaseService: CoordinationCaseService,
              public caseStateService: CaseStateService, ) { }
  linkModalRef: NgbModalRef;

  getCoordinationCases() {
    this.coordinationCaseService.getLinkedCasesForCoordinationCase(this.caseId).subscribe(
      result => {
        this.coordinationList = result;
        this.existingLinkedCaseNumbersOnCase = [this.caseNumber];
        this.coordinationList.forEach(linkedCase => this.existingLinkedCaseNumbersOnCase.push(linkedCase.caseNumber));
        this.areLinkedCasesLoaded = true;
      }
    );
  }

  redirectToCase(caseId: number) {
    this.router.navigate(['case', caseId]);
  }

  get HandlingAreaStatusEnum() {
    return HandlingAreaStatusEnum;
  }

  openModal() {
    this.linkModalRef = this.modalService.open(LinkedCoordinationCaseModalComponent);
    this.linkModalRef.componentInstance.caseNumberOnCase = this.caseNumber;
    this.linkModalRef.componentInstance.existingLinkedCaseNumbersOnCase = this.existingLinkedCaseNumbersOnCase;
    this.linkModalRef.componentInstance.linkCaseEvent.subscribe(
      caseNumberLinkedCase => {
        this.coordinationCaseService.linkCases(+this.caseId, caseNumberLinkedCase).subscribe(
          result => {
            if(result.success) {
              this.coordinationList.push(result.payload as CoordinationList);
              this.existingLinkedCaseNumbersOnCase = [this.caseNumber];
              this.coordinationList.forEach(linkedCase => this.existingLinkedCaseNumbersOnCase.push(linkedCase.caseNumber));
              this.coordinationCaseService.coordinationCasesChanged(this.caseId, (this.coordinationList.length));
              this.closeModal();
            }
            else {
               this.closeModal();
               let showWarningMessageDialog;
               if (result.errorCode === 'LINK_TO_COORDINATION_NOT_POSSIBLE') {
                showWarningMessageDialog = this.showWarningMessageDialog('case-basics-linked-case-coordination-case');
              } else if (result.errorCode === 'LINK_TO_MAJOR_INCIDENT_NOT_POSSIBLE') {
                showWarningMessageDialog = this.showWarningMessageDialog('case-basics-linked-case-major-incident-case');
              } else if (result.errorCode === 'LINKED_CASE_NOT_VALIDATED') {
                showWarningMessageDialog = this.showWarningMessageDialog('case-basics-linked-case-not-validated');
              } else if (result.errorCode === 'SECCA_CASE_NOT_FOUND_ERROR') {
                showWarningMessageDialog = this.showWarningMessageDialog(result.msg);
              }
              showWarningMessageDialog.componentInstance.closeModalEvent.subscribe(emittedEvent => {
                showWarningMessageDialog.close();
              });
            }
          }
        );
      });
    this.linkModalRef.componentInstance.closeModal.subscribe(
      _ => {
        this.closeModal();
        this.areLinkedCasesLoaded = true;
      },
      error => console.log(error)
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  deleteLink(id: number) {
    const showCloseWarningMessageDialog = this.showCloseWarningMessageDialog();
    showCloseWarningMessageDialog.componentInstance.closeModalEvent.subscribe(emittedEvent => {
      if (emittedEvent) {
        this.coordinationCaseService.deleteCasesFromCoordinationCase(id, +this.caseId).subscribe(
          _ => {
            this.coordinationList.splice(this.coordinationList.findIndex(item => item.caseId === id), 1);
            this.existingLinkedCaseNumbersOnCase = [this.caseNumber];
            this.coordinationList.forEach(linkedCase => this.existingLinkedCaseNumbersOnCase.push(linkedCase.caseNumber));
            this.coordinationCaseService.coordinationCasesChanged(this.caseId, (this.coordinationList.length));
          }
        );
      }
      showCloseWarningMessageDialog.close();
    });

    showCloseWarningMessageDialog.componentInstance.closeModalEvent.subscribe(
      _ => {
        showCloseWarningMessageDialog.close();
      },
      err => console.log(err)
    );
  }

  private showCloseWarningMessageDialog(): NgbModalRef {
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: '',
      text: 'case-basics-coordination-case-delete-link-to-case',
      footer: 'case-basics-coordination-case-delete-link-footer',
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: true,
    });
    return confirmCloseDialog;
  }

  private showWarningMessageDialog(warningText: string) : NgbModalRef {
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'default-modal-header',
      title: '',
      text: warningText,
      footer: warningText,
      yes: 'default-modal-dialog-yes',
      no: 'default-modal-dialog-no',
      isBody: true,
      isFooter: false,
    });
    return confirmCloseDialog;
  }
}
