import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { Coverage } from '../../../../shared/models/coverage';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ServiceOrderHoverModel } from './plan/service-order-hover/service-order-hover-model';
import { ReserveCoverage } from '@secca/shared/models/reserveCoverage';

@Injectable({
  providedIn: 'root',
})
export class CasePlansService {
  private fetchCaseCoverages = new BehaviorSubject(undefined);
  private fetchReserveCoverages = new BehaviorSubject(undefined);
  private addSingleServiceOrder = new BehaviorSubject(undefined);
  private editSingleServiceOrder = new BehaviorSubject(undefined);
  private deleteSingleServiceOrder = new BehaviorSubject(undefined);
  private hoverServiceOrder = new BehaviorSubject(undefined);
  private plansServiceOrders: ReplaySubject<ServiceOrder[]> = new ReplaySubject();

  public sendServiceOrders(serviceOrders: ServiceOrder[]) {
    this.plansServiceOrders.next(serviceOrders);
  }

  public getServiceOrders(): Observable<ServiceOrder[]> {
    return this.plansServiceOrders.asObservable();
  }

  public sendCoverages(caseCoverages: Coverage[]) {
    this.fetchCaseCoverages.next(caseCoverages);
  }

  public sendReserveCoverage(reserveCoverages: ReserveCoverage) {
    this.fetchReserveCoverages.next(reserveCoverages);
  }

  public getCoverages(): Observable<Coverage[]> {
    return this.fetchCaseCoverages.asObservable();
  }

  public getReserveCoverages(): Observable<ReserveCoverage> {
    return this.fetchReserveCoverages.asObservable();
  }

  public sendNewServiceOrder(caseCoverages: ServiceOrder) {
    this.addSingleServiceOrder.next(caseCoverages);
  }

  public getNewServiceOrder(): Observable<ServiceOrder> {
    return this.addSingleServiceOrder.asObservable();
  }

  public sendEditedServiceOrder(caseCoverages: ServiceOrder) {
    this.editSingleServiceOrder.next(caseCoverages);
  }

  public getEditedServiceOrder(): Observable<ServiceOrder> {
    return this.editSingleServiceOrder.asObservable();
  }

  public sendDeletedServiceOrder(serviceOrder: ServiceOrder) {
    this.deleteSingleServiceOrder.next(serviceOrder);
  }

  public getDeletedServiceOrder(): Observable<ServiceOrder> {
    return this.deleteSingleServiceOrder.asObservable();
  }

  public sendHoverServiceOrder(serviceOrderHoverModel: ServiceOrderHoverModel) {
    this.hoverServiceOrder.next(serviceOrderHoverModel);
  }

  public getHoverServiceOrder(): Observable<ServiceOrderHoverModel> {
    return this.hoverServiceOrder.asObservable();
  }
}
