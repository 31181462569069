import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { DropdownWithIconDisableDictionary } from '@secca/shared/models/dropdownWithIconDisableDictionary';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { CustomerProfileInfo } from '@secca/shared/models/customerProfileInfo';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { Location } from '@secca/shared/models/location';
import {
  OriginalParameters,
  SupplierDialogData
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/SupplierDialogData';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import {
  ServiceTypeEnum,
  ValidationTypeEnum
} from '@secca/shared/models/enums';
import { resumeAnimation } from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/animation';
import { Filter } from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/Filter';
import { ProviderService } from '@secca/core/services/providerService';
import {
  ContactItem,
  DocListItem,
  FacetPair,
  PartnerItem,
  SolrInput,
  SupplierSearchResponse
} from '@secca/shared/models/SupplierSearchResponse';
import { ThemePalette } from '@angular/material/core';
import { TreatmentServiceOrderExtension } from '@secca/shared/models/service-order/treatment-service-order-extension';
import { ProviderLookup } from '@secca/shared/models/SupplierLookup';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { UserDto } from '@secca/shared/models/userDto';
import { StakeholderType } from '@secca/case/components/case-stakeholders/stakeholderType';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import {
  SeccaSearchParams
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/search-service-provider-modal/seccaSearchParams';
import {
  SupplierSearchHelper
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/search-service-provider-modal/supplier-search-helper.service';
import { IncidentService } from '@secca/core/services/incident.service';
import {
  SuggesterComponent
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/search-service-provider-modal/suggester/suggester.component';
import { Element, SupplierServicesResponse } from '@secca/shared/models/SupplierServicesResponse';
import { combineLatest, firstValueFrom, forkJoin, Observable, of } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { ProviderHelper } from '@secca/shared/helpers/provider-helper';
import { MasterListService } from '@secca/core/services/masterlist-service';
import { PropertyTypeDb } from '@secca/shared/models/PropertyType';
import { SearchKeyword } from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/model/searchKeyword';
import { CaseService } from '@secca/core/services/case.service';
import {
  PartnerService
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/search-service-provider-modal/partner.service';


@Component({
  selector: 'app-search-service-provider-modal',
  templateUrl: './search-service-provider-modal.component.html',
  styleUrls: ['./search-service-provider-modal.component.scss'],
  animations: resumeAnimation
})


@AutoUnsubscribe
export class SearchServiceProviderModalComponent implements OnInit {
  private stakeholdersTypes: StakeholderType[];
  isErrors: boolean = false;
  noDataFound: boolean = false;
  isLoading: boolean = false;
  showError: string = '';
  totalNumberOfSuppliers: number;
  serviceOrdersOnCase: ServiceOrder[] = [];
  private supplierServiceListFiltered: Element[] = [];
  private competenciesListFiltered: Element[] = [];
  private supplierServices: SupplierServicesResponse;
  private competenciesList: SupplierServicesResponse;
  private countryListWithAlpha2Code: any[] = [];
  private formattedSearchString: string;
  private supplierServicesAll: FacetPair[];
  private competenciesListAll: FacetPair[];
  countNumberOfCountriesMarked: number = 0;
  countryInLocation: string;
  contactRoles: PropertyTypeDb[];



  constructor(
    private modalService: NgbModal,
    private serviceOrderService: ServiceOrderService,
    private insuranceService: InsuranceService,
    private supplierService: ProviderService,
    private dictionaryService: DictionaryService,
    private stakeholderService: StakeholderService,
    private supplierSearchHelper: SupplierSearchHelper,
    private incidentService: IncidentService,
    private masterListService: MasterListService,
    private caseService: CaseService,
    private partnerService: PartnerService
  ) {
    dictionaryService.getLoggedInUser().subscribe(user => {
      this.loggedInUser = user;
    });
  }


  private openStuff: Set<number> = new Set<number>();
  response: SupplierSearchResponse;

  @Output()
  closeSupplierSearch: EventEmitter<void> = new EventEmitter();
  @Input()
  serviceOrderId: number;
  @Input()
  supplierDialogData: SupplierDialogData;

  @ViewChild(SuggesterComponent)
  suggesterComponent: SuggesterComponent;

  originalParameters: OriginalParameters = {};

  suppliers: DocListItem[] = [];
  filteredSuppliers: DocListItem[] = [];

  filters: Filter[];

  countriesList: FacetPair[] = [];
  filteredCountries: FacetPair[] = [...this.countriesList];
  searchCountryString: string = '';
  checkedCountries: { [key: string]: boolean } = {};

  competencesList: FacetPair[] = [];
  checkedCompetences: { [key: string]: boolean } = {};
  competenciesSuggestionsListFilter: Set<string> = new Set<string>([]);

  servicesList: FacetPair[] = [];
  checkedServices: { [key: string]: boolean } = {};

  supplierTypeList: FacetPair[] = [];
  checkedSupplierTypes: { [key: string]: boolean } = {};
  supplierTypeSuggestionsListFilter: Set<string> = new Set<string>([]);

  supplierServiceList: FacetPair[] = [];
  checkedSupplierServices: { [key: string]: boolean } = {};
  supplierServicesSuggestionsListFilter: Set<string> = new Set<string>([]);

  recommendationList: FacetPair[] = [];
  checkedRecommendations: { [key: string]: boolean } = {};
  recommendationSuggestionsListFilter: Set<string> = new Set<string>([]);


  private responseInformation: SolrInput; // For statistics at some point

  serviceTypesDropDown: DropdownWithIconDisableDictionary[] = [];
  availableServiceOrderTypes: ServiceOrderType[];
  allServiceOrders: ServiceOrderType[];
  customerProfileInfo: CustomerProfileInfo;
  serviceLocation: Location = new Location();
  selectedSuppliersByUser: DocListItem[] = [];

  keywords: SearchKeyword[] = [];

  serviceTypeNotUsedInSearch: boolean = false;
  locationNotUsedInSearch: boolean = false;
  fuzzyTextMatchEnabled: boolean = false;

  slideColour: ThemePalette = 'warn';
  enabledColour: ThemePalette = 'primary';
  sliderValue: number = 0.5;
  suppliersToShow: number = 15;
  biggerThanZeroAndNotOver100: RegExp = new RegExp('^(100|[1-9][0-9]?)$');

  selectedServiceOrder: ServiceOrder = new ServiceOrder();
  highestDistanceToSupplier: number = 50;
  protected readonly ValidationTypeEnum = ValidationTypeEnum;

  loggedInUser: UserDto;
  private providerHelper: ProviderHelper;

  async ngOnInit() {
    await this.getServiceOrders(this.supplierDialogData);
    this.getStakeholderTypes();
    this.setupAccordians();
    await this.setupAppOnEntry();
  }

  private setCheckedCountry(country: string, value: boolean = true) {
    if (country) {
      const alpha2codeCountry = this.countryListWithAlpha2Code.find(country => country.isoAlpha2 === this.serviceLocation.countryCodeAlpha2);
      this.serviceLocation.country = alpha2codeCountry?.country; // Set the country name in english when showing location
      this.checkedCountries[alpha2codeCountry?.country] = value;
      this.countryInLocation = alpha2codeCountry?.country;
    }
  }

  async setupAppOnEntry() {
    if (!!this.supplierDialogData.serviceOrder) {
      this.selectedServiceOrder.type = this.supplierDialogData.serviceOrder?.type;
      let extension: TreatmentServiceOrderExtension = this.supplierDialogData.serviceOrder?.extension as TreatmentServiceOrderExtension;
      if (extension != null && !!extension.durations && extension.durations.length > 0) {
        this.serviceLocation = extension?.durations[0].location ? { ...extension.durations[0].location } : undefined;
        if (this.serviceLocation.country !== '') {
          this.setCheckedCountry(this.serviceLocation.country, true);
        }
        if (this.locationInvalid(this.serviceLocation)) {
          this.locationNotUsedInSearch = true;
        }
      } else {
        this.locationNotUsedInSearch = true;
      }
    } else {
      this.serviceTypeNotUsedInSearch = true;
    }

    if (this.locationInvalid(this.serviceLocation) && this.supplierDialogData.incidentId) {
      this.incidentService.getCaseIncident(this.supplierDialogData.incidentId).subscribe(incident => {
        this.serviceLocation = !!incident.currentLocation?.country ? incident.currentLocation : incident.incidentLocation;
        this.locationNotUsedInSearch = this.locationInvalid(this.serviceLocation);
        this.saveOriginalParameters();
        this.updateSearchObject();
      });
    } else {
      this.saveOriginalParameters();
      await this.updateSearchObject();
    }
  }

  private locationInvalid(location: Location): boolean {
    return (location.latitude === null && location.longitude === null) || (location.latitude === undefined && location.longitude === undefined) || (location.latitude === 0 && location.longitude === 0);
  }

  private saveOriginalParameters() {
    const alpha2codeCountry = this.countryListWithAlpha2Code.find(country => country.isoAlpha2 === this.serviceLocation.countryCodeAlpha2);
    this.serviceLocation.country = alpha2codeCountry?.country;

    this.originalParameters = {
      serviceOrder: this.supplierDialogData.serviceOrder ? JSON.parse(JSON.stringify(this.supplierDialogData.serviceOrder)) : null,
      keywords: [],
      sliderValue: 0.5,
      serviceTypeNotUsedInSearch: this.serviceTypeNotUsedInSearch,
      locationNotUsedInSearch: this.locationNotUsedInSearch,
      fuzzyTextMatchEnabled: this.fuzzyTextMatchEnabled,
      distance: 50,
      suppliersToShow: 15,
      country: this.serviceLocation.country,
      location: { ...this.serviceLocation }
    };
  }

  closeModalWindow(caseStakeholderId?: number) {
    if (!!caseStakeholderId) {
      this.supplierDialogData.caseStakeholderId = caseStakeholderId;
    }
    this.closeSupplierSearch.emit();
    this.modalService.dismissAll();
  }

  private getStakeholderTypes() {
    this.dictionaryService.getStakeholdersTypes().subscribe((result: StakeholderType[]) => {
      this.stakeholdersTypes = result;
    });
  }

  async getServiceOrders(supplierDialogData: SupplierDialogData) {
    const [info, types, serviceOrdersOnCase, allServiceOrders, contactRoles, apiResponse] = await Promise.all([
      firstValueFrom(this.insuranceService.getCustomerProfileInfo(supplierDialogData.caseId.toString())),
      firstValueFrom(this.serviceOrderService.getServiceOrderTypesPerCase(supplierDialogData.caseId.toString())),
      firstValueFrom(this.serviceOrderService.getServiceOrdersForCase(supplierDialogData.caseId)),
      firstValueFrom(this.serviceOrderService.getAllServiceOrderTypes()),
      firstValueFrom(this.masterListService.getContactRoles()),
      firstValueFrom(this.supplierService.countryNameAndAlpha2Api())
    ]);

    this.availableServiceOrderTypes = types;
    this.customerProfileInfo = info;
    this.serviceOrdersOnCase = serviceOrdersOnCase;
    this.allServiceOrders = allServiceOrders;
    this.contactRoles = contactRoles;
    this.countryListWithAlpha2Code = apiResponse.payload.elements;
    this.updateServiceTypesDropDown();
  }

  private getReducedSuppliersListAndCompetenciesList(): Observable<any> {
    return combineLatest([
      this.supplierService.searchSuppliersServices().pipe(
        catchError(error => {
          console.error('Error searching supplier services', error);
          this.openProviderModal(error, 'Error while searching supplier services');
          return of({});
        })
      ),
      this.supplierService.searchCompetences().pipe(
        catchError(error => {
          console.error('Error searching competences', error);
          this.openProviderModal(error, 'Error while searching competencies');
          return of({});
        })
      )
    ]).pipe(first(), tap(([supplierServices, competencies]) => {
        this.supplierServices = supplierServices as SupplierServicesResponse;
        this.competenciesList = competencies as SupplierServicesResponse;
        if (this.supplierServices && this.competenciesList) {
          this.updateFilterServicesAndCompetencies(this.supplierServices, this.competenciesList, this.supplierDialogData.serviceOrder?.type, this.supplierSearchHelper
          );
        }
      })
    );
  }

  private updateServiceTypesDropDown() {
    if (this.availableServiceOrderTypes) {
      this.serviceTypesDropDown = this.availableServiceOrderTypes
        .map(serviceOrderType => this.serviceOrderService.mapMasterlistServiceTypeToServiceOrderTypeWithIcons(serviceOrderType, this.customerProfileInfo.claimsAgreement, this.customerProfileInfo.medicalFlightAuthorizationAgreement, true));
    }
  }

  async setSelectedServiceType(serviceType: string): Promise<void> {
    await this.presentSuppliersByType(serviceType as ServiceTypeEnum);
  }

  async setSearchObjectAndSearch(changeOfLocation: boolean = false) {
    this.isLoading = true;
    this.searchCountryString = '';
    if (this.serviceLocation.country) {
      if (changeOfLocation) {
        Object.keys((this.checkedCountries)).forEach(key => delete this.checkedCountries[key]);
      }
      if (this.locationNotUsedInSearch) {
        this.openStuff.delete(5);
        this.setCheckedCountry(this.serviceLocation.country, false);
      } else {
        this.setCheckedCountry(this.serviceLocation.country, true);
      }
    } else {
      this.serviceLocation = new Location();
      this.setCheckedCountry(this.serviceLocation.country, false);
      this.searchCountryString = '';
      this.countryInLocation = '';
    }
    const searchParams: SeccaSearchParams = {
      freeText: this.keywords.filter(key => key.userEntered === true).map(keyword => `${keyword.text}`).join(' '),
      fuzzyText: this.fuzzyTextMatchEnabled,
      location: !this.locationNotUsedInSearch ? new Map<string, number>().set('latitude', this.serviceLocation?.latitude).set('longitude', this.serviceLocation?.longitude) : undefined,
      distance: this.highestDistanceToSupplier,
      serviceTypes: !this.serviceTypeNotUsedInSearch ? [this.translateFromServiceTypeToServiceOrderName(this.selectedServiceOrder.type)] : undefined,
      supplierServices: Object.keys(this.checkedSupplierServices).filter(supplierServiceKey => this.checkedSupplierServices[supplierServiceKey]),
      competences: Object.keys(this.checkedCompetences).filter(compKey => this.checkedCompetences[compKey]),
      recommendations: Object.keys(this.checkedRecommendations).filter(recommendationKey => this.checkedRecommendations[recommendationKey]),
      countries: Object.keys(this.checkedCountries).filter(countryKey => this.checkedCountries[countryKey]),
      distanceWeight: this.sliderValue,
      supplierType: Object.keys(this.checkedSupplierTypes).filter(supplierTypeKey => this.checkedSupplierTypes[supplierTypeKey]),
      rows: this.suppliersToShow
    };
    this.formattedSearchString = this.supplierSearchHelper.generateSearchQuery(searchParams);
    this.countNumberOfCountriesMarked = searchParams.countries.length;

    this.searchInSuppliersDB(this.formattedSearchString);
    this.matchAndMarkSupplierWithStakeholdersInCase();
  }

  async presentSuppliersByType(type: ServiceTypeEnum): Promise<void> {
    if (!this.selectedServiceOrder.type) {
      this.selectedServiceOrder.type = ServiceTypeEnum.TREATMENT;
    } else {
      this.selectedServiceOrder.type = type;
    }
    this.updateFilterServicesAndCompetencies(this.supplierServices, this.competenciesList, type, this.supplierSearchHelper);
    await this.updateSearchObject();
  }

  supplierSelectedByUser($event: string, rank: number) {
    this.filteredSuppliers.forEach(supplier => {
      if (supplier.id === $event) {
        supplier.ranking = rank + 1; // Rank is starting from 1
        supplier.selected = !supplier.selected;
        this.selectedSuppliersByUser.push(supplier);
        this.lookupAssistantPartnerForSuppliers(this.selectedSuppliersByUser);
      }
    });
  }


  private lookupAssistantPartnerForSuppliers(suppliers: DocListItem[]) {
    const partners = suppliers.map(supplier => this.lookupAssistantPartner(supplier));
    forkJoin(partners).subscribe({
      complete: () => {
        // Call backend with selected suppliers
        this.insertSuppliersFromNMS(this.selectedSuppliersByUser);
      }
    });
  }

  private lookupAssistantPartner(supplier: DocListItem): Observable<PartnerItem> {
    return new Observable(observer => {

      if (!supplier.partner_list) {
        observer.complete();
        return;
      }

      // Gets the case and finds the active partner on the validated date and if no use todays date
      this.caseService.getCase('' + this.supplierDialogData.caseId).subscribe((caseData) => {
        let caseValidatedDate = !caseData.validatedOn ? new Date() : caseData.validatedOn;
        const assistantPartner: PartnerItem = this.partnerService.findActivePartnerOnDate(supplier.partner_list, caseValidatedDate);
        // No assistant partner found
        if (!assistantPartner) {
          observer.complete();
          return;
        }

        // Search for assistant partner in NMS
        const searchString = this.supplierSearchHelper.generateSearchQuery({ rows: 1, freeText: 'supplierId:' + assistantPartner.partner_sosbk });
        this.supplierService.searchSuppliers(searchString).pipe(
          map(response => {
            if (response.docList.length > 0) {
              // Mark the partner as selected
              response.docList[0].selected = true;
              // Assistent partner found for supplier
              this.selectedSuppliersByUser.push(response.docList[0]);
            } else {
              // No assistant partner found for supplier
            }
            observer.complete();
          }),
          catchError(error => {
            console.error('Error searching for assistant partner', error);
            observer.error(error);
            return of();
          })
        ).subscribe();
      });
    });
  }

  private insertSuppliersFromNMS(selectedAndPreselectedSuppliers: DocListItem[]) {
    let providerLookup: ProviderLookup = this.supplierSearchHelper.mapDocsToProviders(selectedAndPreselectedSuppliers, this.supplierDialogData.caseId, this.supplierDialogData.serviceOrder?.type, this.loggedInUser, this.contactRoles, this.formattedSearchString);
    this.supplierService.addSupplierToCase(providerLookup).subscribe({
      next: (caseStakeholderId: number) => this.closeModalWindow(caseStakeholderId),
      error: (err) => {
        console.error('Error adding supplier to case', err);
        this.openProviderModal(err);
      }
    });
  }

  public toggle(id: number): void {
    if (this.openStuff.has(id)) {
      this.openStuff.delete(id);
    } else {
      this.openStuff.add(id);
    }
  }

  public isOpen(id: number): boolean {
    return this.openStuff.has(id);
  }

  public isOpenState(id: number): string {
    return this.isOpen(id) ? 'open' : 'close';
  }

  setupAccordians() {
    this.filters = [
      { id: 1, heading: 'Supplier Service', open: false },
      { id: 2, heading: 'Competencies', open: false },
      { id: 3, heading: 'Supplier Type', open: false },
      { id: 4, heading: 'Recommendation', open: false },
      { id: 5, heading: 'Country', open: false }
    ];

    this.filters.forEach(value => {
      if (value.open) {
        this.toggle(value.id);
      }
    });
  }

  private matchAndMarkSupplierWithStakeholdersInCase(): void {
    if (this.filteredSuppliers.length !== 0) {
      this.supplierDialogData.suppliers?.filter(value => value.isCompany)
        .map(value => +value.company.providerId);
      const supplierIdsOnCaseSelected: number[] = Array.from(new Set(this.serviceOrdersOnCase.map(value => value.supplierId)));
      const supplierIdsOnPlanAlready: CaseStakeholder[] = this.supplierDialogData.suppliers?.filter(value => {
        if (supplierIdsOnCaseSelected.includes(+value.id)) {
          return value.id;
        }
      });

      const caseStakeholderInDropDown = this.supplierDialogData.suppliers?.find(value => +value.id === this.supplierDialogData.serviceOrder.supplierId);

      this.filteredSuppliers.forEach(supplier => {
        if (supplierIdsOnPlanAlready?.find(value => value.providerReference == supplier.id)) {
          supplier.alreadyInCase = true;
        }
        if (caseStakeholderInDropDown && supplier.supplierId === +caseStakeholderInDropDown.company.providerId) {
          supplier.alreadySelected = true;
          supplier.alreadyInCase = false;
        }
      });
    }
  }

  private translateFromServiceTypeToServiceOrderName(type: ServiceTypeEnum): string {
    return this.allServiceOrders?.find(value => value.serviceTypeEnumValue === type)?.serviceTypeName;
  }

  private translateFromServiceTypeEnumToServiceTypeCode(serviceType: ServiceTypeEnum): string {
    return this.allServiceOrders?.find(value => value.serviceTypeEnumValue === serviceType)?.serviceTypeCode;
  }

  isAnyFilterInUse(): boolean {
    let checkedCountriesTemp = Object.entries(this.checkedCountries).filter(([key, value]) => key !== this.originalParameters.country);
    return !(
      !checkedCountriesTemp.some((([key, value]) => value === true)) &&
      !Object.entries(this.checkedServices).some((([key, value]) => value === true)) &&
      !Object.entries(this.checkedSupplierTypes).some((([key, value]) => value === true)) &&
      !Object.entries(this.checkedSupplierServices).some((([key, value]) => value === true)) &&
      !Object.entries(this.checkedCompetences).some((([key, value]) => value === true)) &&
      !Object.entries(this.checkedRecommendations).some((([key, value]) => value === true))
    );
  }

  async clearFilters() {
    Object.keys((this.checkedCountries)).forEach(key => delete this.checkedCountries[key]);
    Object.keys((this.checkedServices)).forEach(key => delete this.checkedServices[key]);
    Object.keys((this.checkedSupplierTypes)).forEach(key => delete this.checkedSupplierTypes[key]);
    Object.keys((this.checkedSupplierServices)).forEach(key => delete this.checkedSupplierServices[key]);
    Object.keys((this.checkedCompetences)).forEach(key => delete this.checkedCompetences[key]);
    Object.keys((this.checkedRecommendations)).forEach(key => delete this.checkedRecommendations[key]);
    this.filters.forEach(value => {
      this.openStuff.delete(value.id);
    });
    this.serviceLocation = { ...(this.originalParameters.location) };
    this.keywords = this.keywords.filter(keyword =>
      keyword.userEntered === true &&
      !(
        this.supplierServicesSuggestionsListFilter.has(keyword.text) ||
        this.supplierTypeSuggestionsListFilter.has(keyword.text) ||
        this.recommendationSuggestionsListFilter.has(keyword.text) ||
        this.competenciesSuggestionsListFilter.has(keyword.text)
      )
    );

    this.supplierServicesSuggestionsListFilter.clear();
    this.supplierTypeSuggestionsListFilter.clear();
    this.recommendationSuggestionsListFilter.clear();
    this.competenciesSuggestionsListFilter.clear();
    await this.updateSearchObject();
  }

  hasAnyParametersChanged(): boolean {
    if (this.originalParameters === undefined) {
      return false;
    }

    const {
      serviceTypeNotUsedInSearch,
      locationNotUsedInSearch,
      fuzzyTextMatchEnabled,
      serviceOrder,
      location,
      sliderValue,
      distance
    } = this.originalParameters;
    return this.serviceTypeNotUsedInSearch !== serviceTypeNotUsedInSearch ||
      this.locationNotUsedInSearch !== locationNotUsedInSearch ||
      this.fuzzyTextMatchEnabled !== fuzzyTextMatchEnabled ||
      this.selectedServiceOrder.type !== serviceOrder?.type ||
      (!this.locationInvalid(this.serviceLocation) &&
        (this.serviceLocation.latitude !== location.latitude ||
          this.serviceLocation.longitude !== location.longitude)) ||
      this.sliderValue !== sliderValue ||
      this.keywords.length > 0 ||
      this.highestDistanceToSupplier !== distance ||
      this.serviceLocation.country !== location.country ||
      this.suppliersToShow !== this.originalParameters.suppliersToShow;
  }


  setParametersToOriginal() {
    this.serviceTypeNotUsedInSearch = this.originalParameters.serviceTypeNotUsedInSearch;
    this.locationNotUsedInSearch = this.originalParameters.locationNotUsedInSearch;
    this.fuzzyTextMatchEnabled = this.originalParameters.fuzzyTextMatchEnabled;
    this.selectedServiceOrder.type = this.originalParameters.serviceOrder?.type;
    this.serviceLocation = { ...(this.originalParameters.location) };
    this.sliderValue = this.originalParameters.sliderValue;
    this.highestDistanceToSupplier = 50;
    this.suppliersToShow = 15;
    this.suggesterComponent.clearChips();
    this.keywords.forEach(key => {
      this.removeKeywordsFromCheckedListsInFilters(key);
    });
    this.keywords = [];
  }

  private searchInSuppliersDB(searchQuery: string) {
    this.supplierService.searchSuppliers(searchQuery).subscribe({
      next: (value) => {
        this.isErrors = false;
        this.suppliers = value?.docList?.filter(doc => doc.score > 0) || [];

        this.filteredSuppliers = this.serviceTypeNotUsedInSearch
          ? this.suppliers
          : this.suppliers.filter(supplier => supplier.serviceTypes?.includes(this.translateFromServiceTypeToServiceOrderName(this.selectedServiceOrder.type)));

        this.suppliers.forEach(supplier => {
          supplier.iconPath = this.findIconPathForStakeholder(this.stakeholdersTypes, +supplier.supplierTypeCode);
        });
        this.matchAndMarkSupplierWithStakeholdersInCase();
        this.supplierSearchHelper.markSuppliersAsBlacklisted(this.filteredSuppliers);
        this.partnerService.setAssistantPartnerOnSupplier(this.filteredSuppliers, this.supplierDialogData.caseId);
        this.responseInformation = value.solrInput;
        this.totalNumberOfSuppliers = value.numFound;

        this.servicesList = Array.from(value.facets?.aooServicesOnAoo?.values() || []).filter(value => value.first !== '');
        this.countriesList = Array.from(value.facets?.countries?.values() || []).filter(value => value.first !== '');
        this.supplierTypeList = Array.from(value.facets?.supplierType?.values() || []).filter(value => value.first !== '');
        this.recommendationList = Array.from(value.facets?.recommendations?.values() || []).filter(value => value.first !== '');
        this.supplierServicesAll = Array.from(value.facets?.supplierServices?.values() || []).filter(value => value.first !== '');
        this.competenciesListAll = value.facets?.competences;
        if (this.supplierServiceList.length === 0) { // If the supplier services and competencies are not loaded yet
          this.getReducedSuppliersListAndCompetenciesList().subscribe({
            next: () => {
              this.updateFilterServicesAndCompetencies(this.supplierServices, this.competenciesList, this.selectedServiceOrder.type, this.supplierSearchHelper);
            }
          });
        }
        this.filteredCountries = [...this.countriesList];
        this.isLoading = false;

        if (value.msg !== '') {
          console.error('Error connecting to supplier data: ', value.msg, searchQuery);
          this.showError = value.msg + ', ' + new Date().toLocaleDateString() + ', ' + new Date().toLocaleTimeString() + ', ' + searchQuery;
          this.isErrors = true;
        }
        if (value.numFound === 0) {
          console.error('No data found: ', value.msg, searchQuery);
          this.showError = value.msg + ' ' + new Date().toLocaleDateString() + ', ' + new Date().toLocaleTimeString() + ', ' + searchQuery;
          this.noDataFound = true;
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.error('Error connecting to supplier data: ', error.message, searchQuery);
        this.showError = 'Error connecting to supplier data: ' + error.message + ', ' + new Date().toLocaleDateString() + ', ' + new Date().toLocaleTimeString();
        this.isErrors = true;
      }
    });
  }

  private findIconPathForStakeholder(stakeholdersTypes: StakeholderType[], supplierTypeCode: number): string {
    const typeCode = supplierTypeCode;
    const stakeholderType = stakeholdersTypes.find(st => st.providerTypeId === typeCode);
    return this.stakeholderService.getStakeholderIconFullPath(stakeholderType?.stakeholderTypeEnum) || '/assets/icons/service-order-type/other.svg';
  }

  async updateSearchObject() {
    await this.setSearchObjectAndSearch();
  }

  compareKeywords(kw1: SearchKeyword, kw2: SearchKeyword): boolean {
    return kw1.text === kw2.text;
  }

  private removeKeywordsFromCheckedListsInFilters(keyword: SearchKeyword) {

    if (keyword.category === 'supplierServices') {
      this.checkedSupplierServices[keyword.text] = false;
      this.supplierServicesSuggestionsListFilter.delete(keyword.text);
    }
    if (keyword.category === 'supplierType') {
      this.checkedSupplierTypes[keyword.text] = false;
      this.supplierTypeSuggestionsListFilter.delete(keyword.text);
    }
    if (keyword.category === 'competences') {
      this.checkedCompetences[keyword.text] = false;
      this.competenciesSuggestionsListFilter.delete(keyword.text);
    }
    if (keyword.category === 'service_aoo_recommendation') {
      this.checkedRecommendations[keyword.text] = false;
      this.recommendationSuggestionsListFilter.delete(keyword.text);
    }
  }

  async keywordsEventFromSuggester($event: SearchKeyword[]) {
    const keywordDeleted = this.keywords.find(keyword =>
      !$event.some(eventItem => this.compareKeywords(eventItem, keyword))
    );

    if (keywordDeleted) {
      this.removeKeywordsFromCheckedListsInFilters(keywordDeleted);
    }

    this.keywords = [...$event];

    this.supplierServicesSuggestionsListFilter.clear();
    this.supplierTypeSuggestionsListFilter.clear();
    this.competenciesSuggestionsListFilter.clear();
    this.recommendationSuggestionsListFilter.clear();

    this.keywords
      .filter(keyword => !keyword.userEntered)
      .forEach(keyword => {
        switch (keyword.category) {
          case 'supplierServices':
            this.checkedSupplierServices[keyword.text] = true;
            this.supplierServicesSuggestionsListFilter.add(keyword.text);
            break;
          case 'supplierType':
            this.checkedSupplierTypes[keyword.text] = true;
            this.supplierTypeSuggestionsListFilter.add(keyword.text);
            break;
          case 'competences':
            this.checkedCompetences[keyword.text] = true;
            this.competenciesSuggestionsListFilter.add(keyword.text);
            break;
          case 'service_aoo_recommendation':
            this.checkedRecommendations[keyword.text] = true;
            this.recommendationSuggestionsListFilter.add(keyword.text);
            break;
        }
      });
    await this.updateSearchObject();
  }


  updateFilterServicesAndCompetencies(supplierServices: SupplierServicesResponse, competencies: SupplierServicesResponse, serviceTypeEnum: ServiceTypeEnum, supperSearchHelper: SupplierSearchHelper) {
    const serviceTypeCode = this.translateFromServiceTypeEnumToServiceTypeCode(serviceTypeEnum);
    this.supplierServiceListFiltered = supperSearchHelper.filterServiceDataElementsBasedOnServiceTypeCode(supplierServices, serviceTypeCode);
    this.supplierServiceList = Array.from(this.supplierSearchHelper.filterSupplierServicesBasedOnServiceName(this.serviceTypeNotUsedInSearch, this.supplierServicesAll, this.supplierServiceListFiltered).values() || []).filter(value => value.first !== '');
    const serviceTypeInFilteredServiceTypes = this.supplierServiceListFiltered?.map(value => value.serviceData.sosBk);
    this.competenciesListFiltered = supperSearchHelper.filterCompetenciesOnServiceTypes(competencies, serviceTypeInFilteredServiceTypes);
    this.competencesList = Array.from(this.supplierSearchHelper.filterCompetencies(this.serviceTypeNotUsedInSearch, this.competenciesListAll, this.competenciesListFiltered)?.values() || []).filter(value => value.first !== '');
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  private openProviderModal(error: any, title: string = 'Error') {
    this.providerHelper = new ProviderHelper(this.modalService);
    this.providerHelper.messageError(
      error, 'Error in call supplier search', title, error.message);
  }

  filterCountries() {
    this.openStuff.add(5);
    this.filteredCountries = this.countriesList.filter(country => country.first.toLowerCase().includes(this.searchCountryString.toLowerCase()));
  }

  async setSearchObjectAndSearch2(checkedService: string, list: string) {
    this.supplierServicesSuggestionsListFilter.delete(checkedService);
    this.supplierTypeSuggestionsListFilter.delete(checkedService);
    this.competenciesSuggestionsListFilter.delete(checkedService);
    this.recommendationSuggestionsListFilter.delete(checkedService);
    await this.updateSearchObject();
  }


  async setDisplayRowNumbers() {
    if (!this.biggerThanZeroAndNotOver100.test('' + this.suppliersToShow)) {
      this.suppliersToShow = 15;
    }
    await this.updateSearchObject();
  }
}
