import {Injectable} from '@angular/core';
import {Adapter} from '../interfaces/adapter';
import {EconomyStatusBarValues} from '@secca/shared/models/economyStatusBarValues';
import { DateSerializer } from '@secca/shared/models/date-serializer';
import { RecoveryStatus } from './enums';
import { RecoveryItem, RecoveryItemAdapter } from './recovery-Item';
import { RecoveryError, RecoveryErrorAdapter } from './recovery-error';
import * as moment from 'moment';
import { DaySerializer } from './day-serializer';
import { RecoveryEntryHistory } from './recoveryEntryHistory';

export class Recovery {
  id: number;
  caseId: number;
  customerProfileId: number;
  recoveryNumber: string;
  recoveryStatus: RecoveryStatus;
  dueDate: moment.Moment;
  applicationDeadline: moment.Moment;
  reasonForCancellation: string;
  internalRemark: string;
  recoveryType: string;
  description: string;
  totalAppliedAmount: number;
  totalRecoveredAmount: number;
  recoveryCurrency: string;
  templateName: string;
  templateLanguage: string;
  templateId: string;
  items: RecoveryItem[];
  errors: RecoveryError[];
  history: RecoveryEntryHistory[];
  createdBy: string;
  createdOn: moment.Moment;
  modifiedBy: string;
  modifiedOn: moment.Moment;
  modifiedUserName: string;
  deletedBy: string;
  deletedOn: moment.Moment;

  public constructor(init?: Partial<Recovery>) {
    Object.assign(this, init);
  }

  identicalHistory(recovery: Recovery): boolean {
    return this.internalRemark == recovery.internalRemark &&
            this.recoveryType == recovery.recoveryType &&
            this.recoveryStatus == recovery.recoveryStatus &&
            this.recoveryCurrency == recovery.recoveryCurrency;
  }

  getRedStatusText(): string {
    if ([RecoveryStatus.CANCELLED].includes(this.recoveryStatus)) {
      return this.recoveryStatus;
    }
    return RecoveryStatus.SETTLED;
  }

  isCancelled(): boolean {
       return this.recoveryStatus === RecoveryStatus.CANCELLED.toString();
  }

  static setStatusIndex(statusValues: EconomyStatusBarValues, status?: string) {
    if ( !status ) {
      return;
    }
    switch (status) {
      case RecoveryStatus.REQUEST_POA:  {
        statusValues.statusIndex = 1;
        break;
      }
      case RecoveryStatus.POA_REQUEST_SENT:
      case RecoveryStatus.SEND_POA_FIRST_REMINDER:
      case RecoveryStatus.POA_FIRST_REMINDER_SENT:
      case RecoveryStatus.SEND_POA_SECOND_REMINDER:
      case RecoveryStatus.POA_SECOND_REMINDER_SENT:
      case RecoveryStatus.POA_FOLLOW_UP: {
        statusValues.statusIndex = 2;
        break;
      }
      case RecoveryStatus.SEND_APPLICATION:
      case RecoveryStatus.POA_SIGNED: {
        statusValues.statusIndex = 3;
        break;
      }
      case RecoveryStatus.APPLICATION_SENT: {
        statusValues.statusIndex = 4;
        break;
      }
      case RecoveryStatus.APPLICATION_FOLLOW_UP: {
        statusValues.statusIndex = 5;
        break;
      }
      case RecoveryStatus.SETTLED:
      case RecoveryStatus.PAYMENT_IN_AS400:
      case RecoveryStatus.CANCELLED: {
        statusValues.statusIndex = 6;
        if ( status === RecoveryStatus.CANCELLED ) {
          statusValues.redIconIndex = 6;
        }
        break;
      }
      default: {
        console.warn('setStatusIndex with RecoveryStatus failed to determine status index. Returned value: ' + statusValues.statusIndex);
        break;
      }
    }
  }
}

export class SelectItem<T> {
  label: string;
  value: T;
  selected: boolean;

  public constructor(init?: Partial<SelectItem<T>>) {
    Object.assign(this, init);
  }
}

@Injectable({
    providedIn: 'root'
  })
  export class RecoveryAdapter implements Adapter<Recovery> {
    constructor(private recoveryItemAdapter: RecoveryItemAdapter,
                private recoveryErrorAdapter: RecoveryErrorAdapter){}

    adapt(item: any): Recovery {
      const recovery = new Recovery({
        id: item.id,
        caseId: item.caseId,
        customerProfileId: item.customerProfileId,
        recoveryNumber: item.recoveryNumber,
        recoveryStatus: item.recoveryStatus,
        recoveryType: item.recoveryType,
        dueDate: DaySerializer.deserialize(item.dueDate),
        applicationDeadline: DaySerializer.deserialize(item.applicationDeadline),
        reasonForCancellation: item.reasonForCancellation,
        internalRemark: item.internalRemark,
        description: item.description,
        totalAppliedAmount: item.totalAppliedAmount,
        totalRecoveredAmount: item.totalRecoveredAmount,
        recoveryCurrency: item.recoveryCurrency,
        templateName: item.templateName,
        templateLanguage: item.templateLanguage,
        templateId: item.templateId,
        items: !!item.items ? item.items.map(this.recoveryItemAdapter.adapt) : null,
        errors: !!item.recoveryErrors ? item.recoveryErrors.map(this.recoveryErrorAdapter.adapt) : [],
        createdBy: item.createdBy,
        createdOn: item.createdOn ? DateSerializer.deserialize(item.createdOn) : null,
        modifiedBy: item.modifiedBy,
        modifiedOn: item.modifiedOn ? DateSerializer.deserialize(item.modifiedOn) : null,
        modifiedUserName: item.modifiedUserName,
        deletedOn: item.deletedOn ? DateSerializer.deserialize(item.deletedOn) : null,
        deletedBy: item.deletedBy,
      });
      return recovery;
    }
  }
