import { Injectable } from '@angular/core';
import { BaseService } from '@secca/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@secca/core/services/settings.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {TravelInfoDto} from "@secca/case/components/case-travel-info/model/travelInfoDto";

@Injectable({
  providedIn: 'root'
})
export class TravelInfoService extends BaseService {
  constructor(private http: HttpClient, private settingsService: SettingsService) {
    super(settingsService);
  }

  public fetchTravelInfo(countryCode: string): Observable<TravelInfoDto> {
    return this.http.get<TravelInfoDto>(this.baseURL + `travel-info/${countryCode}`, { headers: this.jsonHeaders })
      .pipe(
        catchError((error) => {
          console.error('Error in fetchTravelInfo:', error);
          const noTravelInfo: TravelInfoDto = {};
          return of(noTravelInfo);
        })
      );
  }
}
