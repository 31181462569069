import { UpdateStakeholderQueueService } from './update-stakeholder-queue.service';
import { MessageTemplateAdapter } from './../../shared/models/message-template';
import { SettingsService } from './settings.service';
import { StakeholderService } from './stakeholder.service';
import { NoteDto } from 'src/app/shared/models/note';
import { DropdownDictionary } from 'src/app/shared/models/dropdownDictionary';
import {
  SimpleCaseStakeholder,
  SimpleCaseStakeholderAdapter,
  SimpleCaseStakeholderDropDownWrapper
} from './../../shared/models/simpleCaseStakeholder';
import { CaseUserSearchUserWrapper } from './../../shared/models/searchUser';
import { UserAdapter } from './../../shared/models/user';
import { CaseInsurance, CaseInsuranceAdapter } from './../../shared/models/case-insurance';
import { PersonInsurance } from './../../shared/models/person-insurance';
import { Timeline, TimeLineAdapter } from './../../shared/models/timeline';
import { Case, CaseAdapter } from './../../shared/models/case';
import { Note, NoteAdapter } from './../../shared/models/note';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';
import { CaseIncident, CaseIncidentAdapter } from '../../shared/models/caseIncident';
import { User } from 'src/app/shared/models/user';
import { OtherInsurance, OtherInsuranceAdapter } from './../../shared/models/otherInsurance';
import { CustomerNameAdapter, CustomerNameHolder, CustomerProfile, CustomerProfilesAdapter } from '@secca/shared/models/customerProfile';
import * as moment from 'moment';
import { CreateCase } from 'src/app/shared/models/createCase';
import { MessageBodyAdapter } from '../../modules/case/components/case-output-management/models/messageBody';
import { SensitiveType, StakeholderTypeEnum } from '@secca/shared/models/enums';
import { CaseStakeholder, CaseStakeholderAdapter } from '@secca/shared/models/caseStakeholder';
import { DaySerializer } from '@secca/shared/models/day-serializer';
import { ProductPurposesOfTravel, ProductPurposesOfTravelAdapter } from '@secca/shared/models/productPurposesOfTravel';
import { CaseLockHttpService } from './case-lock-http.service';
import { UpdateDateOfBirthOrAgeOrGenderOrNationalId, UpdateDateOfBirthOrAgeOrGenderOrNationalIdAdapter } from '@secca/shared/models/person';
import { UpdateOtherInsuranceService } from './update-other-insurance.service';
import { QueueService } from './queue.service';
import { EndUserStateService } from '@secca/core/state-services/enduser-state-service';
import { CaseValidationService } from '@secca/core/services/case-validation.service';
import { SimpleCustomerProfile, SimpleCustomerProfilesAdapter } from '@secca/shared/models/simpleCustomerProfile';
import { CustomerProfileDropdown, CustomerProfileDropdownAdapter } from '@secca/shared/components/multiple-select-customer-profile/customer-profile-search';
import { ServiceOrder, ServiceOrderAdapter } from '@secca/shared/models/service-order/service-order';

@Injectable({
  providedIn: 'root'
})
export class CaseService extends BaseService {
  private policyHolderChanges = new BehaviorSubject(null);
  private policyholderId: string;
  private caseStakeholder = new BehaviorSubject(undefined);
  private caseNotesChanges = new Subject<number>();
  private stakeholder: CaseStakeholder;
  private customerProfileSubscription = new BehaviorSubject(undefined);

  constructor(
    private http: HttpClient,
    private timeLineAdapter: TimeLineAdapter,
    private caseInsuranceAdapter: CaseInsuranceAdapter,
    private caseAdapter: CaseAdapter,
    private incidentAdapter: CaseIncidentAdapter,
    private noteAdapter: NoteAdapter,
    private userAdapter: UserAdapter,
    private serviceOrderAdapter: ServiceOrderAdapter,
    private caseStakeholderAdapter: CaseStakeholderAdapter,
    private simpleStakeholderAdapter: SimpleCaseStakeholderAdapter,
    private simpleCaseStakeholderDropDownWrapper: SimpleCaseStakeholderDropDownWrapper,
    private caseUserSearchUserWrapper: CaseUserSearchUserWrapper,
    private otherInsuranceAdapter: OtherInsuranceAdapter,
    private settingsService: SettingsService,
    private customerProfilesAdapter: CustomerProfilesAdapter,
    private simpleCustomerProfilesAdapter: SimpleCustomerProfilesAdapter,
    private customerNameAdapter: CustomerNameAdapter,
    private messageTemplateAdapter: MessageTemplateAdapter,
    private emailBodyAdapter: MessageBodyAdapter,
    private purposesOfTravelAdapter: ProductPurposesOfTravelAdapter,
    private stakeholderService: StakeholderService,
    private caseLockHttpService: CaseLockHttpService,
    private updateStakeholderQueueService: UpdateStakeholderQueueService,
    private updateOtherInsuranceService: UpdateOtherInsuranceService,
    private queueService: QueueService,
    private endUserstateService: EndUserStateService,
    private customerProfileDropdownAdapter: CustomerProfileDropdownAdapter,
    private caseValidationService: CaseValidationService) {
    super(settingsService);
  }

  dateTime: Observable<moment.Moment> = interval(1000).pipe(
    map(tick => moment.utc()),
    share()
  );

  public publishStakeholder(stakeholder: CaseStakeholder) {
    if (stakeholder.id === this.policyholderId) {
      this.publishPolicyholder(stakeholder);
    } else if (stakeholder.hasRole(StakeholderTypeEnum.endUser)) {
      this.endUserstateService.sendEndUser(stakeholder);
    }
  }

  public publishPolicyholder(policyholder: CaseStakeholder) {
    this.policyholderId = policyholder.id;
    this.policyHolderChanges.next(policyholder);
  }

  public observePolicyholder(): Observable<CaseStakeholder> {
    return this.policyHolderChanges.asObservable();
  }

  public publishCaseNotesChanges(id: number) {
    this.caseNotesChanges.next(id);
  }

  public observeCaseNotesChanges(): Observable<number> {
    return this.caseNotesChanges;
  }

  public getCase(id: string): Observable<Case> {
    return this.http.get(this.baseURL + 'cases/' + id).pipe(map(item => this.caseAdapter.adapt(item)));
  }

  public addNoteToCase(noteDto: NoteDto): Observable<any> {
    return this.http.post<any>(this.baseURL + 'notes', noteDto, {
      headers: this.jsonHeaders
    }).pipe(map((res: any) => {
        this.publishCaseNotesChanges(noteDto.entityId);
        return res;
    }));
  }

  public editNoteToCase(noteDto: NoteDto): Observable<any> {
    return this.http.post<any>(this.baseURL + 'notes/edit', noteDto, {
      headers: this.jsonHeaders
    }).pipe(map((res: any) => {
        this.publishCaseNotesChanges(noteDto.entityId);
        return res;
    }));
  }

  public copyNoteToCases(noteDto: NoteDto, caseIds: number[]): Observable<any> {
    return this.http.post<any>(this.baseURL + 'notes/copy/cases/' + caseIds, noteDto, {
      headers: this.jsonHeaders
    }).pipe(map((res: any) => {
      this.publishCaseNotesChanges(noteDto.entityId);
      return res;
    }));
  }

  public getCopiedCasesByNoteId(noteId: number): Observable<number[]> {
    return this.http
      .get<number[]>(this.baseURL + 'notes/copied-cases/note/' + noteId);
  }

  public isCaseErpReady(id: string): Observable<boolean> {
    return this.http.get<boolean>(this.baseURL + 'cases/erp-ready/' + id);
  }

  public createCase(createCase: CreateCase): Observable<any> {
    return this.http.post<Case>(this.baseURL + 'cases', createCase, {
      headers: this.jsonHeaders
    });
  }

  public userCreateExternalCase(caseId: number): Observable<any> {
    return this.http.post<Case>(this.baseURL + 'cases/web/create' , caseId, {
      headers: this.jsonHeaders
    });
  }

  public userCreateUnrelatedCase(caseId: number): Observable<any> {
    return this.http.post<Case>(this.baseURL + 'cases/unrelated/create' , caseId, {
      headers: this.jsonHeaders
    });
  }

  public addCaseWithCopyOfStakeholder(copyStakeholderId: string): Observable<any> {
    return this.http.post<Case>(this.baseURL + 'cases/createCaseWithCopiedOfExistingStakeholder/' + copyStakeholderId, {
      headers: this.jsonHeaders
    });
  }

  public addPersonInsurance(newPersonInsurance: PersonInsurance): Observable<PersonInsurance> {
    return this.http.post<PersonInsurance>(this.baseURL + 'insurances', newPersonInsurance, {
      headers: this.jsonHeaders
    });
  }

  public addCaseIncident(newCaseIncident: CaseIncident): Observable<CaseIncident> {
    return this.http.post<CaseIncident>(this.baseURL + 'caseIncident', newCaseIncident, {
      headers: this.jsonHeaders
    });
  }

  public addUserForCase(newUser: User): Observable<User> {
    return this.http.post<User>(this.baseURL + 'users', newUser, {
      headers: this.jsonHeaders
    });
  }

  public deleteCase(id: string): Observable<Case> {
    return this.http.delete<Case>(this.baseURL + 'case/' + id).pipe(map(item => this.caseAdapter.adapt(item)));
  }

  public deleteUser(caseId: string, userType: string): Observable<User> {
    return this.http.delete<User>(this.baseURL + `users?caseId=${caseId}&type=${userType}`).pipe(map(item => this.userAdapter.adapt(item)));
  }

  public getNewCaseNumber(): Observable<any> {
    return this.http.post<any>(this.baseURL + 'secca-cases', {
      headers: this.jsonHeaders
    });
  }

  public getServiceOrdersForCase(caseId: string): Observable<ServiceOrder[]> {
    return this.http
      .get<ServiceOrder[]>(this.baseURL + `service-orders/by-case/${caseId}`)
      .pipe(map((data: ServiceOrder[]) => data.map(item => this.serviceOrderAdapter.adapt(item))));
  }

  public getStakeholderForCase(caseId: string, type: string): Observable<CaseStakeholder> {
    return this.http
      .get(this.baseURL + 'cases' + '/' + caseId + '/stakeholders/type/' + type, { headers: this.jsonHeaders })
      .pipe(map(item => this.caseStakeholderAdapter.adapt(item)));
  }

  public getSimpleStakeholdersOnCase(caseId: string): Observable<SimpleCaseStakeholder[]> {
    return this.http
      .get(this.baseURL + 'cases' + '/' + caseId + '/simple-stakeholders', { headers: this.jsonHeaders })
      .pipe(map((data: SimpleCaseStakeholder[]) => data.map(item => this.simpleStakeholderAdapter.adapt(item))));
  }

  public getStakeholdersOnCase(caseId: string): Observable<CaseStakeholder[]> {
    return this.http
      .get<CaseStakeholder[]>(this.baseURL + 'cases' + '/' + caseId + '/stakeholders', {
        headers: this.jsonHeaders
      })
      .pipe(
        map(stakeholders => stakeholders.filter(stakeholder => !stakeholder.deactivated))
      )
      .pipe(map((data: CaseStakeholder[]) => data.map(item => this.caseStakeholderAdapter.adapt(item))));
  }

  public getAllStakeholdersOnCase(caseId: string): Observable<CaseStakeholder[]> {
    return this.http
      .get<CaseStakeholder[]>(this.baseURL + 'cases' + '/' + caseId + '/all-stakeholders', {
        headers: this.jsonHeaders
      })
      .pipe(map((data: CaseStakeholder[]) => data.map(item => this.caseStakeholderAdapter.adapt(item))));
  }

  public sendCaseStakeholderWithCaseStakeholder(caseStakeholder: CaseStakeholder[]) {
    this.caseStakeholder.next(caseStakeholder);
  }

  public getCaseStakeholderWithCaseStakeholder(): Observable<CaseStakeholder[]> {
    return this.caseStakeholder.asObservable();
  }

  public getCaseStakeholdersOnCase(caseId: string): Observable<CaseStakeholder[]> {
    return this.http
      .get<any[]>(this.baseURL + 'cases' + '/' + caseId + '/stakeholders', {
        headers: this.jsonHeaders
      })
      .pipe(map((data: CaseStakeholder[]) => data.map(item => this.caseStakeholderAdapter.adapt(item))));
  }

  public getCaseStakeholdersOnCaseByType(caseId: string, type: StakeholderTypeEnum): Observable<CaseStakeholder[]> {
    return this.getCaseStakeholdersOnCase(caseId)
      .pipe(
        map((data: CaseStakeholder[]) => data.filter(item => item.hasRole(type)))
      )
  }

  public deleteStakeholderOnCase(caseId: string, stakeholderId: string): Observable<any> {
    return this.caseLockHttpService.delete<any>(this.baseURL + 'cases/' + caseId + '/stakeholders/' + stakeholderId, { headers: this.jsonHeaders });
  }

  public isStakeholderDeletable(caseId: string, stakeholderId: string): Observable<any> {
    return this.http.get<any>(this.baseURL + 'cases/' + caseId + '/stakeholders/' + stakeholderId + '/isDeletable', {
      headers: this.jsonHeaders
    });
  }

  public UpdateStakeholderToCompany(id: string): Observable<any> {
    return this.caseLockHttpService.post<any>(this.baseURL + 'stakeholders/' + id + '/to-company', null, { headers: this.jsonHeaders })
      .pipe(map(stakeholder => new CaseStakeholderAdapter().adapt(stakeholder)));
  }

  public UpdateStakeholderToPerson(id: string): Observable<any> {
    return this.caseLockHttpService.post<any>(this.baseURL + 'stakeholders/' + id + '/to-person', null, { headers: this.jsonHeaders })
      .pipe(map(stakeholder => new CaseStakeholderAdapter().adapt(stakeholder)));
  }

  public updateStakeholderForCase(stakeholder: CaseStakeholder) {
    this.queueService.pushStakeholder(new CaseStakeholderAdapter().adapt(stakeholder));
    return this.updateStakeholderQueueService.actionSchedule();
  }

  public updateDateOfBirthOrAgeOrGenderOrNationalId(stakeholder: CaseStakeholder): Observable<UpdateDateOfBirthOrAgeOrGenderOrNationalId> {
    return this.caseLockHttpService.put<any>(this.baseURL + 'stakeholders/update/' + stakeholder.id, stakeholder,
    {headers: this.jsonHeaders})
    .pipe(map(personUpdate => new UpdateDateOfBirthOrAgeOrGenderOrNationalIdAdapter().adapt(personUpdate)));
  }

  formatDate(date: Date) {
    if (date == null) {
      return null;
    }
    return moment(date).format('YYYY-MM-DD');
  }

  public doSsnLookup(stakeholderId: string): Observable<CaseStakeholder> {
    return this.http
      .post<any>(this.baseURL + 'stakeholders/' + stakeholderId + '/ssn-lookup', null, { headers: this.jsonHeaders })
      .pipe(map(stakeholder => new CaseStakeholderAdapter().adapt(stakeholder)));
  }

  public createStakeholderOnCase(id: string, value: string): Observable<any> {
    const body = { stakeholderType: value };
    return this.http.post<any>(this.baseURL + 'cases/' + id + '/stakeholders', body, { headers: this.jsonHeaders });
  }

  public getUserForCase(caseId: string, userType: string): Observable<User> {
    return this.http
      .get(this.baseURL + `users?caseId=${caseId}&type=${userType}`)
      .pipe(map((data: any[]) => data.map(item => this.userAdapter.adapt(item))[0]));
  }

  public getUsersByTeam(adtCode: string): Observable<User[]> {
    return this.http
      .get(this.baseURL + 'users/' + adtCode)
      .pipe(map((data: any[]) => data.map(item => this.userAdapter.adapt(item))));
  }

  public updateUser(user: User): Observable<User> {
    return this.http.put<User>(this.baseURL + 'users\\' + user.id, user, { headers: this.jsonHeaders });
  }

  public getTimeline(): Observable<Timeline> {
    return this.http.get(this.baseURL + 'timeline').pipe(map(item => this.timeLineAdapter.adapt(item)));
  }

  public getCaseInsurance(caseId: string): Observable<CaseInsurance> {
    return this.http.get(this.baseURL + 'insurances/case/' + caseId).pipe(map(item => this.caseInsuranceAdapter.adapt(item)));
  }

  public updateOtherInsurance(otherInsurance: OtherInsurance) {
    this.queueService.pushOtherInsurance(new OtherInsuranceAdapter().adapt(otherInsurance));
    return this.updateOtherInsuranceService.actionSchedule();
  }

  public getOtherInsurance(caseId: string): Observable<OtherInsurance> {
    return this.http
      .get(this.baseURL + `otherinsurance/case/${caseId}`)
      .pipe(map((data: any[]) => data.map(item => this.otherInsuranceAdapter.adapt(item))[0]));
  }

  public addOtherInsurance(newPersonInsurance: OtherInsurance): Observable<OtherInsurance> {
    return this.http.post<OtherInsurance>(this.baseURL + 'otherinsurance', newPersonInsurance, {
      headers: this.jsonHeaders
    });
  }

  public getCustomerProfilesByCase(caseId: string): Observable<CustomerProfile[]> {
    return this.http
      .get<CustomerProfile[]>(this.baseURL + `customer-profiles/all/cases/${caseId}`)
      .pipe(map((data: any[]) => data.map(item => this.customerProfilesAdapter.adapt(item))));
  }

  public getCustomerProfiles(): Observable<CustomerProfile[]> {
    return this.http
      .get<CustomerProfile[]>(this.baseURL + `customer-profiles/customer-travel-profiles`)
      .pipe(map((data: any[]) => data.map(item => this.customerProfilesAdapter.adapt(item))));
  }

  public getCustomerProfilesForDropDown(): Observable<CustomerProfileDropdown[]> {
    return this.http
      .get<CustomerProfileDropdown[]>(this.baseURL + `profile/customer-travel-profiles`)
      .pipe(map((data: any[]) => data.map(item => this.customerProfileDropdownAdapter.adapt(item))));
  }

  public getSimpleCustomerProfiles(): Observable<SimpleCustomerProfile[]> {
    return this.http
      .get<SimpleCustomerProfile[]>(this.baseURL + `customer-profiles/simple-customer-travel-profiles`)
      .pipe(map((data: any[]) => data.map(item => this.simpleCustomerProfilesAdapter.adapt(item))));
  }

  public getAllSimpleCustomerProfiles(): Observable<SimpleCustomerProfile[]> {
    return this.http
      .get<SimpleCustomerProfile[]>(this.baseURL + `customer-profiles/all-simple-customer-travel-profiles`)
      .pipe(map((data: any[]) => data.map(item => this.simpleCustomerProfilesAdapter.adapt(item))));
  }

  public getCustomerProfileName(profileId: string): Observable<CustomerNameHolder> {
    return this.http.get<CustomerNameHolder>(this.baseURL + 'profile/' + profileId)
      .pipe(map(item => this.customerNameAdapter.adapt(item)));
  }

  private emitCustomerProfileChanged(caseCoverages: CustomerProfile) {
    this.customerProfileSubscription.next(caseCoverages);
  }

  public subscribeCustomerProfileChanged(): Observable<CustomerProfile> {
    return this.customerProfileSubscription.asObservable();
  }

  public getCustomerProfile(profileId: string): Observable<CustomerProfile> {
    return this.http.get<CustomerProfile>(this.baseURL + 'customer-profiles/customer-profile/' + profileId)
      .pipe(map(item => {
          this.emitCustomerProfileChanged(item);
          return this.customerProfilesAdapter.adapt(item);
      }));
  }

  public getMemberProfiles(profileId: string): Observable<CustomerProfile[]> {
    return this.http
      .get<CustomerProfile[]>(this.baseURL + `customer-profiles/member-profiles/` + profileId)
      .pipe(map((data: any[]) => data.map(item => this.customerProfilesAdapter.adapt(item))));
  }

  public getCustomerProduct(productId: number): Observable<any> {
    return this.http.get<string>(this.baseURL + 'products/' + productId.toString());
  }

  public getProfileProducts(profileId: number, incidentDate = moment.utc()): Observable<DropdownDictionary[]> {
    incidentDate = incidentDate || moment.utc();
    return this.http
      .get<DropdownDictionary[]>(this.baseURL + 'products/profile/' + profileId + '?activeOnly=true')
      .pipe(
        // PO Katrine does not want that changes in incident date can invalidate the chosen product, even though it can create a situation with an invalid chosen product in regard to incident date
        // map((data: any[]) => data.filter(product => incidentDate.isSameOrAfter(moment(product.validFrom, 'YYYY-MM-DD')) && (product.validTo == null || incidentDate.isSameOrBefore(moment(product.validTo, 'YYYY-MM-DD'))))),
        map((data: any[]) => data.filter(product => product.validTo == null || incidentDate.isSameOrBefore(moment(product.validTo, 'YYYY-MM-DD')))),
        map((data: any[]) => data.map(item => new DropdownDictionary(item.id, item.name))));
  }

  public getProductLevels(productId: any): Observable<DropdownDictionary[]> {
    return this.http.get<DropdownDictionary[]>(this.baseURL + 'products/' + productId + '/levels')
      .pipe(map((data: any[]) => data.map(item => new DropdownDictionary(item.id.toString(), item.sosInsuranceLevel)))
        , catchError(err => {
          return throwError(err);
        }));
  }

  public getProductGeoCoverageAreas(productId: any): Observable<DropdownDictionary[]> {
    return this.http.get<DropdownDictionary[]>(this.baseURL + 'products/' + productId + '/countryLists')
      .pipe(map((data: any[]) => data.map(item => new DropdownDictionary(item.id, item.name)))
        , catchError(err => {
          return throwError(err);
        }));
  }

  public getProductPurposesOfTravel(productId: number): Observable<ProductPurposesOfTravel> {
    return this.http.get(this.baseURL + `products/${productId}/purposesOfTravel`)
      .pipe(map(data => this.purposesOfTravelAdapter.adapt(data)));
  }

  public getBrandImageUrl(brandId: string): string {
    return this.baseURL + 'brand/' + brandId + '/icon';
  }

  public getBrandImagesUrlForProfile(profile: string): string {
    return this.baseURL + 'profile/' + profile + '/brand-icon';
  }

  public getNotes(): Observable<Note[]> {
    return this.http.get(this.baseURL + 'note').pipe(map((data: any[]) => data.map(item => this.noteAdapter.adapt(item))));
  }

  public updatePrimaryContact(caseId: string, stakeholderId: string): Observable<CaseStakeholder> {
    return this.caseLockHttpService.put<any>(this.baseURL + 'cases/' + caseId + '/stakeholdersIsPrimaryContact/' + stakeholderId, null, {
      headers: this.jsonHeaders
    })
      .pipe(map(sh => this.caseStakeholderAdapter.adapt(sh)));
  }

  public updateLegalGuardian(caseId: string, stakeholderId: string): Observable<CaseStakeholder> {
    return this.caseLockHttpService.put<any>(this.baseURL + 'cases/' + caseId + '/stakeholdersIsLegalGuardian/' + stakeholderId, null, {
      headers: this.jsonHeaders
    })
      .pipe(map(sh => this.caseStakeholderAdapter.adapt(sh)));
  }

  public mergeStakeholders(caseId: string, absorbing: string, absorbed: string): Observable<CaseStakeholder> {
    return this.caseLockHttpService.post<any>(this.baseURL + 'cases/' + caseId + '/merge/' + absorbing + '/is-also/' + absorbed, null, {
      headers: this.jsonHeaders
    })
      .pipe(tap(_ => this.caseValidationService.sendVerifyCaseNumberAssigned(caseId)))
      .pipe(map(item => this.caseStakeholderAdapter.adapt(item)));

  }

  public unmergeStakeholders(caseId: string, existing: string, emerging: string) {
    return this.caseLockHttpService.post<any>(this.baseURL + 'cases/' + caseId + '/unmerge/' + existing + '/is-no-longer/' + emerging, null, {
      headers: this.jsonHeaders
    })
      .pipe(tap(_ => this.caseValidationService.sendVerifyCaseNumberAssigned(caseId)))
      .pipe(map(item => this.caseStakeholderAdapter.adapt(item)));
  }

  public getCaseStakeholder(caseId: string, stakeholderId: string): Observable<CaseStakeholder> {
    return this.http
    .get<any>(this.baseURL + 'cases/' + caseId + '/getcasestakeholder/' + stakeholderId, { headers: this.jsonHeaders })
    .pipe(map(item => this.caseStakeholderAdapter.adapt(item)));
  }

  public updateCoTraveler(caseId: string, stakeholderId: string): Observable<any> {
    return this.caseLockHttpService.put<any>(this.baseURL + 'cases/' + caseId + '/updateIsCoTraveler/' + stakeholderId, {
      headers: this.jsonHeaders
    })
      .pipe(map(sh => this.caseStakeholderAdapter.adapt(sh)));
  }


  public updateSensitiveType(caseId: string, sensitiveType: SensitiveType, cause: string): Observable<any> {
    return this.caseLockHttpService.put<any>(this.baseURL + 'cases/case-sensitive-types/' + caseId + '/' + sensitiveType, cause, {
      headers: this.jsonHeaders
    })
  }

  public addStakeholderToCaseByType(caseId: string, stakeholderType: string) {
    return this.caseLockHttpService.post<any>(this.baseURL + 'cases/' + caseId + '/stakeholders/addType/' + stakeholderType, {
      headers: this.jsonHeaders
    });
  }

  public getCaseReportDate(caseId: string): Observable<moment.Moment> {
    return this.http.get(this.baseURL + 'cases/report-date/' + caseId)
      .pipe(map(reportDate => DaySerializer.deserialize(reportDate.toString())));
  }

  teamNameShortName(assignInitials: string): string {
    var returnAssignInitials = assignInitials;

    switch (assignInitials?.trim()) {
      case 'Team Expat & BTI': {
        returnAssignInitials = 'Team Expat';
        break;
      }
      case 'Team Operational Excellence': {
        returnAssignInitials = 'Team OPEX';
        break;
      }
      case 'Team Transport': {
        returnAssignInitials = 'Team TC';
        break;
      }
      default: {
        returnAssignInitials = assignInitials
        break;
      }
    }
    return returnAssignInitials;
  }
}

