<div class='container mt-2 travel-info-container' *ngIf="!!travelInfo">
  <div>
    <div *ngIf="!!travelInfo.lastUpdated; then travelInfoFetched else noTravelInfo"></div>
  </div>
  <ng-template #travelInfoFetched>
    <header class="header-travel">
      {{ 'Travel_Info' | translate }}
    </header>
    <div class='line'></div>
    <div class="text-center">
      <div>
        {{ 'travel-info-last-updated' | translate }}
      </div>
      <div>
        {{ travelInfo.lastUpdated | date:'medium' }}
      </div>
    </div>
    <div class="travel-info-button text-center" (click)="openTravelGuide()">
      {{ 'travel-info-um-link' | translate }}
      <div class='travel-info-popup'>
        <div class='popup-content'>
          <div *ngFor='let info of travelInfo.travelInfo' class='card mb-3'>
            <div class='card-header' [ngClass]="{
            'bg-success text-white': info.severity === 'GREEN',
            'bg-warning': info.severity === 'YELLOW',
            'bg-orange text-white': info.severity === 'ORANGE',
            'bg-danger text-white': info.severity === 'RED'
          }">
              <h5 class='mb-0'>{{ info.headLine }}</h5>
            </div>
            <div class='card-body'>
              <h6 class='card-subtitle mb-2 text-muted'>{{ info.summary }}</h6>
              <p class='card-text'>{{ info.bodyText }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #noTravelInfo>
    <div class='error-container'>
      <p>{{ 'travel-info-not-accessible' | translate }}</p>
      <p>
        {{'travel-info-goto'  | translate }}
        <a href="{{travelInfo?.sourceUrl ? travelInfo.sourceUrl : 'https://um.dk/rejse-og-ophold'}}" target="_blank">{{'travel-info-um-rejse-og-ophold' | translate  }}</a>
        {{'travel-info-post-goto'  | translate }}
      </p>
    </div>
  </ng-template>
</div>
