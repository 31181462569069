import { StakeholderTypeEnum } from './../../shared/models/enums';
import { ContactPersonAdapter } from './../../shared/models/contactPerson';
import { ContactPerson } from 'src/app/shared/models/contactPerson';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PhoneNumber } from '../../shared/models/phoneNumber';
import { PhoneNumberType } from '../../shared/models/enums';
import { Language, LanguageAdapter } from '@secca/shared/models/language';
import { CaseStakeholder, CaseStakeholderAdapter } from '@secca/shared/models/caseStakeholder';
import { CaseLockHttpService } from './case-lock-http.service';
import { SimpleCaseStakeholder, SimpleCaseStakeholderAdapter } from '@secca/shared/models/simpleCaseStakeholder';
import { JsonPatch } from '@secca/shared/models/json-patch';
import { PropertyType } from '@secca/shared/models/PropertyType';

@Injectable({
  providedIn: 'root'
})
export class StakeholderService extends BaseService {

  jsonPatchHeaders =  new HttpHeaders({ 'Content-Type': 'application/json-patch+json' });
  private contactPersonPropertyTypes: PropertyType[];

  constructor(
    private http: HttpClient,
    private caseStakeholderAdapter: CaseStakeholderAdapter,
    private languageAdapter: LanguageAdapter,
    private simpleCaseStakeholderAdapter: SimpleCaseStakeholderAdapter,
    private settingsService: SettingsService,
    private contactPersonAdapter: ContactPersonAdapter,
    private caseLockHttpService: CaseLockHttpService
  ) {
    super(settingsService);
  }

  private preferredLanguages: Language[];
  private preferredChannels: string[];
  private roleTypeNames: string[];

  public getLatestUpdatedVersionOfStakeholder(stakeholderId: number): Observable<CaseStakeholder> {
    return this.http
      .get(this.baseURL + 'stakeholders' + '/' + 'latest' + '/' + stakeholderId, { headers: this.jsonHeaders })
      .pipe(map(item => this.caseStakeholderAdapter.adapt(item)));
  }

  public getStakeholder(stakeholderId: number): Observable<CaseStakeholder> {
    return this.http
      .get(this.baseURL + 'stakeholders' + '/' + stakeholderId, { headers: this.jsonHeaders })
      .pipe(map(item => this.caseStakeholderAdapter.adapt(item)));
  }

  public getSimpleStakeholder(stakeholderId: number): Observable<SimpleCaseStakeholder> {
    return this.http
      .get(this.baseURL + 'stakeholders' + '/' + stakeholderId + '/simple', { headers: this.jsonHeaders })
      .pipe(map(item => this.simpleCaseStakeholderAdapter.adapt(item)));
  }

  public addContactPerson(contactPerson: ContactPerson, companyId: string): Observable<any> {
    return this.caseLockHttpService.post(this.baseURL + 'contact-persons/create-and-add-to-company/' + companyId, contactPerson, {
      headers: this.jsonHeaders
    });
  }

  public getContactsPersons(companyId: string): Observable<ContactPerson[]> {
    return this.http
      .get(this.baseURL + 'contact-persons/all-from-company/' + companyId)
      .pipe(map((data: any[]) => data.map(item => this.contactPersonAdapter.adapt(item))));
  }

  public updateContactPersons(id: string, contactPerson: ContactPerson): Observable<any> {
    return this.caseLockHttpService.patch(this.baseURL + 'contact-persons/' + id, contactPerson, {
      headers: this.jsonHeaders
    });
  }

  public deleteContactPersons(id: string, caseId: string): Observable<any> {
    return this.caseLockHttpService.delete(this.baseURL + 'contact-persons/' + id + '/' + caseId, {
      headers: this.jsonHeaders
    });
  }


  public removeNationalIdCountry(id: string): Observable<CaseStakeholder> {
    return this.caseLockHttpService
      .patch(this.baseURL + 'stakeholders/' + id,
        [JsonPatch.remove('/person/nationalIdCountry')], { headers: this.jsonPatchHeaders })
      .pipe(map(item => this.caseStakeholderAdapter.adapt(item)));
  }

  public removeHomeCountry(id: string): Observable<CaseStakeholder> {
    return this.caseLockHttpService
      .patch(this.baseURL + 'stakeholders/' + id,
        [JsonPatch.remove('/address/country')], { headers: this.jsonPatchHeaders })
      .pipe(map(item => this.caseStakeholderAdapter.adapt(item)));
  }

  public patchStakeholder(id: string, patches: JsonPatch[]): Observable<CaseStakeholder> {
    return this.caseLockHttpService
      .patch(this.baseURL + 'stakeholders/' + id,
        patches, { headers: this.jsonPatchHeaders })
      .pipe(map(item => this.caseStakeholderAdapter.adapt(item)));
  }

  public isCompanyStakeholderMedicalCategory(type: string): boolean {
    if (
      type === StakeholderTypeEnum.doctorOutpatientClinic ||
      type === StakeholderTypeEnum.hospital ||
      type === StakeholderTypeEnum.dentist
    ) {
      return true;
    } else {
      return false;
    }
  }

  public getAllowedAgeForLegalGuardian(countryCode: string): number {
    let allowedAge: number;
    switch (countryCode) {
      case 'DK':
      case 'SE':
      case 'FI':
        allowedAge = 15;
        break;
      case 'NO':
      default:
        allowedAge = 16;
        break;
    }
    return allowedAge;
  }
  public getNationalIdMask(nationalCountryCode: string, nationalId: string): string {
    let result;
    if (nationalCountryCode === 'DK' || nationalCountryCode === 'SE') {
      result = nationalId.substr(0, 7) + 'XXXX';
    }
    if (nationalCountryCode === 'NO' || nationalCountryCode === 'FI' || nationalCountryCode === 'IS') {
      result = nationalId.substr(0, 6) + 'XXXX';
    }
    if (nationalCountryCode === 'FO') {
      result = nationalId.substr(0, 6) + 'XXX';
    }
    if (nationalCountryCode === 'LV') {
      result = nationalId.substr(0, 6) + 'XXXXX';
    }
    if (nationalCountryCode === 'EE' || nationalCountryCode === 'LT') {
      result = 'X' + nationalId.substr(1, 7) + 'XXXX';
    }
    return result;
  }

  public initPreferredLanguagesAndChannels() {
    this.http
      .get(this.baseURL + 'stakeholders/preferred-channels')
      .pipe(map((data: any[]) => data.map(item => item.code))).subscribe(result =>
      this.preferredChannels = result);
    this.http
      .get(this.baseURL + 'stakeholders/preferred-languages')
      .pipe(map((data: any[]) => data.map(item => this.languageAdapter.adapt(item)))).subscribe(result =>
      this.preferredLanguages = result);
  }

  public getPreferredChannels() {
    if (this.preferredChannels == null) {
      this.initPreferredLanguagesAndChannels();
      return null;
    } else {
      return this.preferredChannels;
    }
  }

  public getPreferredLanguages(): Language[] {
    if (this.preferredLanguages == null) {
      this.initPreferredLanguagesAndChannels();
      return null;
    } else {
      return this.preferredLanguages;
    }
  }

  public getPreferredLanguageForStakeholder(stakeholder: CaseStakeholder): Language {
    if (stakeholder.isCompany) {
      if (stakeholder.company.gop) {
        return this.preferredLanguages.find(a => a.code === stakeholder.company.gop.preferredLanguageCode);
      } else {
        return null;
      }
    } else {
      return this.preferredLanguages?.find(a => a.code === stakeholder.person.preferredLanguageCode);
    }
  }

  getPhoneNumber(phoneNumberType: PhoneNumberType, phoneNumbers: PhoneNumber[]): PhoneNumber {
    const phoneNumber = phoneNumbers.find(phonenumber => phonenumber.type === phoneNumberType);
    if (phoneNumber) {
      return phoneNumber;
    } else {
      const newPhoneNumber = new PhoneNumber();
      newPhoneNumber.type = phoneNumberType;
      return newPhoneNumber;
    }
  }

  setPhoneCountryCode(phoneNumberType: PhoneNumberType, value: any, phoneNumbers: PhoneNumber[]): PhoneNumber[] {
    const phonenumer = phoneNumbers.find(phonenumber => phonenumber.type === phoneNumberType);
    if (phonenumer && !phonenumer.countryCode) {
      phonenumer.prefix = value.phonePrefix;
      phonenumer.countryCode = value.code;
    }
    return phoneNumbers;
  }

  setPhonenumber(phoneNumberType: PhoneNumberType, value: any, phoneNumbers: PhoneNumber[]): PhoneNumber[] {
    const setPhoneNumber = phoneNumbers.find(phonenumber => phonenumber.type === phoneNumberType);
    if (setPhoneNumber) {
      setPhoneNumber.countryCode = value.phoneCountryCode;
      setPhoneNumber.number = value.phoneNumber;
      setPhoneNumber.prefix = value.phonePrefix;
    } else {
      const newPhoneNumber = new PhoneNumber();
      newPhoneNumber.type = phoneNumberType;
      newPhoneNumber.number = value.phoneNumber;
      newPhoneNumber.prefix = value.phonePrefix;
      newPhoneNumber.countryCode = value.phoneCountryCode;
      phoneNumbers.push(newPhoneNumber);
    }
    return phoneNumbers;
  }

  public getStakeholderIconFullPath(stakeholderTypeEnum: any): string{
    return '/assets/icons/' + this.addIconToCaseStakeholder(stakeholderTypeEnum) + '.svg';
  }

  public removeDateOfBirth(id: string): Observable<CaseStakeholder> {
    return this.patchStakeholder(id, [JsonPatch.remove('/person/dateOfBirth')]);
  }

  public removeDateOfDeath(id: string): Observable<CaseStakeholder> {
    return this.patchStakeholder( id, [JsonPatch.remove('/person/dateOfDeath')]);
  }


  addIconToCaseStakeholder(stakeholderTypeEnum: any): string {
    let iconName: string;
    switch (stakeholderTypeEnum) {
      case StakeholderTypeEnum.endUser:
        iconName = 'End-User';
        break;

      case StakeholderTypeEnum.policyHolder:
        iconName = 'Policy Holder';
        break;

      case StakeholderTypeEnum.reporter:
        iconName = 'Reporter';
        break;

      case StakeholderTypeEnum.person:
        iconName = 'Person';
        break;

      case StakeholderTypeEnum.medicalAdvisor:
      case StakeholderTypeEnum.crisisPsychologist:
        iconName = 'Medical Person';
        break;

      case StakeholderTypeEnum.dentist:
      case StakeholderTypeEnum.doctorOutpatientClinic:
      case StakeholderTypeEnum.hospital:
        iconName = 'Place of Treatment';
        break;

      case StakeholderTypeEnum.hotel:
        iconName = 'Hotel';
        break;

      case StakeholderTypeEnum.airAmbulanceCompany:
        iconName = 'Ambulance Flight';
        break;

      case StakeholderTypeEnum.charterFights:
      case StakeholderTypeEnum.schedulesFights:
      case StakeholderTypeEnum.airline:
        iconName = 'Airline';
        break;

      case StakeholderTypeEnum.ambulanceCompany:
        iconName = 'Ground Ambulance';
        break;

      case StakeholderTypeEnum.taxi:
        iconName = 'Taxi';
        break;

      case StakeholderTypeEnum.escortDoctor:
      case StakeholderTypeEnum.escortNurse:
        iconName = 'Medical escort';
        break;

      case StakeholderTypeEnum.assistanceContact:
      case StakeholderTypeEnum.billingOfficeAdministration:
      case StakeholderTypeEnum.sosServiceOffice:
        iconName = 'Agent';
        break;

      case StakeholderTypeEnum.houseCallDoctor:
      case StakeholderTypeEnum.patientsDoctorHospital:
        iconName = 'patients-doctor-or-hospital';
        break;

      case StakeholderTypeEnum.pharmacy:
      case StakeholderTypeEnum.airport:
      case StakeholderTypeEnum.travelAgency:
      case StakeholderTypeEnum.churchesAndPriests:
      case StakeholderTypeEnum.embassyConsulate:
      case StakeholderTypeEnum.funeralCompany:
      case StakeholderTypeEnum.lawyers:
      case StakeholderTypeEnum.policeAndRescueSearch:
      case StakeholderTypeEnum.specializedMedicalFacility:
      case StakeholderTypeEnum.rehabilitation:
      case StakeholderTypeEnum.administration:
      case StakeholderTypeEnum.nationalHealthDirectory:
      case StakeholderTypeEnum.diagnosticsCenter:
      case StakeholderTypeEnum.shippingAndCargoCompany:
      case StakeholderTypeEnum.company:
      default:
        iconName = 'Other';
        break;
    }
    return iconName;
  }

  public displayTreatmentAndProviderRatingIcons(stakeholder: CaseStakeholder) {
    return stakeholder.stakeholderType != StakeholderTypeEnum.reporter &&
           stakeholder.stakeholderType != StakeholderTypeEnum.policyHolder &&
           stakeholder.stakeholderType != StakeholderTypeEnum.patientsDoctorHospital &&
           stakeholder.stakeholderType != StakeholderTypeEnum.customer;
  }

  public feedbackMailtoUri(stakeholder: CaseStakeholder): string {
    const recipient = 'internationalnetwork@sos.eu';
    const country = stakeholder.address.country.name || stakeholder.homeCountryName;
    const subject = `Feedback on supplier ID ${stakeholder.id} (${country})`;

    const body = 'Dear Network managers\n\n'
      + 'I have feedback on the supplier with the following details:\n\n'
      + `Supplier ID: ${stakeholder.id}\n`
      + `Name: ${stakeholder.company?.name || stakeholder.suppliersReference}\n`
      + `Country: ${country}\n\n`
      + 'Feedback:\n\n';

    return `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }
}
