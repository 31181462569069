import { SimpleStakeholder } from './simpleStakeholder';
import { Moment } from 'moment-timezone';
import { Coverage, CoverageAdapter } from '@secca/shared/models/coverage';
import { Injectable } from '@angular/core';
import { Adapter } from '@secca/shared/interfaces/adapter';

export class ReserveCoverage {
  reserve: number;
  settled: number;
  invoiced: number;
  coverageList: Coverage[];

  public constructor(init?: Partial<ReserveCoverage>) {
    Object.assign(this, init);
  }
}
@Injectable({
  providedIn: 'root'
})
export class ReserveCoverageAdapter implements Adapter<ReserveCoverage> {
  constructor(private coverageAdapter: CoverageAdapter){}
  adapt(item: any): ReserveCoverage {
    return new ReserveCoverage({
      reserve: item.reserve,
      settled: item.settled,
      invoiced: item.invoiced,
      coverageList: !!item.coverageList ? item.coverageList.map(this.coverageAdapter.adapt) : []
    });
  }
}
