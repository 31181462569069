<div class='modal-content'>
  <div class='d-flex justify-content-between modal-header'>
    <div class='modal-header-title'>
      {{ 'service-order-search-supplier-title' | translate }}
    </div>
    <div class='modal-buttons'>
      <div (click)='closeModalWindow()'>
        <img src='/assets/icons/Close White.svg' alt='Close window' />
      </div>
    </div>
  </div>

  <div class='body-1 modal-body'>
    <div class='align-items-stretch border'>

      <div class='d-flex flex-row-reverse'>
        <div
          class=" pe-4 pt-2 {{hasAnyParametersChanged() ? 'clearFields ' : 'grayed-out'}}"
          (click)='setParametersToOriginal()'>
          {{ 'SUPPLIER_PARAMETER-CLEAR' | translate }}
        </div>
      </div>

      <div class='d-flex flex-row mx-2'>
        <div class='flex-fill'>
          <app-drop-down-with-icon
            [items]='serviceTypesDropDown'
            [name]="'service-order-service-type' | translate"
            [disabled]='serviceTypeNotUsedInSearch'
            (selectedItemIdChange)='setSelectedServiceType($event)'
            [(selectedItemId)]='selectedServiceOrder.type'
          >
          </app-drop-down-with-icon>
          <div class='m-2 mt-2'>
            <mat-slide-toggle
              [color]='slideColour'
              [name]=''
              [(ngModel)]='serviceTypeNotUsedInSearch'
              (change)='presentSuppliersByType(selectedServiceOrder.type)'>
            </mat-slide-toggle>
            <mat-label>{{ 'SUPPLIER_SEARCH_LABEL' | translate }}</mat-label>
          </div>
        </div>
        <div class='flex-fill ms-2'>
          <app-input-location
            class=''
            [withImage]='true'
            [value]='serviceLocation'
            [allowEmpty]='true'
            [name]="'sub-duration-location' | translate"
            (saveModel)='setSearchObjectAndSearch(true)'
            placeholder='Enter location'
            autocorrect='off'
            autocapitalize='off'
            spellcheck='false'
            [disabled]='locationNotUsedInSearch'
          ></app-input-location>
          <div class='m-2 mt-2'>
            <mat-slide-toggle
              [color]='slideColour'
              [name]=''
              (change)=setSearchObjectAndSearch()
              [(ngModel)]='locationNotUsedInSearch'>
            </mat-slide-toggle>
            <mat-label>{{ 'SUPPLIER_LOCATION_LABEL' | translate }}</mat-label>
          </div>
        </div>


        <div class='col-4 flex-fill ms-2 pb-1'>
          <div class='suggester-label'>
            {{ 'SUPPLIER_SUGGESTER' | translate }}
          </div>
          <app-suggester (chipListChanged)='keywordsEventFromSuggester($event)' [items]='keywords'>
          </app-suggester>
          <div class='m-2 mt-2'>
            <mat-slide-toggle
              [color]='enabledColour'
              [name]=''
              (change)=setSearchObjectAndSearch()
              [(ngModel)]='fuzzyTextMatchEnabled'>
            </mat-slide-toggle>
            <mat-label>{{ 'SUPPLIER_FUZZY_TEXT_MATCH' | translate }}</mat-label>
          </div>
        </div>

        <div class='col-1 flex-fill ms-2'>
          <app-input [name]="'SUPPLIER_HIGHEST_DISTANCE' | translate" id='distance' [(value)]='highestDistanceToSupplier'
                     (saveModel)='setSearchObjectAndSearch()' [validationType]='ValidationTypeEnum.numbers'
                     (change)='setSearchObjectAndSearch()'>
          </app-input>
        </div>

        <div class='col-1 flex-fill ms-2'>
          <app-input [name]="'SUPPLIER_ROWS' | translate" id='supplierRows' [(value)]='suppliersToShow'
                     (saveModel)='setDisplayRowNumbers()'
                     (change)='setDisplayRowNumbers()'
                     [validationType]='ValidationTypeEnum.numbers'>
          </app-input>
        </div>

        <div class='d-flex flex-column ms-2'>
          <div class='slider-labels'>
            <span>{{ 'SUPPLIER_RATING' | translate }}</span>
            <span>{{ 'SUPPLIER_DISTANCE' | translate }}</span>
          </div>
          <mat-slider min='0' max='1' step='0.1' [showTickMarks]='false'>
            <input matSliderThumb matInput [(ngModel)]='sliderValue' (change)='setSearchObjectAndSearch()'>
          </mat-slider>
        </div>
      </div>
    </div>

    <div>
      <div class='border-bottom d-flex'>
        <!-- Accordions with filters to the left -->
        <div class='accordion-width window_height border-start border-end overflow-auto'>
          <div class='d-flex flex-column'>
            <div>
              <div
                [className]="isAnyFilterInUse() === false ? 'grayed-out m-2 float-end' : 'clearFields m-2 float-end'"
                (click)='clearFilters()'>
                {{ 'SUPPLIER_FILTER-CLEAR' | translate }}
              </div>
            </div>
            <div *ngFor='let accord of filters' class='mt-3 mb-3'>
              <div class='d-flex justify-content-between' (click)='toggle(accord.id)'>
                <div class='m-2'>
                  {{ accord.heading }}
                </div>
                <div>
                  <div *ngIf="accord.heading === 'Country' && !!countryInLocation && !locationNotUsedInSearch"
                       class='badge text-bg-primary m-2'> {{ countryInLocation }}
                  </div>
                  <span *ngIf="countNumberOfCountriesMarked > 0 && accord.heading === 'Country'"
                        class='m-2'> {{ locationNotUsedInSearch ? "+" + countNumberOfCountriesMarked : (countNumberOfCountriesMarked - 1 === 0 ? '' : "+" + (countNumberOfCountriesMarked - 1)) }}
                  </span>

                  <div *ngIf="accord.heading === 'Supplier Service'">
                    <app-single-item-display [filterList]='supplierServicesSuggestionsListFilter'></app-single-item-display>
                  </div>
                  <div *ngIf="accord.heading === 'Competencies'">
                    <app-single-item-display [filterList]='competenciesSuggestionsListFilter'></app-single-item-display>
                  </div>
                  <div *ngIf="accord.heading === 'Supplier Type'">
                    <app-single-item-display [filterList]='supplierTypeSuggestionsListFilter'></app-single-item-display>
                  </div>
                  <div *ngIf="accord.heading === 'Recommendation'">
                    <app-single-item-display [filterList]='recommendationSuggestionsListFilter'></app-single-item-display>
                  </div>
                </div>

                <div class='m-2'>
                  <img src='/assets/icons/ArrowDown.svg'
                       [@dropdownMenuState]='isOpenState(accord.id)' alt='drop down' />
                </div>
              </div>

              <div class='m-2' *ngIf='isOpen(accord.id)'>
                <ng-container *ngIf="accord.heading === 'Supplier Service'">
                  <div *ngFor='let supplerService of supplierServiceList' class='m-1 d-flex align-items-center'>
                    <input class='m-1' type='checkbox' [(ngModel)]='checkedSupplierServices[supplerService.first]'
                           name='{{supplerService.first}}'
                           (change)='setSearchObjectAndSearch2(supplerService.first, accord.heading)'>
                    <span>{{ supplerService.first }} ({{ supplerService.second }})</span>
                  </div>
                </ng-container>

                <ng-container *ngIf="accord.heading === 'Recommendation'">
                  <div *ngFor='let recommendation of recommendationList' class='m-1 d-flex align-items-center'>
                    <input class='m-1' type='checkbox' [(ngModel)]='checkedRecommendations[recommendation.first]'
                           name='{{recommendation.first}}'
                           (change)='setSearchObjectAndSearch2(recommendation.first, accord.heading)'>
                    <span>{{ recommendation.first }} ({{ recommendation.second }})</span>
                  </div>
                </ng-container>

                <ng-container *ngIf="accord.heading === 'Supplier Type'">
                  <div *ngFor='let supplerType of supplierTypeList' class='m-1 d-flex align-items-center'>
                    <input class='m-1' type='checkbox' [(ngModel)]='checkedSupplierTypes[supplerType.first]' name='{{supplerType.first}}'
                           (change)='setSearchObjectAndSearch2(supplerType.first, accord.heading)'>
                    <span>{{ supplerType.first }} ({{ supplerType.second }})</span>
                  </div>
                </ng-container>

                <ng-container *ngIf="accord.heading === 'Country'">
                  <input type='text' class='input-field' [(ngModel)]='searchCountryString'
                         placeholder='Search country' (input)='filterCountries()'>
                  <div *ngFor='let country of filteredCountries' class='m-1 d-flex align-items-center'>
                    <input class='m-1' type='checkbox' [(ngModel)]='checkedCountries[country.first]' name='{{country.first}}'
                           (change)='setSearchObjectAndSearch()'>
                    <span>{{ country.first }} ({{ country.second }})</span>
                  </div>
                </ng-container>

                <ng-container *ngIf="accord.heading === 'Competencies'">
                  <div *ngFor='let comp of competencesList' class='m-1 d-flex align-items-center'>
                    <input class='m-1' type='checkbox' [(ngModel)]='checkedCompetences[comp.first]' name='{{comp.first}}'
                           (change)='setSearchObjectAndSearch2(comp.first, accord.heading)'>
                    <span>{{ comp.first }} ({{ comp.second }})</span>
                  </div>
                </ng-container>

                <ng-container *ngIf="accord.heading === 'Supplier Service'">
                  <div *ngFor='let service of servicesList' class='m-1 d-flex align-items-center'>
                    <input class='m-1' type='checkbox' [(ngModel)]='checkedServices[service.first]'
                           name='{{service.first}}' (change)='setSearchObjectAndSearch2(service.first, accord.heading )'>
                    <span>{{ service.first }} ({{ service.second }})</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <!-- List of suppliers -->
        <div class='d-flex flex-column flex-grow-1 overflow-auto border-start pb-1 pt-3'>
          <div class='px-3 py-1 h6'>Found {{ totalNumberOfSuppliers }} results in total and filtered to {{ filteredSuppliers.length }}</div>
          <div *ngIf='filteredSuppliers.length && !isLoading; else noDataOrErrorTemplate' class='w-100 height-search'>
            <div *ngFor='let supplier of filteredSuppliers; let rank = index ' class='container-fluid'>
              <app-supplier-info [supplier]='supplier' (changeSupplierElect)='supplierSelectedByUser($event, rank)'></app-supplier-info>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #loadingTemp>
  <div class=''>
    <div class='spinner-border' role='status'></div>
  </div>
</ng-template>

<ng-template #noDataFoundtemp>
  <div class='justify-content-center w-100'>
    <div class='m-5 h4'>
      {{ 'service-order-view-supplier-no-data-found' | translate }}
    </div>
    <div hidden class='m-5 h6'> {{ showError }}</div>
  </div>
</ng-template>

<ng-template #errorTemp>
  <div class='justify-content-center w-100'>
    <div class='m-5 h4'> Connection error</div>
    <div class='m-5 h6'> {{ showError }}</div>
  </div>
</ng-template>

<ng-template #noDataOrErrorTemplate>
  <ng-container *ngIf='isErrors'>
    <ng-container *ngTemplateOutlet='errorTemp'></ng-container>
  </ng-container>
  <ng-container *ngIf='noDataFound && !isLoading'>
    <ng-container *ngTemplateOutlet='noDataFoundtemp'></ng-container>
  </ng-container>
  <ng-container *ngIf='isLoading'>
    <ng-container *ngTemplateOutlet=loadingTemp></ng-container>
  </ng-container>
</ng-template>
