import { FileService } from '@secca/core/services/file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CaseDocumentTableRow } from '@secca/case/components/case-documents/case-document-table/case-document-table-row';
import { CaseDocumentTagEnum } from '@secca/shared/models/enums';
import { DocumentService } from '@secca/core/services/document.service';
import { InputType } from '@secca/shared/models/enums';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { Component, Input } from '@angular/core';
import { AttachmentDto } from '@secca/shared/models/attachment-dto';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { saveAs } from 'file-saver-es';
import heic2any from 'heic2any';

@Component({
  selector: 'app-task-message-attachment',
  templateUrl: './task-message-attachment.component.html',
  styleUrls: ['./task-message-attachment.component.scss'],
})
export class TaskMessageAttachmentComponent {

  @Input() caseId: number;
  @Input() disabled: boolean;
  @Input() set attachment(value: AttachmentDto) {
    this._attachment = value;
    if (value != null) {
      this.initialize();
    }
  }
  get attachment(): AttachmentDto {
    return this._attachment;
  }
  private _attachment: AttachmentDto;

  documentsTag: DropdownDictionary[];
  pdfSrc: any = {};
  pdfPassword: string;
  askForPDFPassword: boolean;
  wordHtml: string;
  loading: boolean;
  incorrectPDFPassword: boolean;
  currentFileType: FileType;
  transformWordToPdfUrl: string;
  caseDocument: CaseDocumentTableRow;
  heicUrl: any;
  url: string;
  arrayBufferFromBlob: any;
  htmlDocument: any;

  constructor(
    private documentService: DocumentService,
    private fileService: FileService,
    private sanitizer: DomSanitizer,
    public caseStateService: CaseStateService
  ) {

  }

  getContentType(): string {
    if (this.currentFileType === FileType.IMAGE) {
      return 'image/' + this.attachment.documentExtension;
    }
    if (this.currentFileType === FileType.VIDEO) {
      return 'video/' + this.attachment.documentExtension;
    }
    return "";
  }

  initialize() {
    this.loading = true;
    this.currentFileType = this.getFileType();
    if (this.currentFileType === FileType.WORD) {
      this.documentService.getTransformWordToPdf(this.attachment.documentId).subscribe(result => {
        this.loading = false;
        this.url = URL.createObjectURL(result);
        result.arrayBuffer().then(buffer => this.arrayBufferFromBlob = buffer);
      },
        error => console.log(error));
    }
    else {
      this.documentService.getDocument(this.attachment.documentId).subscribe(result => {
        this.url = URL.createObjectURL(result);
        this.loading = false;
        if (this.currentFileType === FileType.PDF) {
          result.arrayBuffer().then(buffer => this.arrayBufferFromBlob = buffer);
        }
        if (this.currentFileType === FileType.HEIC) {
          this.transformHeicToImageUrl();
        }
        if (this.currentFileType === FileType.HTML) {
          result.arrayBuffer().then(buffer => this.htmlDocument = Utf8ArrayToStr(new Uint8Array(buffer)));
        }
      },
        error => console.log(error));
    }
    this.documentService.getCaseDocument(this.attachment.documentId).subscribe(result => {
      this.caseDocument = new CaseDocumentTableRow(result);
    },
      error => console.log(error));

    this.documentService.getUserAllowedDocumentTags().subscribe(
        items => {
          this.documentsTag = items.map(item => new DropdownDictionary(item, CaseDocumentTagEnum[item]));
        }
      );
  }

  updateDocument() {
    this.documentService.updateCaseDocument(this.caseDocument.getCaseDocument()).subscribe(
      response => {
        this.caseDocument = new CaseDocumentTableRow(response);
      },
      error => console.log(error)
    );
  }

  get FileType() {
    return FileType;
  }

  get sanitizedDocumentUrl() {
    if (this.url != null) {
      return this.sanitizer.bypassSecurityTrustUrl(this.url);
    }
  }

  get documentUrl() {
    return this.documentService.getDocumentUrl(this.attachment.documentId)
  }

  transformHeicToImageUrl() {
    this.loading = true;
    fetch(this.url)
      .then((res) => res.blob())
      .then((blob) => heic2any({
        blob
      }))
      .then((conversionResult) => {
        if (conversionResult instanceof Blob){
          this.heicUrl = URL.createObjectURL(conversionResult);
        } else {
          throw "Error converting to blob"
        }
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
        this.loading = false;
      });

  }

  get showPreview() {
    if (this.attachment?.fileScan) {
      if (this.attachment.fileScan.hit || !this.attachment.fileScan.scanned) {
        return false;
      }
    }
    return true;
  }

  get sanitizedHeicUrl() {
    return this.sanitizer.bypassSecurityTrustUrl(this.heicUrl);
  }


  getFileType(): FileType {
    if (this.attachment.documentExtension == null || this.attachment.documentExtension == "") {
      return FileType.UNKNOWN;
    }
    switch (this.attachment.documentExtension.toLowerCase()) {
      case 'pdf':
        return FileType.PDF;
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'png':
        return FileType.IMAGE;
      case 'mp4':
      case 'mov':
      case 'ogg':
        return FileType.VIDEO;
      case 'heif':
      case 'heic':
        return FileType.HEIC;
      case 'docx':
        return FileType.WORD;
      default:
        return FileType.UNKNOWN;
    }
  }

  onPDFError(x: any) {
    this.askForPDFPassword = true;
  }

  pdfPasswordProvided() {
    this.loading = true;
    this.askForPDFPassword = false;
    this.documentService.removePasswordFromPDF(this.attachment.documentId, this.pdfPassword, this.caseId).subscribe(
      result => {
        if(result.success) {
          this.arrayBufferFromBlob = null;
          this.loading = false;
          this.attachment.documentId = result.payload;
          this.incorrectPDFPassword = false;
          this.initialize();
        }
        else {
          this.loading = false;
          this.askForPDFPassword = true;
          this.incorrectPDFPassword = true;
          this.pdfPassword = '';
        }
      }
    );
  }

  get InputType() {
    return InputType;
  }

  pdfFromWordLoaded() {
    this.loading = false;
  }

  downloadFile(event: any) {
    event.preventDefault();
    this.fileService.downloadFile(this.documentUrl).subscribe(blob => {
      saveAs(blob, this.attachment.documentFilename);
    });
  }

}

function Utf8ArrayToStr(array) {
  var out, i, len, c;
  var char2, char3;

  out = "";
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4)
    {
      case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
      // 0xxxxxxx
      out += String.fromCharCode(c);
      break;
      case 12: case 13:
      // 110x xxxx   10xx xxxx
      char2 = array[i++];
      out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
      break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(((c & 0x0F) << 12) |
          ((char2 & 0x3F) << 6) |
          ((char3 & 0x3F) << 0));
        break;
    }
  }
  return out;
}

enum FileType {
  UNKNOWN,
  PDF,
  IMAGE,
  WORD,
  VIDEO,
  HEIC,
  HTML
}
