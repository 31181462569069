import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CaseService } from '@secca/core/services/case.service';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { PersonInsurance } from '@secca/shared/models/person-insurance';
import { InsuranceService } from '@secca/core/services/insurance.service';
import { PolicyService } from '@secca/core/services/policy.service';
import { PolicyLookupResponse, PolicyLookup } from '@secca/shared/models/policy-lookup-response';
import {PolicyLookupSelect} from '@secca/shared/models/policy-lookup-select';
import {combineLatest, Subscription} from 'rxjs';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { PolicyLookupService } from '@secca/case/components/case-basics/case-basics-insurance/policy-lookup/policy-lookup-service';
import { ServiceOrderService } from '@secca/core/services/service-order.service';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import {QueueService} from '@secca/core/services/queue.service';
import { StakeholderTypeEnum } from '@secca/shared/models/enums';

@Component({
  selector: 'app-policy-lookup',
  templateUrl: './policy-lookup.component.html',
  styleUrls: ['./policy-lookup.component.scss']
})
@AutoUnsubscribe
export class PolicyLookupComponent implements OnInit {
  @Input() set caseId(value: string) {
    this._caseId = value;
    if (value != null) {
      this.subscribeToPersonInsurance();
      this.prepareEndUserData(value);
    }
  }
  get caseId() {
    return this._caseId;
  }

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  private _caseId: string;
  lookupResponse: PolicyLookupResponse;
  error: string;
  policyFound: boolean;
  chosen = 0;
  policyHolder = new CaseStakeholder();
  policyHolderName = '';
  phAddressLine = '';
  endUser = new CaseStakeholder();
  endUserFetched = false;
  endUserName = '';
  euAddressLine = '';
  insurance = new PersonInsurance();
  isPolicySelected: boolean;
  showSelectedPolicy: boolean;
  policyDetails: PolicyLookup;
  $personInsuranceSubscr: Subscription;
  $queueRunningSubscription: Subscription;
  matchByPh = false;
  matchByEu = false;
  $policyholderSubscr: Subscription;

  private readonly ssnMatch = "national-id-match";
  private readonly endUserMatch = "ENDUSER";

  constructor(
    private modalService: NgbModal,
    private caseService: CaseService,
    private insuranceService: InsuranceService,
    private policyService: PolicyService,
    private policyLookupService: PolicyLookupService,
    private serviceOrderService: ServiceOrderService,
    private queueService: QueueService) {
    this.$policyholderSubscr = this.caseService.observePolicyholder().subscribe(
      policyHolder => {
        if (policyHolder) {
          this.policyHolder = policyHolder;
          [this.policyHolderName, this.phAddressLine] = PolicyLookupComponent.updateStakeholderData(this.policyHolder);
        }
      }
    );
  }

  static updateStakeholderData(stakeholder: CaseStakeholder): string[] {
    if (stakeholder) {
      const genderAge = stakeholder.person?.gender || stakeholder.person?.age
        ? ' (' + [stakeholder.person.gender, stakeholder.person.age].filter(Boolean).join(' ') + ')'
        : '';
      const name = stakeholder.isCompany ? stakeholder.company.name : [stakeholder.person.firstName, stakeholder.person.surname].join(' ');
      const stakeholderName = name + genderAge;
      const country = stakeholder.address?.country ? stakeholder.address.country.name : null;
      const postalCodeCity = [stakeholder.address.postalCode, stakeholder.address.city].filter(Boolean).join(' ');
      const stakeholderAddressLine = [stakeholder.address.street, postalCodeCity, country].filter(Boolean).join(', ');
      return [stakeholderName, stakeholderAddressLine];
    }
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.$policyholderSubscr.unsubscribe();
  }

  private prepareEndUserData(caseId: string) {
    if (!this.endUserFetched) {
      this.endUserFetched = true;
      this.caseService.getCaseStakeholdersOnCaseByType(caseId, StakeholderTypeEnum.endUser).subscribe(
        stakeholders => {
          if (Array.isArray(stakeholders) && stakeholders.length) {
            this.endUser = stakeholders[0];
            [this.endUserName, this.euAddressLine] = PolicyLookupComponent.updateStakeholderData(this.endUser);
          }
        }
      );
    }
  }

  private subscribeToPersonInsurance() {
    if ( this.$personInsuranceSubscr ) {
      this.$personInsuranceSubscr.unsubscribe();
    }
    this.$personInsuranceSubscr = this.insuranceService.getPersonInsurance(this.caseId).subscribe(
      insurance => {
        if (insurance) {
          this.insurance = insurance;
        }
      }
    );
  }

  init(): void {
    this.lookupResponse = null;
    this.policyDetails = null;
    this.isPolicySelected = !!this.insurance.insuranceLookupPolicy;
    this.showSelectedPolicy = this.isPolicySelected;
    if (this.isPolicySelected) {
      this.onShowSelectedPolicy();
    }

    // wait for possible data updates to run before searching
    this.$queueRunningSubscription = combineLatest([
      this.queueService.insuranceQueueRunning, this.queueService.stakeholderQueueRunning, this.queueService.incidentQueueRunning])
      .subscribe(([insuranceRunning, stakeholderRunning, incidentRunning]) => {
        if (!insuranceRunning && !stakeholderRunning && !incidentRunning) {
          this.doInitialLookup();
        }
      });
  }

  doInitialLookup() {
    if (this.$queueRunningSubscription) {
      this.$queueRunningSubscription.unsubscribe();
    }
    const searchValue = this.insurance.creditCard ? this.insurance.creditCardNo : this.insurance.policyReference;
    this.performLookup(searchValue);
  }

  performLookup(searchValue: string) {
    this.lookupResponse = null;
    this.policyFound = false;
    this.policyDetails = null;
    if (this.isPolicySelected) {
      this.onShowSelectedPolicy();
    }
    this.policyService.lookupPolicy(this.caseId, this.insurance.creditCard, searchValue).subscribe({
      next: (result) => {
        this.lookupResponse = result;

        this.insurance.insuranceLookupMatch = result?.results?.length > 0;
        // make sure we don't react to the insuranceLookupMatch update
        if (this.$queueRunningSubscription) {
          this.$queueRunningSubscription.unsubscribe();
        }
        this.insuranceService.updatePersonInsuranceWithQueueService(this.caseId, this.insurance);
        this.policyLookupService.sendPolicyMatch(this.caseId, this.insurance.insuranceLookupMatch);

        this.policyFound = !(result.results === null || result.results.length === 0);
        if (!this.isPolicySelected && this.policyFound) {
          this.onShowLookupPolicy(0);
        }
      },
      error: (error) => {
        this.close();
        throw error;
      }
    });
  }

  close() {
    this.closed.emit();
    this.modalService.dismissAll();
  }

  policySelect(policy: PolicyLookup) {
    const data: PolicyLookupSelect = new PolicyLookupSelect(this.insurance.id, policy);
    policy.customerContactInfo = this.lookupResponse.profileContactInfo;
    this.insuranceService.selectPolicy(data);
    this.close();
  }

  removePolicy() {
    this.insuranceService.removePolicy(this.insurance.id);
    this.close();
  }

  onShowLookupPolicy(idx: number) {
    this.policyDetails = this.lookupResponse.results[idx];
    this.checkMatchType();
    this.chosen = idx;
    this.showSelectedPolicy = false;
  }

  checkMatchType() {
    const nationalIdMatch = this.policyDetails?.possibleMatches?.some(
      match => match.parameter === this.ssnMatch && match.quality === "SUCCESS"
    );
    const matchedBy = this.policyDetails?.lookupExecution?.matchedBy?.toUpperCase();
    this.matchByEu = nationalIdMatch && matchedBy === this.endUserMatch.toUpperCase();
    this.matchByPh = nationalIdMatch && matchedBy !== this.endUserMatch.toUpperCase();
  }

  onShowSelectedPolicy() {
    this.policyDetails = this.insurance.insuranceLookupPolicy;
    this.chosen = undefined;
    this.showSelectedPolicy = true;
  }

  showWarningIfPolicyChange(policyDetails: PolicyLookup) {
    this.serviceOrderService.isCoveragesUsedOnExistingServiceOrders(+this.caseId).subscribe(coveragesExist => {
      if (coveragesExist) {
        const modalRef = this.modalService.open(ModalDialogComponent);
        modalRef.componentInstance.items = [];
        modalRef.componentInstance.configuration = new ModalDialogConfiguration({
          header: 'default-modal-header',
          title: 'changing-service-orders-with-coverages',
          text: null,
          footer: 'case-validation-warning-modal-footer',
          yes: 'default-modal-dialog-yes',
          no: 'default-modal-dialog-no',
          isBody: true,
          isFooter: true,
        });
        modalRef.componentInstance.closeModalEvent.subscribe(
          (closedClickingYes: boolean) => {
            this.modalService.dismissAll();
            if (closedClickingYes) {
              if (policyDetails) {
                this.policySelect(policyDetails);
              } else {
                this.removePolicy();
              }
            }
          },
          (error: any) => console.log(error)
        );
      } else {
        if (policyDetails) {
          this.policySelect(policyDetails);
        } else {
          this.removePolicy();
        }
      }
    });
  }
}
