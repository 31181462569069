import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PlanService } from './../../../../../core/services/plan.service';
import { Coverage } from './../../../../../shared/models/coverage';
import { Coverage as CaseCoverage } from '@secca/shared/models/onepoint-coverage';
import { CasePlansService } from './../case-plans.service';
import {Component, Input, OnInit} from '@angular/core';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { ReserveCoverage } from '@secca/shared/models/reserveCoverage';

@Component({
  selector: 'app-case-coverage',
  templateUrl: './case-coverage.component.html',
  styleUrls: ['./case-coverage.component.scss']
})
@AutoUnsubscribe
export class CaseCoverageComponent implements OnInit {
  @Input() caseId: string;
  reserveCoverage: ReserveCoverage;
  coveragesSummarized: Coverage[];
  $coverageSubscr: Subscription;
  $reserveCoverageSubscr: Subscription;
  billingCurrency: string;
  validCaseCoverages: CaseCoverage[];

  constructor(
    private casePlansService: CasePlansService,
    private planService: PlanService,
  ) {
    this.$coverageSubscr = this.casePlansService.getCoverages().subscribe(coverages => {
      if (this.caseId != null) {
        this.planService.getReserveCoverageForCase(this.caseId).subscribe(
          result => this.casePlansService.sendReserveCoverage(result),
        );
      }
    });
    this.$reserveCoverageSubscr = this.casePlansService.getReserveCoverages().subscribe(reserveCoverage => {
      this.reserveCoverage = reserveCoverage;
      this.calculateReserveCoveragesSummarized();
    });
  }

  ngOnInit() {
    this.planService.getCoveragesForCase(this.caseId).subscribe(
      result => this.casePlansService.sendCoverages(result),
    );
    if (this.validCaseCoverages == null) {
      this.planService.getValidCaseCoverages(Number(this.caseId)).subscribe(caseCoverages => {
          this.validCaseCoverages = caseCoverages;
        },
      );
    }
    if (this.caseId) {
      this.planService.getBillingCurrencyForCase(+this.caseId).subscribe(result => {
        this.billingCurrency = result.billingCurrency;
      });
    } else {
      this.billingCurrency = '';
    }
  }

  calculateReserveCoveragesSummarized() {
    if (this.reserveCoverage == null) {
      return;
    }

    this.coveragesSummarized = [];

    this.reserveCoverage.coverageList.forEach(coverage => {
      this.coveragesSummarized.push(coverage);
    });
  }

  isCoverageValid(coverage: Coverage): boolean {
    if (this.validCaseCoverages) {
      return this.validCaseCoverages.filter(validCaseCoverage => +validCaseCoverage.coverageId === +coverage.typeId).length > 0;
    }
    return true;
  }
}
