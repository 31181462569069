import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SettingsService } from '@secca/core/services/settings.service';
import { MessageTemplate, MessageTemplateAdapter } from '@secca/shared/models/message-template';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '@secca/core/services/base.service';
import { ServiceOrderMessageRequest } from '@secca/shared/models/service-order/service-order-message-request';
import { DocumentTemplateFilter } from '@secca/case/components/case-plans/case-plans/add-service-order/sub-components/sub-gop-details/documentTemplateFilter';
import { MessageChannelType } from '@secca/shared/models/enums';
import { MessageBodyAdapter } from '@secca/case/components/case-output-management/models/messageBody';
import { MessageService } from '@secca/core/services/message.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceOrderMessageService extends BaseService implements MessageService{

  constructor(private http: HttpClient,
              private messageTemplateAdapter: MessageTemplateAdapter,
              private emailBodyAdapter: MessageBodyAdapter,
              private settingsService: SettingsService) {
    super(settingsService);
  }

  public getMessageTemplates(request: ServiceOrderMessageRequest): Observable<MessageTemplate[]> {
    return this.http
      .post<MessageTemplate[]>(this.baseURL + `message-templates/by-filter`, this.prepareTemplateFilterBodyRequest(request))
      .pipe(map((data: any[]) => data.map(item => this.messageTemplateAdapter.adapt(item))));
  }

  public getEmailBody(request: ServiceOrderMessageRequest, templateStorageId: string, selectedStakeholderId: string): Observable<any> {
    return this.http.get(this.baseURL + 'service-orders/templates/create/email/as-string', {
      params: this.prepareParamsForEmailBodyRequest(request.serviceOrder.id, templateStorageId, request.message.receiver.id)
    }).pipe(map((data) => this.emailBodyAdapter.adapt(data)));
  }

  public getSmsBody(request: ServiceOrderMessageRequest, templateStorageId: string, selectedStakeholderId: string): Observable<any> {
    return new Observable();
  }

  private prepareParamsForEmailBodyRequest(serviceOrderId: number, templateStorageId: string, receiverId: string): HttpParams {
    let params = new HttpParams();
    params = params.append('serviceOrderId', serviceOrderId.toString());
    params = params.append('templateStorageId', templateStorageId);
    params = params.append('receiverStakeholderId', receiverId);
    return params;
  }

  private prepareTemplateFilterBodyRequest(messageRequest: ServiceOrderMessageRequest): DocumentTemplateFilter {
    return new DocumentTemplateFilter(this.resolveDocumentType(messageRequest.message.messageChannelType),
      null,
      messageRequest.serviceOrder.type);
  }

  private resolveDocumentType(channelType: MessageChannelType): string {
    switch (channelType) {
      case MessageChannelType.EMAIL:
      case MessageChannelType.FAX:
        return 'EMAIL_FAX';
      case MessageChannelType.SMS:
        return 'SMS';
      default:
    }
    return null;
  }
}
