<div class="filter-container">
  <div class="row">
    <div class="col-4">
      <div class="drop-down-container">
        <app-drop-down [items]="group" [selectedItemId]="''" (selectedItemIdChange)="selectedItemIdChange($event)"></app-drop-down>
      </div>

    </div>
    <div class="col-8 container">
      <div [appDisableInput]="caseStateService.isCaseDisabled()">
        <div class="row column-flexible"></div>
        <div class="column-fixed">
          <div ngbDropdown>
            <button class="primary-button" type="button" ngbDropdownToggle>
              <!--span class="shortcut-key">A</span-->{{ 'case-stakeholder-add-new' | translate }}
            </button>
            <div ngbDropdownMenu class="drop-down-container">
              <a ngbDropdownItem (click)="addNew(StakeholderTypeEnum.person)">{{ 'case-stakeholder-add-person' | translate }}</a>
              <a ngbDropdownItem (click)="addNew(null)" *ngIf="settingsService.isNewServiceProviderEnabled()">{{ 'case-stakeholder-search-supplier' | translate }}</a>
              <a ngbDropdownItem (click)="open()">{{ 'case-stakeholder-search-providers' | translate }}</a>
              <a ngbDropdownItem (click)="addNew(StakeholderTypeEnum.company)">{{ 'case-stakeholder-add-manual-company' | translate }}</a>
              <a ngbDropdownItem (click)="addNew(StakeholderTypeEnum.sosAccounting)">{{ 'case-stakeholder-add-sos-accounting' | translate }}</a>
              <a ngbDropdownItem (click)="openWithoutProviders()">{{ 'case-stakeholder-add-manual-other' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <img class="search-image" src="../../../../../../assets/icons/Magnifier.svg">
    <input type="text" placeholder="Search types" class="search-input" (keyup)="updateTypesFilter()" [(ngModel)]="typeSearchValue">
    <div class="close-modal-big" aria-label="Close" (click)="modal.dismiss('Cross click')">
      &times;
    </div>
  </div>
  <div class="modal-body">
    <div class="types-list">
      <div *ngFor="let type of filteredTypes; let i = index">
        <a href="" (click)="clickOnType($event, type.stakeholderTypeEnum)">
          <div [ngClass]="'types-list-row-' + (i % 2 == 0 ? 'even' : 'odd')">
            {{ 'case-stakeholder-type.' + type.stakeholderTypeEnum | translate }}
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>
