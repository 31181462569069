
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Case } from 'src/app/shared/models/case';
import { OnRefresh } from 'src/app/shared/interfaces/on-refresh';
import { StakeholderType } from 'src/app/modules/case/components/case-stakeholders/stakeholderType';
import {
  CaseStakeholdersTableComponent
} from "@secca/case/components/case-stakeholders/case-stakeholders-table/case-stakeholders-table.component";

@Component({
  selector: 'app-case-stakeholders',
  templateUrl: './case-stakeholders.component.html',
  styleUrls: ['./case-stakeholders.component.scss']
})
export class CaseStakeholdersComponent implements OnInit, OnRefresh {
  @ViewChild('caseStakeholdersTable') caseStakeholdersTable: CaseStakeholdersTableComponent;
  @Input()
  set case(newCase: Case) {
    this.theCase = newCase;
    if (this.theCase) {
      this.onRefresh();
    }
  }
  get case(): Case {
    return this.theCase;
  }
  @Input() newCase: boolean;
  private theCase: Case;

  constructor() { }

  ngOnInit() {}

  public onRefresh() {
  }

  addNewType(type: string, openProviders: boolean) {
    this.caseStakeholdersTable.addNewType(type, openProviders);
  }

  filterByType(type: StakeholderType[]) {
    this.caseStakeholdersTable.filterByType(type);
  }
}
