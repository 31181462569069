import { map } from 'rxjs/operators';
import { Coverage, CoverageAdapter } from '@secca/shared/models/coverage';
import { Coverage as CaseCoverage, CoverageAdapter as CaseCoverageAdapter } from '@secca/shared/models/onepoint-coverage';
import { Observable } from 'rxjs';
import { SettingsService } from './settings.service';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ValidCaseCoverageAdapter } from '@secca/shared/models/valid-case-coverage';
import { BillingCurrency, BillingCurrencyAdapter } from '@secca/shared/models/billing-currency';
import { ReserveCoverage, ReserveCoverageAdapter } from '@secca/shared/models/reserveCoverage';

@Injectable({
  providedIn: 'root'
})
export class PlanService extends BaseService {
  constructor(private http: HttpClient, private settingsService: SettingsService, private coverageAdapter: CoverageAdapter, private caseCoverageAdapter: CaseCoverageAdapter, private validCaseCoverageAdapter: ValidCaseCoverageAdapter, private billingCurrencyAdapter: BillingCurrencyAdapter, private reserveCoverageAdapter: ReserveCoverageAdapter) {
    super(settingsService);
  }

  public getValidCaseCoverages(caseId: number): Observable<CaseCoverage[]> {
    return this.http
      .get<CaseCoverage[]>(this.baseURL + 'service-orders/case-coverages/' + caseId, {
        headers: this.jsonHeaders
      })
      .pipe(map((data: any[]) => data.map(item => this.caseCoverageAdapter.adapt(item))));
  }

  public getCoveragesForCase(caseId: string): Observable<Coverage[]> {
    return this.http
      .get<Coverage[]>(this.baseURL + 'plans/coverages/bycase/' + caseId, {
        headers: this.jsonHeaders
      })
      .pipe(map((data: any[]) => data.map(item => this.normalizeCoverage(this.coverageAdapter.adapt(item)))));
  }

  public getReserveCoverageForCase(caseId: string): Observable<ReserveCoverage> {
    return this.http
      .get<ReserveCoverage>(this.baseURL + 'plans/coverages/reserve/case/' + caseId, {
        headers: this.jsonHeaders
      })
      .pipe(map((data: any) => this.normalizeReserveCoverage(this.reserveCoverageAdapter.adapt(data))));
  }

  public existReserveOnCase(caseId: string): Observable<boolean> {
    return this.http
      .get<boolean>(this.baseURL + 'plans/coverages/exist-reserve/' + caseId, {
        headers: this.jsonHeaders
      });
  }

  public createCoverage(coverage: Coverage): Observable<Coverage> {
    this.normalizeCoverage(coverage);
    return this.http
      .post<Coverage>(this.baseURL + 'plans/coverages', coverage, {
        headers: this.jsonHeaders
      })
      .pipe(map(item => this.coverageAdapter.adapt(item)));
  }

  public updateCoverage(coverage: Coverage): Observable<Coverage> {
    this.normalizeCoverage(coverage);
    return this.http
      .put<Coverage>(this.baseURL + 'plans/coverages', coverage, {
        headers: this.jsonHeaders
      })
      .pipe(map(item => this.coverageAdapter.adapt(item)));
  }

  public deleteCoverage(coverage: Coverage): Observable<Coverage> {
    return this.http.delete<Coverage>(this.baseURL + 'plans/coverages/' + coverage.id, {
      headers: this.jsonHeaders
    });
  }

  private normalizeCoverage(coverage: Coverage): Coverage {
    coverage.planned = this.RoundNumber(coverage.planned);
    coverage.limit = this.RoundNumber(coverage.limit);
    coverage.reserve = this.RoundNumber(coverage.reserve);
    coverage.settled = this.RoundNumber(coverage.settled);
    coverage.invoiced = this.RoundNumber(coverage.invoiced);
    return coverage;
  }

  private normalizeReserveCoverage(reserveCoverage: ReserveCoverage): ReserveCoverage {
    reserveCoverage.reserve = this.RoundNumber(reserveCoverage.reserve);
    reserveCoverage.settled = this.RoundNumber(reserveCoverage.settled);
    reserveCoverage.invoiced = this.RoundNumber(reserveCoverage.invoiced);
    reserveCoverage.coverageList.forEach(coverage => {
        this.normalizeCoverage(coverage);
      }
    );
    return reserveCoverage;
  }

  private RoundNumber(value: number): number {
    if (value == null) {
      return value;
    }
    return Math.round(value);
  }

  public getBillingCurrencyForCase(caseId: number): Observable<BillingCurrency> {
    return this.http
      .get<BillingCurrency>(this.baseURL + 'service-orders/billing-currency/' + caseId, {
        headers: this.jsonHeaders
      })
      .pipe(map(item => this.billingCurrencyAdapter.adapt(item)));
  }

}
