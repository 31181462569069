
import {Injectable} from '@angular/core';
import {Adapter} from '@secca/shared/interfaces/adapter';

export class ResultOf {
    success: boolean
    msg: string
    errorCode: string
    payload: any


    public constructor(init?: Partial<ResultOf>) {
        Object.assign(this, init);
      }
}


@Injectable({
  providedIn: 'root',
})
export class ResultOfAdapter implements Adapter<ResultOf> {
  adapt(item: any): ResultOf {
    return new ResultOf({
        success: item.success,
        msg: item.msg,
        errorCode: item.errorCode,
        payload: item.payload
    });
  }
}

