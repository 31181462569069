import { RegularFlightMedicalComponent } from './../service-type-components/regular-flight-medical/regular-flight-medical.component';
import { ServiceTypeComponent } from './../models/interfaces';
import { ServiceOrderTypeState } from './../models/serviceOrderTypeState';
import {DefaultCoveragesAlwaysPresentEnum, MessageChannelType, ServiceTypeEnum, StatusTypes} from '@secca/shared/models/enums';
import { StakeholderService } from 'src/app/core/services/stakeholder.service';
import { PlanService } from '@secca/core/services/plan.service';
import { StakeholderHelperService } from '@secca/core/services/stakeholder-helper.service';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { IncidentService } from '@secca/core/services/incident.service';
import { CaseIncident } from '@secca/shared/models/caseIncident';
import { UntypedFormControl } from '@angular/forms';
import { InputTypePattern, PermissionEnum, StakeholderTypeEnum } from 'src/app/shared/models/enums';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { CaseService } from 'src/app/core/services/case.service';
import { AutoCompleteTypeEnum } from 'src/app/shared/components/drop-down-countries-auto-complete/auto-complete-type-enum';
import { DropdownDictionary, SupplierDropDownAdapter } from '@secca/shared/models/dropdownDictionary';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ProviderService } from '@secca/core/services/providerService';
import { ProviderSearch } from '@secca/shared/models/ProviderSearch';
import { UserDto } from '@secca/shared/models/userDto';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { ProviderResponse, ProviderResponseAdapter } from '@secca/shared/models/ProviderResponse';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProviderHelper } from '@secca/shared/helpers/provider-helper';
import { SelectionAwareStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/SelectionAwareStakeholder';
import { TranslateService } from '@ngx-translate/core';
import { CaseCoverageDropDown } from '@secca/shared/models/case-coverage-drop-down';
import { ServiceOrderExtension } from '@secca/shared/models/service-order/service-order-extension';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { interval, Subscription } from 'rxjs';
import { switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { ProviderModifiedResponseDto } from '@secca/shared/models/provider-modified-status';
import { Coverage } from '@secca/shared/models/onepoint-coverage';
import { PermissionService } from '@secca/core/services/permission.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { SettingsService } from '@secca/core/services/settings.service';
import { DialogStateService } from '@secca/core/state-services/dialog-state.service';
import {
  SupplierDialogData,
  SupplierSearchData
} from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/SupplierDialogData';
import {CurrencyMaskInputMode} from "ngx-currency";
import {ExchangeRateService} from "@secca/core/services/exchange-rate.service";
import Autocomplete = google.maps.places.Autocomplete;
import {SupplierProfileService} from "@secca/core/services/supplier-profile.service";
import {ServiceOrderMessageRequest} from "@secca/shared/models/service-order/service-order-message-request";
import {Message} from "@secca/case/components/case-output-management/models/message";
import {Case} from "@secca/shared/models/case";
import {CaseMessageService} from "@secca/core/services/case-message.service";
import {PhoneDto} from "@secca/shared/models/phone-dto";

const NOT_COVERED_SERVICE_ORDERS = [
    ServiceTypeEnum.TREATMENT,
    ServiceTypeEnum.ACCOMMODATION,
    ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL,
    ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL,
    ServiceTypeEnum.AMBULANCE_FLIGHT,
    ServiceTypeEnum.GROUND_AMBULANCE,
    ServiceTypeEnum.GROUND_TRANSPORT,
    ServiceTypeEnum.HANDLING_OF_MORTAL_REMAINS,
    ServiceTypeEnum.ASSISTANCE_OR_CONTACT,
    ServiceTypeEnum.LUGGAGE,
    ServiceTypeEnum.MONEY_TRANSFER,
    ServiceTypeEnum.OTHER_REFUND_REQUEST
  ];

@Component({
  selector: 'app-left-side',
  templateUrl: './left-side.component.html',
  styleUrls: ['./left-side.component.scss'],
})
@AutoUnsubscribe
export class LeftSideComponent implements OnInit, OnDestroy {
  @Input() selectedServiceOrderInitialStatus: StatusTypes;
  @Input() serviceTypeComponent: ServiceTypeComponent;

  @Input() showProviderSpinner: boolean = false;

  @Input() set serviceOrderTypeState(serviceOrderTypeState: ServiceOrderTypeState) {
    this._serviceOrderTypeState = serviceOrderTypeState;
    if (this.$subscr1 != null) {
      this.$subscr1.unsubscribe();
    }
    this.$subscr1 = serviceOrderTypeState.confirmationTabHasBeenClosed.subscribe(() => {
      this.changeStatus(StatusTypes.EXPECTED);
    });
  }
  get serviceOrderTypeState() {
    return this._serviceOrderTypeState;
  }
  @Input() set returnedProviders(returnedProviders: CaseStakeholder[]) {
    this._returnedProviders = returnedProviders;
    if (this.caseId != null) {
      this.mockProviders(returnedProviders);
    }
  }

  @Input() incidentId: number;
  @Input() serviceOrderType: ServiceOrderType;
  @Input() lastGopDocumentId: string;

  @Input()
  set serviceOrder(serviceOrder: ServiceOrder) {
    this._serviceOrder = serviceOrder;

    this.hasSupplier = !!serviceOrder.supplierId;
    // Make sure that tab is set according to status on incoming service order
    this.changeStatus(this._serviceOrder.status);
    if (
      this._serviceOrder.type === ServiceTypeEnum.REFERRAL_TO_CUSTOMER ||
      this._serviceOrder.type === ServiceTypeEnum.GENERAL_ADVICE ||
      this._serviceOrder.type === ServiceTypeEnum.ADVISE_ON_INSURANCE_TERMS ||
      this._serviceOrder.type === ServiceTypeEnum.ADVICE_CALL_REGISTRATION ||
      this._serviceOrder.type === ServiceTypeEnum.PRE_DEPARTURE_TRAVEL_SERVICE
    ) {
      this.isSupplierDisabled = true;
      this.expectedCostDisabled = true;
      this.expectedNotPossible = true;
      this.serviceOrder.expectedCostValue = '0';
    } else {
      this.isSupplierDisabled = false;
      this.expectedCostDisabled = false;
    }

    if ( this._serviceOrder.type === ServiceTypeEnum.PRE_DEPARTURE_TRAVEL_SERVICE ) {
      this.allowSearchSupplierIgnoreTransfer = true;
    }
  }

  @Input() iccServiceOrder: ServiceOrder;

  private expectedNotPossible: boolean = false;

  get serviceOrder() {
    return this._serviceOrder;
  }
  @Output() extensionChange: EventEmitter<ServiceOrderExtension> = new EventEmitter();
  @Output() supplierChange: EventEmitter<number> = new EventEmitter();
  @Output() supplierAddedEvent: EventEmitter<number> = new EventEmitter();
  @Input()
  set caseId(newCaseId: number) {
    if (newCaseId !== undefined) {
      this._caseId = newCaseId;
      if (this.returnedProviders != null) {
        this.mockProviders(this.returnedProviders);
      }
    }
  }

  get caseId(): number {
    return this._caseId;
  }

  @Input()
  set type(newTypeId: number) {
    if (newTypeId !== undefined) {
      this._typeId = newTypeId;
    }
  }

  get type(): number {
    return this._typeId;
  }

  get gopCreated(): boolean {
    return !!this.lastGopDocumentId;
  }

  get supplierDisabled(): boolean {
    return (
      this.serviceOrder.status === StatusTypes.CANCELLED ||
      this.gopCreated ||
      this.isSupplierDisabled ||
      this.serviceOrderTypeState.isLockedByCoordinationTransport ||
        this.showProviderSpinner ||
      (!this.serviceOrderTypeState.canConfirmationTabBeClosed && this.serviceOrder.status !== StatusTypes.EXPECTED && this.hasSupplier)
    );
  }

  expectedCostDisabled = false;
  private caseStakeholders: CaseStakeholder[];

  @Input() set stakeholders(stakeholders: CaseStakeholder[]) {
    this.caseStakeholders = stakeholders;
    if (stakeholders) {
      this.selectionAwareStakeholders = stakeholders.map(stakeholder => new SelectionAwareStakeholder(stakeholder, false));
      this.selectStakeholders();
    }
  }

  @Input() baseCurrenciesSupported: DropdownDictionary[];
  currencyMaskOptions = {
    prefix: '',
    thousands: '.',
    decimal: ',',
    precision: 2,
    allowNegative: false,
    inputMode: CurrencyMaskInputMode.NATURAL,
  };
  private _serviceOrderTypeState: ServiceOrderTypeState;
  private incident = new CaseIncident();
  private providerResponse: ProviderResponse;
  private _caseId: number;
  private _returnedProviders: CaseStakeholder[];
  private caseNumber: string;
  private case: Case;
  private providerURL: string;
  private loggedInUser: UserDto;
  private uniqueId: string;
  private _typeId: number;
  public searchStartLocationFormControl: UntypedFormControl;
  public searchEndLocationFormControl: UntypedFormControl;
  validCaseCoverages: CaseCoverageDropDown[];
  providers: DropdownDictionary[];
  private allProviders: CaseStakeholder[];
  autocompleteStart: Autocomplete;
  autocompleteEnd: Autocomplete;
  private providerResponseAdapter: ProviderResponseAdapter;
  private address: ProviderHelper;
  private isSupplierDisabled;
  private pollingActive: boolean;
  private pollingActiveCnt: number;
  private pollingCheckTime: Date;
  private pollingInterval = 1500;
  private hasSupplier: boolean;
  private $stakeholderPollSubscr: Subscription;
  private oldReturnedProviders;
  _serviceOrder: ServiceOrder;
  $subscr1;
  isNewServiceProviderEnabled: boolean = this.settingService.isNewServiceProviderEnabled();

  selectionAwareStakeholders: SelectionAwareStakeholder[];
  allowSearchSupplierIgnoreTransfer: boolean;

  get returnedProviders(): CaseStakeholder[] {
    return this._returnedProviders;
  }

  get isRightSideValid(): boolean {
    if (this.serviceTypeComponent == null) {
      return false;
    } else {
      if (RegularFlightMedicalComponent.prototype.isPrototypeOf(this.serviceTypeComponent)) {
        return (this.serviceTypeComponent as RegularFlightMedicalComponent).isValidForChangingTheStatusToConfirmation();
      }
      return this.serviceTypeComponent.isValid();
    }
  }

  get isRegularFlightNonMedical(): boolean {
    return this._serviceOrder.type === ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL;
  }

  constructor(
    private planService: PlanService,
    private caseService: CaseService,
    private incidentService: IncidentService,
    private supplierAdapter: SupplierDropDownAdapter,
    private providerService: ProviderService,
    private dictionaryService: DictionaryService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private helperService: StakeholderHelperService,
    private stakeholderService: StakeholderService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    public caseStateService: CaseStateService,
    private dialogViewerService: CaseDialogViewerService,
    private exchangeService: ExchangeRateService,
    private settingService: SettingsService,
    private supplierProfileService: SupplierProfileService,
    private dialogStateService: DialogStateService,
    private caseMessageService: CaseMessageService
  ) {}

  ngOnInit() {
    this.type = 1;
    this.pollingActive = false;
    this.pollingActiveCnt = 0;
    this.setSeccaCaseNumber();
    this.getAllValidCaseCoverages();
    this.searchStartLocationFormControl = new UntypedFormControl();
    this.searchEndLocationFormControl = new UntypedFormControl();
    this.providerURL = this.providerService.getProviderURL();
    this.getLoggedInUser();
    this.selectStakeholders();
    this.getIncident();
    this.checkIfLeftSideIsValid();
  }

  ngOnDestroy(): void {
    this.pollingActive = false;
  }

  get reserveAmountRequired(): boolean {
    return this._serviceOrder.type !== ServiceTypeEnum.ADVISE_ON_INSURANCE_TERMS;
  }

  get isNotMedicalEscortServiceOrderType(): boolean {
    return this._serviceOrder.type !== ServiceTypeEnum.MEDICAL_ESCORT && this._serviceOrder.type !== ServiceTypeEnum.OTHER_REFUND_REQUEST;
  }

  get isNotMajorIncidentServiceOrderType(): boolean {
    return this._serviceOrder.type !== ServiceTypeEnum.MAJOR_INCIDENT;
  }

  showNotCoveredStatus() {
    return NOT_COVERED_SERVICE_ORDERS.includes(this.serviceOrder.type);
  }

  get selectedStakeholders(): CaseStakeholder[] {
    if (this.caseStakeholders == null) {
      return null;
    } else {
      const selectedStakeholders = this.serviceOrder.getSelectedStakeholderIds();
      if (selectedStakeholders) {
        return this.caseStakeholders.filter(stakeholder =>
          selectedStakeholders.includes(stakeholder.id)
        );
      }
      return this.caseStakeholders.filter(stakeholder => stakeholder.hasRole(StakeholderTypeEnum.endUser));
    }
  }

  private getIncident() {
    this.incidentService.getCaseIncident(this.incidentId).subscribe(incident => {
      this.incident = incident;
    });
  }

  private selectStakeholders() {
    if (this.selectionAwareStakeholders !== null && this.selectionAwareStakeholders !== undefined) {
      this.selectionAwareStakeholders.forEach(s => {
        let stakeholderWasAppearingOnServiceOrder = false;
        s.isSelected = stakeholderWasAppearingOnServiceOrder;
      });
      this.serviceOrder.caseStakeholderIds = this.selectionAwareStakeholders.map(a => +a.stakeholder.id);
    }
  }

  private getLoggedInUser() {
    this.dictionaryService.getLoggedInUser().subscribe(result => {
      this.loggedInUser = result;
    });
  }

  private setSeccaCaseNumber() {
    this.caseService.getCase(this._caseId.toString()).subscribe(result => {
      this.case = result;
      this.caseNumber = result.caseNumber;
    });
  }

  get InputTypePattern() {
    return InputTypePattern;
  }

  private getAllValidCaseCoverages() {
    this.planService.getValidCaseCoverages(this.caseId).subscribe(result => {
      const resultValidCaseCoverages: Coverage[] = result;
      let numberOfDefaultCoveragesAlwaysPresent = 2;
      if (this.serviceOrder.coverageTypeId === DefaultCoveragesAlwaysPresentEnum.UNKNOWN) {
        const coverage = new Coverage();
        coverage.coverageId = DefaultCoveragesAlwaysPresentEnum.UNKNOWN.toString();
        coverage.coverageName = 'Unknown';
        resultValidCaseCoverages.push(coverage);
        numberOfDefaultCoveragesAlwaysPresent = 3;
      }
      this.validCaseCoverages = resultValidCaseCoverages.map(
        value =>
          new CaseCoverageDropDown(
            +value.coverageId,
            value.coverageName,
            (resultValidCaseCoverages.length > numberOfDefaultCoveragesAlwaysPresent &&
                      +value.coverageId === DefaultCoveragesAlwaysPresentEnum.ACUTE_COMMITTED_BEFORE_POLICY_SELECTION) ||
              +value.coverageId === DefaultCoveragesAlwaysPresentEnum.UNKNOWN
          )
      );
    });
  }

  mockProviders(result: any[]) {
    if (result != null) {
      this.allProviders = result;
      this.providers = result
        .filter(
          stakeholder =>
            stakeholder.stakeholderType !== StakeholderTypeEnum.endUser &&
            stakeholder.stakeholderType !== StakeholderTypeEnum.reporter &&
            stakeholder.stakeholderType !== StakeholderTypeEnum.policyHolder &&
            stakeholder.stakeholderType !== StakeholderTypeEnum.person
        )
        .map(data => this.supplierAdapter.adapt(data))
        .map(data => new DropdownDictionary(+data.id, data.name))
        .filter(data => data.name != null)
        .sort((a, b) =>
          a.name === 'Egencia' ||
          a.name === 'Albin International Repatriation Limited' ||
          a.name === 'GET-E International Airport Transfers'
            ? -1 : 0); // Egencia, Albin International Repatriation Limited, GET-E International Airport Transfers top of list
    }
  }

  getIconUrl(stakeholder: CaseStakeholder): string {
    // Find type:
    let type = stakeholder.stakeholderType;
    if (stakeholder.coTraveller) {
      type = StakeholderTypeEnum.coTraveller;
    }
    return this.stakeholderService.getStakeholderIconFullPath(type as StakeholderTypeEnum);
  }

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  isOddNumber(index: number) {
    if (index % 2 === 0) {
      return true;
    }
    return false;
  }
  supplierChanged(supplierId: number) {
    this.checkIfLeftSideIsValid();
    if(!this.serviceOrder.exchangedCostValue){
      this.serviceOrder.exchangedCostValue = null;
    }
    this.lookupCreditorCurrency();
    this.supplierChange.emit(supplierId);
  }

  dataChanged() {
    this.checkIfLeftSideIsValid();
  }

  checkIfLeftSideIsValid() {
    if (this.serviceOrder.coverageTypeId != null &&
          ((this.serviceOrder.expectedCostValue?.length && +this.serviceOrder.expectedCostValue >= 0) || !this.reserveAmountRequired)) {
      this.serviceOrderTypeState.isLeftSideValid = true;
    }

    else if (this.serviceOrder.coverageTypeId != null &&
            (this._serviceOrder.type === ServiceTypeEnum.REFERRAL_TO_CUSTOMER || this._serviceOrder.type === ServiceTypeEnum.GENERAL_ADVICE)
    ) {
      this.serviceOrderTypeState.isLeftSideValid = true;
    }
    else if (this._serviceOrder.type === ServiceTypeEnum.MAJOR_INCIDENT) {
      this.serviceOrderTypeState.isLeftSideValid = true;
    }
    else {
      this.serviceOrderTypeState.isLeftSideValid = false;
    }
    this.serviceOrderTypeState.isSupplierChosen = this.serviceOrder.supplierId != null;
  }

  searchSupplier() {
    this.providerService.createProviderSearch(this.createJSONProviderSearchParameter()).subscribe(
      result => {
        if (result !== null) {
          this.providerResponse = result;
          this.pollUntilStakeholderUpdated();
          window.open(this.providerURL + this.providerResponse.uniqueId);
        }
      },
      error => {
        this.openProviderModal(error);
      }
    );
  }

  viewSupplier() {
    const result = this.returnedProviders.find(stakeholder => +stakeholder.id === this.serviceOrder.supplierId);
    if (!result || !result.company) {
      console.error(`No stakeholder with id: ${this.serviceOrder.supplierId} found or company is null`);
      return;
    }

    const data: SupplierSearchData = {supplierId: +result.company.providerId, caseId: +result.caseId};
    const matDialogRef = this.dialogViewerService.openSupplierSearchDialogFromPlansAddServices(data);
    if ( matDialogRef ) {
      setTimeout(() => {
        this.dialogStateService.sendToFront(matDialogRef.id);
      }, 0);
    }
  }

  searchSupplierNew() {
    if (this.settingService.isNewServiceProviderEnabled()) {
      const supplierDialogData = {
        caseId: this.caseId,
        serviceOrder: this.serviceOrder,
        suppliers: this.returnedProviders,
        incidentId: this.incidentId
      } as SupplierDialogData;

      const matDialogRef = this.dialogViewerService.openSupplierSearchDialog(supplierDialogData);
      if ( matDialogRef ) {
        setTimeout(() => {
          this.dialogStateService.sendToFront(matDialogRef.id);
        }, 0);
        matDialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
          if (!!supplierDialogData.caseStakeholderId) {
            this.serviceOrder.supplierId = supplierDialogData.caseStakeholderId;
            this.lookupCreditorCurrency();
          }
        });
      }
    }
  }
  lookupCreditorCurrency(){
    let provider = this.returnedProviders.find(provider => provider.id == this.serviceOrder.supplierId.toString());
    if(provider?.company?.providerId){
      this.supplierProfileService.getCreditorCurrency(Number(provider.company.providerId)).subscribe(
        result => {
          this.serviceOrder.exchangedCostCurrency = result;
          this.serviceOrder.exchangedCostValue = null;
          this.transferToExchange();
        }
      );
    }
  }

  transferToReserve(): void {
    if(!this.serviceOrder.exchangedCostCurrency || this.serviceOrder.exchangedCostCurrency == ""){
      return;
    }
    this.exchangeService.getExchangeRateNow(this.serviceOrder.exchangedCostCurrency, this.serviceOrder.expectedCostCurrency).subscribe(
      result => {
        this.serviceOrder.expectedCostValue = (parseFloat(this.serviceOrder.exchangedCostValue) * result.normalRate).toFixed(2);
        this.checkIfLeftSideIsValid();
      }
    );
  }

  reserveChanged(newValue: any): void {
    this.serviceOrder.expectedCostValue = newValue.toString();
    this.transferToExchange();
    this.checkIfLeftSideIsValid();
  }

  transferToExchange(): void {
    if(!this.serviceOrder.exchangedCostCurrency || this.serviceOrder.exchangedCostCurrency == ""){
      return;
    }
    this.exchangeService.getExchangeRateNow(this.serviceOrder.expectedCostCurrency, this.serviceOrder.exchangedCostCurrency).subscribe(
      result => {
        this.serviceOrder.exchangedCostValue = (parseFloat(this.serviceOrder.expectedCostValue) * result.normalRate).toFixed(2);
      }
    );
  }
  exchangedAmountChanged(newValue: any): void {
    this.serviceOrder.exchangedCostValue = newValue.toString();
    this.transferToReserve();
  }
  exchangedCurrencyChanged(): void {
    this.transferToExchange();
  }

  private openProviderModal(error: any) {
    this.address = new ProviderHelper(this.modalService);
    this.address.messageError(error, 'provider-error-modal-header', 'provider-error-title', 'provider-error-message');
  }

  private pollUntilStakeholderUpdated() {
    if (this.pollingActive || this.allowSearchSupplierIgnoreTransfer ) {
      return;
    }
    this.pollingActive = true;
    this.pollingCheckTime = null;
    this.oldReturnedProviders = this.returnedProviders;

    this.$stakeholderPollSubscr = interval(this.pollingInterval)
      .pipe(
        switchMap(() => this.providerService.providerModified(this.caseId, this.pollingCheckTime, null)),
        tap(result => this.handlePollResult(result)),
        takeWhile(() => this.pollingActive),
      )
      .subscribe(
        () => {},
        error => {
          console.log(error);
          this.pollingActive = false;
        }
      );
  }

  private handlePollResult(response: ProviderModifiedResponseDto): void {
    if (response.modifiedStakeholders.length !== 0) {
      this.loadStakeholders();
      this.caseStateService.sendProvidersUpdated();
      this.pollingActive = false;
    }

    this.pollingCheckTime = response.lastCheckTime;
  }

  private loadStakeholders(): void {
    this.caseService.getCaseStakeholdersOnCase(this.caseId.toString()).subscribe(result => {
      this.returnedProviders = result;

      // Find new stakeholders
      if (this._returnedProviders && this._returnedProviders.length > 0) {
        const newProviders = this._returnedProviders.filter(
          theNew => this.oldReturnedProviders && !this.oldReturnedProviders.find(theOld => +theOld.id === +theNew.id)
        );

        if (newProviders.length === 1) {
          // Only one provider, select it
          this.serviceOrder.supplierId = +newProviders[0].id;
          this.supplierAddedEvent.emit(this.serviceOrder.supplierId);
        } else if (newProviders.length > 1) {
          // More providers, remove any contacts
          const newProvidersNoContacts = newProviders.filter(
            provider => StakeholderTypeEnum.assistanceContact !== provider.stakeholderType &&
                        StakeholderTypeEnum.sosServiceOffice !== provider.stakeholderType
          );
          if (newProvidersNoContacts.length === 1) {
            // Only one provider, select it
            this.serviceOrder.supplierId = +newProvidersNoContacts[0].id;
            this.supplierAddedEvent.emit(this.serviceOrder.supplierId);
          }
        }
      }
    });
  }

  private createJSONProviderSearchParameter() {
    const isEndUserCurrentLocation = this.incident.currentLocation.country !== null;

    const searchParameter = new ProviderSearch({
      caseNumber: null !== this.caseNumber ? this.caseNumber : this.translateService.instant('provider-call-without-case-number'),
      caseId: this.caseId.toString(),
      stakeholderId: '',
      webUser: this.loggedInUser.initials.toUpperCase(),
      clientLocation: {
        address: '',
        postalCode: isEndUserCurrentLocation ? this.incident.currentLocation.postalCode : this.incident.incidentLocation.postalCode,
        cityName: isEndUserCurrentLocation ? this.incident.currentLocation.locality : this.incident.incidentLocation.locality,
        country: {
          name: isEndUserCurrentLocation ? this.incident.currentLocation.country : this.incident.incidentLocation.country,
        },
      },
      searchParameters: {
        providerType: '',
        name: '',
        service: '',
        contactDetail: '',
        city: '',
        countryCode: '',
      },
      integrationInformation: {
        showIntegration: true,
        WebfileWaitForBrowser: false,
        showSearch: true,
        textHeader: '',
        textBody: this.translateService.instant('provider-please-choose-the-required-provider'),
      },
    });
    return searchParameter;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  changeStatus(val: StatusTypes, event: any = null) {
    if (val === this.serviceOrder.status && event != null) {
      return false;
    }
    if (this.serviceOrderTypeState.isConfirmationTabSaved && val === StatusTypes.EXPECTED) {
      return false;
    }

    if ( this.serviceOrder.status == StatusTypes.EXPECTED && this.serviceOrderType && this.serviceOrder.extension.getCommitmentType() &&
      !this.serviceOrderType.commitmentTypes.some((obj) => {
        return obj.businessKey === this.serviceOrder.extension.getCommitmentType().businessKey;
    })) {
      this.serviceOrder.extension.setCommitmentType(null);
    }

    if (val !== this.serviceOrder.status) {
      this.serviceOrder.status = val;
      this.serviceOrderTypeState.statusChangeEvent.emit(val);
    }
    if (val === StatusTypes.COMMITTED || val === StatusTypes.CANCELLED) {
      this.serviceOrderTypeState.showConfirmationTab = true;
      this.serviceOrderTypeState.showDetailsTab = true;
    }
    if (val === StatusTypes.EXPECTED) {
      this.serviceOrderTypeState.showConfirmationTab = false;
      this.serviceOrderTypeState.showDetailsTab = false;
    }
  }

  expectedDisabled(): boolean {
    return this.expectedNotPossible || (this.selectedServiceOrderInitialStatus !== StatusTypes.EXPECTED && this.selectedServiceOrderInitialStatus !== StatusTypes.NOT_COVERED);
  }

  notCoveredDisabled() : boolean {
    return this.serviceOrder.status !== StatusTypes.EXPECTED &&  this.serviceOrder.status !== StatusTypes.NOT_COVERED;
  }

  commitedDisabled(): boolean {
    return !this.isRegularFlightNonMedical && (!this.serviceOrderTypeState.isLeftSideValid || !this.isRightSideValid);
  }

  canceledDisabled(): boolean {
    return (
      !this.serviceOrderTypeState.isLeftSideValid ||
      !this.isRightSideValid ||
      this.selectedServiceOrderInitialStatus === StatusTypes.EXPECTED
    );
  }

  iccCommitedDisabled(): boolean {
    return this.serviceOrder.status !== StatusTypes.CANCELLED || this.iccServiceOrder.status !== StatusTypes.COMMITTED;
  }

  iccCommitedHover(): string {
    if (this.iccServiceOrder.status == StatusTypes.COMMITTED  && this.serviceOrder.status == StatusTypes.CANCELLED) {
      return null;
    } else {
      return 'coordination-linked-service-order-status-disabled-text';
    }
  }

  iccCanceledDisabled(): boolean {
    return this.selectedServiceOrderInitialStatus !== StatusTypes.COMMITTED;
  }

  iccCancelledHover(): string {
    if(this.serviceOrder.status == StatusTypes.CANCELLED || (this.iccServiceOrder.status == StatusTypes.COMMITTED  && this.serviceOrder.status == StatusTypes.COMMITTED)) {
      return null;
    } else {
      return 'coordination-linked-service-order-status-disabled-text';
    }
  }

  get isAmbulanceFlight(): boolean {
    return this.serviceOrder.type === ServiceTypeEnum.AMBULANCE_FLIGHT;
  }

  get isCommitted(): boolean {
    return this.serviceOrder.status === StatusTypes.COMMITTED;
  }

  get StatusTypes() {
    return StatusTypes;
  }

  get canceledWarningVisible(): boolean {
    return this.serviceOrder.status === StatusTypes.CANCELLED;
  }

  get notCoveredWarningVisible(): boolean {
    return this.serviceOrder.status === StatusTypes.NOT_COVERED;
  }
  sendSupplierInformation(stakeholderId: number): void {
    const message = new Message();
    message.templateType = 'CONTACT_INFO';
    message.messageChannelType = MessageChannelType.SMS;
    message.sms = new PhoneDto();
    message.selectedStakeholder = this.returnedProviders.find(cs => cs.id == ""+stakeholderId);
    message.caseId = ""+this.serviceOrder.caseId;
    const srMessage = new ServiceOrderMessageRequest({
      messageService: this.caseMessageService,
      message });
    let matDialogRef= this.dialogViewerService.openOutputManagementDialog(this.case, message.messageChannelType, srMessage, null);
    if ( matDialogRef ) {
      setTimeout(() => {
        this.dialogStateService.sendToFront(matDialogRef.id);
      }, 0);
    }
  }
}
