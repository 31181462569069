import { ActivatedRoute } from '@angular/router';
import { PlanService } from './../../../../../core/services/plan.service';
import { Subscription } from 'rxjs';
import { Coverage } from './../../../../../shared/models/coverage';
import { CasePlansService } from '../case-plans.service';
import { Component, Input, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { ReserveCoverage } from '@secca/shared/models/reserveCoverage';

@Component({
  selector: 'app-case-coverage-info',
  templateUrl: './case-coverage-info.component.html',
  styleUrls: ['./case-coverage-info.component.scss']
})
@AutoUnsubscribe
export class CaseCoverageInfoComponent implements OnInit {
  @Input() coverages: Coverage[];
  @Input() caseId: string;
  remaining: number;
  billingCurrency: string;
  reserveCoverage: ReserveCoverage;
  $coverageSubscr: Subscription;

  constructor(private casePlansService: CasePlansService, private planService: PlanService) {
    this.$coverageSubscr = this.casePlansService.getReserveCoverages().subscribe(reserveCoverage => {
      this.reserveCoverage = reserveCoverage;
      this.calculateCaseCoverage();
    });
  }

  ngOnInit() {
    this.planService.getReserveCoverageForCase(this.caseId).subscribe(
      result => this.casePlansService.sendReserveCoverage(result),
    );
  }

  calculateCaseCoverage() {
    if (this.caseId) {
      this.planService.getBillingCurrencyForCase(+this.caseId).subscribe(result => {
        this.billingCurrency = result.billingCurrency;
      });
    } else {
      this.billingCurrency = '';
    }
    if (this.reserveCoverage != null) {
      this.remaining = this.reserveCoverage.reserve - this.reserveCoverage.settled;
    }
  }
}
