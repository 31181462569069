<div *ngIf="loaded">
  <div fxLayout="row">
    <div fxFlex="100">
      <div class="top-container">
        <div class="case-info-container">
          <app-case-info *ngIf="caseContainer"
                         (setCaseTypeChanged)="refreshCaseType()"
                         (caseTypeReset)="resetCaseType()"
                         [caseTypeTranslated]="caseType?.name"
                         [caseTypeManuallySet]="caseTypeManuallySet"
                         [caseTypeResetDisabled]="caseType?.caseTypeResetDisabled"
                         [caseTypeCode]="caseType?.code" [caseId]="caseContainer.id" [caseNumber]="caseContainer.caseNumber"
                         [caseCreateDate]="getValidatedOn" class='case-info'></app-case-info>
        </div>
        <app-case-timeline
          *ngIf="caseContainer"
          [case]="caseContainer"
          [caseClosed]="caseClosed"
          [financialStatus]="financialStatus"
          class='case-timeline'
          #caseTimeline>
        </app-case-timeline>
      </div>

      <div fxLayout="row">
        <div fxFlex="270px">
          <app-case-cases class='case-cases' *ngIf="caseContainer" [case]="caseContainer" (menuClick)="onMenuClick($event)">
          </app-case-cases>
          <div class="left-container">
            <app-pinned-buttons [seccaCase]="caseContainer" [caseClosed]="caseClosed" [consent]="consent"></app-pinned-buttons>
            <app-case-primary-contact class='case-primary-contact'></app-case-primary-contact>
            <app-case-local-time class='case-local-time' [seccaCase]="caseContainer"></app-case-local-time>
            <app-case-travel-info [seccaCase]='caseContainer'></app-case-travel-info>
            <app-case-terms [case]="caseContainer"></app-case-terms>
          </div>
        </div>
        <div fxFlex="100">

          <div class="content-container">
            <div class="inner-content-container" *ngIf="loaded && caseContainer">
              <app-case-task *ngIf="caseContainer && selectedMenuNumber === CaseSelectedMenuEnum.CaseTask"
                             [caseId]="caseContainer.id">
              </app-case-task>
              <div *ngIf="caseContainer && selectedMenuNumber === CaseSelectedMenuEnum.CaseBasics" class='case-basics-scroll'>
                <app-case-basics [newCase]='newCase'
                                 *ngIf="caseContainer && selectedMenuNumber === CaseSelectedMenuEnum.CaseBasics" [case]="caseContainer"
                                 [consent]="consent"
                                 [numberOfLinkedCases]="numberOfLinkedCases"
                                 [numberOfCoordinationCases]="numberOfCoordinationCases"
                                 #caseBasics>
                </app-case-basics>
              </div>
              <app-case-summary
                *ngIf="caseContainer && selectedMenuNumber === CaseSelectedMenuEnum.CaseSummary" [numberOfLinkedCases]="numberOfLinkedCases" [numberOfCoordinationCases]="numberOfCoordinationCases"
                [case]="caseContainer" [consent]="consent" (channelTypeChanged)="refreshChannelType($event)"
                #caseSummary>
              </app-case-summary>
              <app-case-plans *ngIf="selectedMenuNumber === CaseSelectedMenuEnum.CasePlan" [case]="caseContainer" [numberOfLinkedCases]="numberOfLinkedCases">
              </app-case-plans>
              <div *permission="PermissionEnum.MEDICAL_READ;hideType: PermissionHideTypeEnum.DISABLE">
                <app-case-medical #caseMedical
                                  *ngIf="selectedMenuNumber === CaseSelectedMenuEnum.CaseMedical" [case]="caseContainer">
                </app-case-medical>
              </div>
              <app-case-stakeholders *ngIf="caseContainer && selectedMenuNumber === CaseSelectedMenuEnum.Stakeholders"
                                     [case]="caseContainer" #caseStakeholders></app-case-stakeholders>
              <app-case-economy *ngIf="caseContainer && selectedMenuNumber === CaseSelectedMenuEnum.CaseEconomy"
                                [case]="caseContainer" #caseEconomy></app-case-economy>
              <app-case-documents *ngIf="selectedMenuNumber === CaseSelectedMenuEnum.Documents" [caseId]="caseContainer.id" [caseNumber]="caseContainer.caseNumber">
              </app-case-documents>
              <app-case-about *ngIf="selectedMenuNumber === CaseSelectedMenuEnum.About" [case]="caseContainer">
              </app-case-about>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div fxFlex="465px">
      <div fxLayout="column">
        <div class="right-container">
          <div class="right-container-content">
            <app-case-handlers *ngIf="caseContainer" [case]="caseContainer" [handlingAreas]="handlingAreas" (changeHandlingArea)="changeHandlingArea($event)"></app-case-handlers>
          </div>
        </div>
        <app-case-coverage-info *ngIf="caseContainer" [caseId]="caseContainer.id" [coverages]="coverages"></app-case-coverage-info>
        <div class="d-flex">
          <app-case-consent *ngIf="caseContainer && consent" [caseId]="caseContainer.id" #digitalConsent [digitalConsent]="consent" (consentChanged)="consentChanged($event)"></app-case-consent>
          <app-case-sensitive [sensitiveInformation]="caseContainer.sensitiveType" [caseId]="caseContainer.id" (sensitiveTypeChanged)="sensitiveTypeChanged($event)"></app-case-sensitive>
        </div>
        <app-case-screening-tool [case]="caseContainer" *ngIf="caseContainer"></app-case-screening-tool>
        <div *permission="PermissionEnum.NOTE_CREATE;hideType: PermissionHideTypeEnum.DISABLE">
          <app-case-notes *ngIf="loaded && caseContainer" [caseId]="caseContainer.id"></app-case-notes>
        </div>
      </div>

    </div>
  </div>
</div>

<!--<div *ngIf="loaded">-->
<!--  <div class='top-container'>-->
<!--    <div class="case-info-container">-->
<!--      <app-case-info *ngIf="caseContainer"-->
<!--                     (setCaseTypeChanged)="refreshCaseType()"-->
<!--                     [caseTypeTranslated]="caseType?.name"-->
<!--                     [caseTypeCode]="caseType?.code" [caseId]="caseContainer.id" [caseNumber]="caseContainer.caseNumber"-->
<!--                     [caseCreateDate]="getValidatedOn" class='case-info'></app-case-info>-->
<!--    </div>-->
<!--    <app-case-timeline-->
<!--      *ngIf="caseContainer"-->
<!--      [case]="caseContainer"-->
<!--      [caseClosed]="caseClosed"-->
<!--      class='case-timeline'-->
<!--      #caseTimeline>-->
<!--    </app-case-timeline>-->
<!--  </div>-->
<!--  <div class='right-container'>-->
<!--    <div class="right-container-content">-->
<!--      <app-case-handlers *ngIf="caseContainer" [case]="caseContainer" [handlingAreas]="handlingAreas" (changeHandlingArea)="changeHandlingArea($event)"></app-case-handlers>-->
<!--    </div>-->
<!--  </div>-->
<!--  <app-case-cases class='case-cases' *ngIf="caseContainer" [case]="caseContainer" (menuClick)="onMenuClick($event)">-->
<!--  </app-case-cases>-->
<!--  <div class='left-container' >-->
<!--    <app-pinned-buttons [seccaCase]="caseContainer" [consent]="consent"></app-pinned-buttons>-->
<!--    <app-case-primary-contact class='case-primary-contact'></app-case-primary-contact>-->
<!--    <app-case-local-time class='case-local-time'></app-case-local-time>-->
<!--    <app-case-terms [case]="caseContainer"></app-case-terms>-->
<!--  </div>-->
<!--  <div class="content-container">-->
<!--    <div class="inner-content-container" *ngIf="loaded && caseContainer">-->
<!--      <app-case-task *ngIf="caseContainer && selectedMenuNumber === CaseSelectedMenuEnum.CaseTask"-->
<!--        [caseId]="caseContainer.id">-->
<!--      </app-case-task>-->
<!--      <div *ngIf="caseContainer && selectedMenuNumber === CaseSelectedMenuEnum.CaseBasics" class='case-basics-scroll'>-->
<!--        <app-case-basics [newCase]='newCase'-->
<!--          *ngIf="caseContainer && selectedMenuNumber === CaseSelectedMenuEnum.CaseBasics" [case]="caseContainer"-->
<!--          [consent]="consent"-->
<!--          #caseBasics>-->
<!--        </app-case-basics>-->
<!--      </div>-->
<!--      <app-case-summary-->
<!--        *ngIf="caseContainer && selectedMenuNumber === CaseSelectedMenuEnum.CaseSummary" [case]="caseContainer" [consent]="consent"-->
<!--        #caseSummary>-->
<!--      </app-case-summary>-->
<!--      <app-case-plans *ngIf="selectedMenuNumber === CaseSelectedMenuEnum.CasePlan" [case]="caseContainer">-->
<!--      </app-case-plans>-->
<!--      <div *permission="PermissionEnum.MEDICAL_READ;hideType: PermissionHideTypeEnum.DISABLE">-->
<!--        <app-case-medical #caseMedical-->
<!--          *ngIf="selectedMenuNumber === CaseSelectedMenuEnum.CaseMedical" [case]="caseContainer">-->
<!--        </app-case-medical>-->
<!--      </div>-->
<!--      <app-case-stakeholders *ngIf="caseContainer && selectedMenuNumber === CaseSelectedMenuEnum.Stakeholders"-->
<!--        [case]="caseContainer" #caseStakeholders></app-case-stakeholders>-->
<!--      <app-case-economy *ngIf="caseContainer && selectedMenuNumber === CaseSelectedMenuEnum.CaseEconomy"-->
<!--        [case]="caseContainer" #caseEconomy></app-case-economy>-->
<!--      <app-case-documents *ngIf="selectedMenuNumber === CaseSelectedMenuEnum.Documents" [caseId]="caseContainer.id" [caseNumber]="caseContainer.caseNumber">-->
<!--      </app-case-documents>-->
<!--      <app-case-about *ngIf="selectedMenuNumber === CaseSelectedMenuEnum.About" [case]="caseContainer">-->
<!--      </app-case-about>-->
<!--    </div>-->
<!--  </div>-->
<!--  <app-case-coverage-info *ngIf="caseContainer" [caseId]="caseContainer.id" [coverages]="coverages"></app-case-coverage-info>-->
<!--  <app-case-consent *ngIf="caseContainer && consent" [caseId]="caseContainer.id" #digitalConsent [digitalConsent]="consent" (consentChanged)="consentChanged($event)"></app-case-consent>-->
<!--  <app-case-screening-tool [case]="caseContainer" *ngIf="caseContainer"></app-case-screening-tool>-->
<!--  <div *permission="PermissionEnum.NOTE_CREATE;hideType: PermissionHideTypeEnum.DISABLE">-->
<!--    <app-case-notes *ngIf="loaded && caseContainer" [caseId]="caseContainer.id"></app-case-notes>-->
<!--  </div>-->
<!--</div>-->
<div [style.display]="loaded ? 'none' : 'block'">
  <div class="skeleton">
    <div class='top'>
      <div class='case-info-container ghost'> </div>
      <div class='timeline-container ghost'> </div>
    </div>
    <div class='right-container'>
      <div class="right-container-content ghost"></div>
    </div>
    <div class='case-container ghost'></div>
    <div class="left-container">
      <div class="pinned-buttons-container">
        <div class="action-button ghost"></div>
        <div class="action-button ghost"></div>
        <div class="action-button ghost"></div>
      </div>
      <div class="time-container ghost"></div>
    </div>
    <div class="content-container">
      <div class="inner-content-container ghost"> </div>
    </div>
  </div>
</div>
