<div class="recovery-task-table" *ngIf="taskViewModels; else loadingTemplate">
    <div class="primary-table-container">
      <table class="primary-table fixed-header-table">
            <thead>
                <tr>
                    <td class="checkbox-column-header">
                        <div class="custom-checkbox checkbox-header-container">
                            <label class="checkbox">
                                <input type="checkbox" (click)="selectAllTasks()" [(ngModel)]="isAllSelected" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </td>
                    <td (click)="sortBy('customerProfileId')" [ngClass]="sortedBy('customerProfileId')">
                        {{ 'board-task-recovery-table-customer-profile' | translate }}
                    </td>
                    <td (click)="sortBy('createdOn')" [ngClass]="sortedBy('createdOn')">
                      {{ 'board-task-recovery-table-created-date' | translate }}
                    </td>
                    <td (click)="sortBy('updatedOn')" [ngClass]="sortedBy('updatedOn')">
                      {{ 'board-task-recovery-table-updated-date' | translate }}
                    </td>
                    <td (click)="sortBy('dueDate')" [ngClass]="sortedBy('dueDate')">
                        {{ 'board-task-recovery-table-due-date' | translate }}
                    </td>
                    <td (click)="sortBy('caseNumber')" [ngClass]="sortedBy('caseNumber')">
                        {{ 'board-task-recovery-table-case-number' | translate }}
                    </td>
                    <td (click)="sortBy('recoveryId')" [ngClass]="sortedBy('recoveryId')">
                        {{ 'board-task-recovery-table-recovery-id' | translate }}
                    </td>
                    <td (click)="sortBy('appliedAmount')" [ngClass]="sortedBy('appliedAmount')">
                        {{ 'board-task-recovery-table-eligible-amount' | translate }}
                    </td>
                    <td (click)="sortBy('appliedAmount')" [ngClass]="sortedBy('appliedAmount')">
                        {{ 'board-task-recovery-table-applied-amount' | translate }}
                    </td>
                    <td (click)="sortBy('recoveredAmount')" [ngClass]="sortedBy('recoveredAmount')">
                        {{ 'board-task-recovery-table-recovered-amount' | translate }}
                    </td>
                    <td (click)="sortBy('recoveryStatus')" [ngClass]="sortedBy('recoveryStatus')">
                        {{ 'board-task-recovery-table-status' | translate }}
                    </td>
                    <td (click)="sortBy('applicationDeadline')" [ngClass]="sortedBy('applicationDeadline')">
                      {{ 'board-task-recovery-table-application-deadline-amount' | translate }}
                    </td>
                    <td (click)="sortBy('applicationDeadline')" [ngClass]="sortedBy('applicationDeadline')">
                      {{ 'board-task-recovery-table-remaining-days' | translate }}
                    </td>
                    <td (click)="sortBy('assignedTo')" [ngClass]="sortedBy('assignedTo')">
                        {{ 'board-task-recovery-table-recovery-assignee' | translate }}
                    </td>
                </tr>
            </thead>
            <tbody (scroll)="onCaseTabelScroll()" id="taskRecoveryTable" [class.zoomed]="settingsService.isZoomed()">
                <tr *ngFor="let task of taskViewModels; let i = index" [class.task-check]="task.isSelected" [class.task-row-red]="!task.recoveryId">
                    <td class="checkbox-column">
                        <div class="custom-checkbox checkbox-content-container">
                            <label class="checkbox">
                            <input type="checkbox" [(ngModel)]="task.isSelected" />
                            <span class="checkmark"></span>
                            </label>
                        </div>
                    </td>
                    <td class="customer-column">
                        <img *ngIf="task.customerProfileId" [src]="getBrandImagesUrlForProfile(task.customerProfileId)" />
                    </td>
                    <td>{{ task.createdOn | momentFormat :'DD MMM' : 'local' }}</td>
                    <td>{{ task.updatedOn | momentFormat :'DD MMM' : 'local' }}</td>
                    <td>{{ task.dueDate | momentFormat :'DD MMM' : 'local' }}</td>
                    <td class="link-column" (click)="redirectToManageRecovery(task.caseId, task.recoveryId)">{{ task.caseNumber }}</td>
                    <td>{{ task.recoveryId }}</td>
                    <td class="amount-column">
                      <span class="currency-value">{{ task.recoveryCurrency }}</span>
                      {{ (isApplicationSent(task.recoveryStatus) ? 0: (task.eligibleAmount || 0)) | number : '.2' }}
                    </td>
                    <td class="amount-column">
                      <span class="currency-value">{{ task.recoveryCurrency }}</span>
                      {{ (isApplicationSent(task.recoveryStatus) ? (task.appliedAmount || 0) : 0) | number: '.2' }}
                    </td>
                    <td class="amount-column">
                      <span class="currency-value">{{ task.recoveryCurrency }}</span>{{ (task.recoveredAmount || 0) | number: '.2' }}
                    </td>
                    <td>{{ getRecoveryStatusText(task.recoveryStatus) }}</td>
                    <td>{{ task.applicationDeadline | date: 'dd MMM YYYY' | lowercase }}</td>
                    <td>
                      {{ getRemainingDays(task) }} {{'board-task-recovery-table-days' | translate}}
                    </td>
                    <td>{{ task.assignedTo }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<ng-template #loadingTemplate>
    <div class="d-flex justify-content-center pt-3">
      <h4>{{ 'board-task-table-refreshing' | translate }}</h4>
    </div>
</ng-template>
