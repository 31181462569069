import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { Case } from '@secca/shared/models/case';
import { combineLatest, Subscription } from 'rxjs';
import { TravelInfoService } from '@secca/case/components/case-travel-info/service/travel-info.service';
import { CaseLocalTimeService } from '@secca/case/components/case-local-time/case-local-time.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { CaseService } from '@secca/core/services/case.service';
import {TravelInfoDto} from "@secca/case/components/case-travel-info/model/travelInfoDto";


@AutoUnsubscribe
@Component({
  selector: 'app-case-travel-info',
  templateUrl: './travel-info.component.html',
  styleUrls: ['./travel-info.component.scss']
})
export class TravelInfoComponent implements OnInit {
  @Input() seccaCase: Case;
  travelInfo: TravelInfoDto;
  private timezone$: Subscription;
  private displayTravelInfo: boolean;

  constructor(private travelInfoService: TravelInfoService,
              private localTimeService: CaseLocalTimeService,
              private caseService: CaseService) {
  }

  ngOnInit(): void {
    this.timezone$ = combineLatest([this.localTimeService.getLocalTimeZone(),
                                    this.caseService.subscribeCustomerProfileChanged()])
      .subscribe(([localTimeZone, customerProfile]) => {
        let countryCodeAlpha2: string;
        if (localTimeZone) {
          countryCodeAlpha2 = localTimeZone.countryCodeAlpha2 || this.seccaCase?.incident?.currentCountryCodeAlpha2;
        }
        if (customerProfile?.adtCode === 'ADT04') {
          this.displayTravelInfo = true;
          this.updateTravelInfo(countryCodeAlpha2);
        } else {
          this.displayTravelInfo = false;
          this.travelInfo = null;
        }
      });
  }

  private updateTravelInfo(countryCodeAlpha2: string): void {
    if (countryCodeAlpha2) {
      this.travelInfoService.fetchTravelInfo(countryCodeAlpha2).subscribe({
        next: (travelInfo) => {
          countryCodeAlpha2 !== 'DK' && this.displayTravelInfo ? this.travelInfo = travelInfo : this.travelInfo = null;
        },
        error: (error) => {
          console.error('Error fetching travel info:', error);
          this.travelInfo = null;
        }
      });
    }
  }

  openTravelGuide() {
    window.open(this.travelInfo.sourceUrl, '_blank');
  }
}
