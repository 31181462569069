<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      <span *ngIf="isExternalTaskSearch || isUnrelatedTaskSearch">{{ title | translate }} <span *ngIf="title">-</span> </span>
      {{ 'search-case-title' | translate }}
    </div>
    <div class="modal-buttons">
      <div (click)="closeModal()">
        <img src="/assets/icons/Close White.svg" />
      </div>
    </div>
  </div>
  <div class="body-1 modal-body">
    <div *ngIf="!searchResultsFullMode">
      <form (ngSubmit)="searchResultButton.focus()">
        <div class="row">
          <div class="col-2 column">
            <div class="clearfix">
              <div class="fw-bold float-start" >{{'search-case-case' | translate}}</div>
              <div
                [className]="clearCaseButtonActive() === false ? 'float-end grayed-out' : 'float-end clearFields'"
                (click)="clearCaseSearchFields(true)">Clear
              </div>
            </div>
            <app-input [name]="'search-case-case-id' | translate" [(value)]="searchCase.caseNumber"
                       (keydown)="caseNumberKeyDown($event)"
                       (paste)="caseNumberPaste($event)"
              (saveModel)="modelChanged()" (valueChange)="caseNumberChanged($event)"
              [validationType]="showMandatoryColor ? ValidationTypeEnum.required : ValidationTypeEnum.default"
              [highlightBorderOnInputNonEmpty]="!!searchCaseRequestObj.caseNumber">
            </app-input>
            <div class="input-field-error" *ngIf="isInputInvalid('caseNumber')">{{'search-case-case-number-format' | translate}}</div>
            <div>
              <div class="date-range-container">
                <div class="date-box">
                  <app-input-calendar [withImage]="true" [name]="'search-case-case-creation-period' | translate"
                    [(date)]="searchCase.caseCreationDateFrom"
                    [highlightBorderOnInputNonEmpty]="true"
                    (dateChange)="caseCreationDateFromChanged($event)">
                  </app-input-calendar>
                </div>
                <div class="date-spacer">-</div>
                <div class="date-box-no-name">
                  <app-input-calendar [withImage]="true" [name]=" "
                    [(date)]="searchCase.caseCreationDateTo"
                    [highlightBorderOnInputNonEmpty]="true"
                    (dateChange)="caseCreationDateToChanged($event)">
                  </app-input-calendar>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <app-drop-down [name]="'search-case-channel' | translate"
                [items]="channelDisabled ? allChannelTypes : selectableChannelTypes"
                [(selectedItemId)]="searchCase.channel"
                [disabled]="channelDisabled">
              </app-drop-down>
            </div>
            <div class="mt-2">
              <app-input [name]="'search-case-case-phone' | translate" [(value)]="searchCase.casePhoneNumber"
              (saveModel)="searchCases()" [validationType]="ValidationTypeEnum.numbers"
              [highlightBorderOnInputNonEmpty]="!!searchCaseRequestObj.casePhoneNumber && searchCase.casePhoneNumberIncludedInSearch"></app-input>
              <div class="input-field-error" *ngIf="isInputLengthInvalid('casePhoneNumber')">{{'search-case-minimum-length' | translate: {'min': getValidationLength('casePhoneNumber')} }}</div>
            </div>
            <div class="custom-control custom-radio float-start d-flex">
              <div class="radio-padding">
                <input type="radio" class="custom-control-input me-2" id="ignoreCasePhoneChecked" name="ignoreCasePhone"
                  [(ngModel)]="searchCase.casePhoneNumberIncludedInSearch" (ngModelChange)="searchCases()" [value]=true>
              </div>
              <div>
                  <label class="custom-control-label" for="ignoreCasePhoneChecked">{{'search-case-include-in-search' | translate}}</label>
              </div>
            </div>
            <div class="custom-control custom-radio float-start d-flex">
              <div class="radio-padding">
                <input type="radio" class="custom-control-input me-2" id="ignoreCasePhoneUnchecked" name="ignoreCasePhone"
                [(ngModel)]="searchCase.casePhoneNumberIncludedInSearch" (ngModelChange)="searchCases()" [value]=false>
              </div>
              <div>
                <label class="custom-control-label" for="ignoreCasePhoneUnchecked">{{'search-case-ignore-in-search' | translate}}</label>
              </div>
            </div>
          </div>
          <div class="col-3 column">
            <div class="clearfix">
              <div class="fw-bold float-start">{{'search-case-customer' | translate}}</div>
              <div
                [className]="clearCustomerButtonActive() === false ? 'float-end grayed-out' : 'float-end clearFields'"
                (click)="clearCustomerSearchFields(true)">{{'search-case-clear' | translate}}
              </div>
            </div>
            <span *ngIf="!isExternalTaskSearch">
              <div [appInputFocus]="true">
                <app-drop-down-customer-profile [name]="'search-case-customer-profile' | translate"
                  [validationType]="showMandatoryColor ? ValidationTypeEnum.required : ValidationTypeEnum.default"
                  [highlightBorderOnInputNonEmpty]="true"
                  [(selectedItemId)]="searchCase.customer" (selectedItemIdChange)="customerProfileChanged($event)"
                  [items]="customerProfiles">
                </app-drop-down-customer-profile>

                <app-drop-down-customer-profile [name]="'search-case-customer-profile-group-member' | translate"
                  [validationType]="ValidationTypeEnum.default" [highlightBorderOnInputNonEmpty]="true"
                  [(selectedItemId)]="searchCase.memberProfile"
                  (selectedItemIdChange)="memberProfileChanged($event)" [items]="memberProfiles"
                  [disabled]="memberProfiles ? memberProfiles.length === 0 : true"
                  [blankTitle]="'search-case-customer-profile-group-profile' | translate">
                </app-drop-down-customer-profile>
              </div>
            </span>

            <div [ngClass]="{ 'mt-2': !isExternalTaskSearch }" >
              <app-input [name]="'search-case-policy-no' | translate" [(value)]="searchCase.policyRefNo" [highlightBorderOnInputNonEmpty]="!!searchCaseRequestObj.policyRefNo"
                (saveModel)="modelChanged()">
              </app-input>
              <div class="input-field-error" *ngIf="isInputLengthInvalid('policyRefNo')">{{'search-case-minimum-length' | translate: {'min': getValidationLength('policyRefNo')} }}</div>
            </div>
            <div class="mt-2">
              <app-input [name]="'search-case-customer-ref-no' | translate" [(value)]="searchCase.customerRefNo" [highlightBorderOnInputNonEmpty]="true"
                (saveModel)="modelChanged()">
              </app-input>
            </div>
            <br />
          </div>
          <div class="col-4 column">
            <div class="row rowPadding">
              <div class="col-12 stakeholderHeader">
                <div class="fw-bold float-start ">{{'search-case-stakeholder' | translate}}</div>
                <div
                  [className]="clearStakeholderButtonActive() === false ? 'float-end grayed-out' : 'float-end clearFields'"
                  (click)="clearStakeholderSearchFields(true)">{{'search-case-clear' | translate}}
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-6 ">
                    <app-drop-down-countries-auto-complete [name]="'search-case-national-id' | translate"
                      [withInput]="true" [showCode]="true" [selectedItemId]="nationalIdCountry"
                      (selectedItemIdChange)="nationalIdCountryChanged($event);modelChanged()"
                      [(value)]="searchCase.nationalId"
                      (saveModel)="modelChanged()"
                      [autoCompleteTypeEnum]="AutoCompleteTypeEnum.Countries"
                      [highlightBorderOnInputNonEmpty]="!!searchCaseRequestObj.nationalId">
                    </app-drop-down-countries-auto-complete>
                    <div class="input-field-error" *ngIf="isInputLengthInvalid('nationalId')">{{'search-case-minimum-length' | translate: {'min': getValidationLength('nationalId')} }}</div>
                  </div>
                  <div class="col-6">
                    <app-input-calendar [withImage]="true" [name]="'search-case-date-of-birth' | translate"
                      [(date)]="dateOfBirth"
                      [highlightBorderOnInputNonEmpty]="true"
                      (dateChange)="dateOfBirthChanged($event)">
                    </app-input-calendar>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 ">
                    <app-input [name]="'search-case-first-name' | translate" [(value)]="searchCase.firstName" [highlightBorderOnInputNonEmpty]="!!searchCaseRequestObj.firstName"
                      (saveModel)="modelChanged()"></app-input>
                      <div class="input-field-error" *ngIf="isInputLengthInvalid('firstName')">{{'search-case-minimum-length' | translate: {'min': getValidationLength('firstName')} }}</div>
                  </div>
                  <div class="col-6">
                    <app-input [name]="'search-case-surname' | translate" [(value)]="searchCase.surname" [highlightBorderOnInputNonEmpty]="!!searchCaseRequestObj.surname"
                      (saveModel)="modelChanged()"></app-input>
                    <div class="input-field-error" *ngIf="isInputLengthInvalid('surname')">{{'search-case-minimum-length' | translate: {'min': getValidationLength('surname')} }}</div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-6 ">
                    <app-input [name]="'search-case-email' | translate" [(value)]="searchCase.email"
                      (saveModel)="modelChanged()" (valueChange)="onEmailValueChange($event)"
                      [validationType]="isExternalTaskSearch ? ValidationTypeEnum.default : ValidationTypeEnum.emailAndValidOrEmpty"
                      [highlightBorderOnInputNonEmpty]="!!searchCaseRequestObj.email">
                    </app-input>
                    <div class="input-field-error" *ngIf="isInputInvalid('email') && searchCase.email !== ''">{{'search-case-invalid-email' | translate }}</div>
                  </div>
                  <div class="col-6">
                    <app-phone-drop-down [name]="'search-case-phone-number' | translate" [withPrefixInput]="true"
                      [withInput]="true" [showCode]="true" (saveModel)="phoneNumberChanged($event);modelChanged()"
                      [selectedItemId]="phoneNumberCountry?.code" [(value)]="searchCase.phoneNumber"
                      [prefixValue]="phoneNumberCountry?.phonePrefix"
                      [removableCountry]="true"
                      [highlightBorderOnInputNonEmpty]="!!searchCaseRequestObj.phoneNumber">
                    </app-phone-drop-down>
                    <div class="input-field-error" *ngIf="isInputLengthInvalid('phoneNumber')">{{'search-case-minimum-length' | translate: {'min': getValidationLength('phoneNumber')} }}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 ">
                    <div class="mt-2">
                      <app-drop-down-countries-auto-complete [withInput]="false" [showCode]="false"
                        [name]="'search-case-home-country' | translate" [countryName]="homeCountry?.countryName"
                        [(selectedItemId)]="homeCountry"
                        [autoCompleteTypeEnum]="AutoCompleteTypeEnum.Countries"
                        [highlightBorderOnInputNonEmpty]="true"
                        (selectedItemIdChange)="homeCountryChanged($event);modelChanged()">
                      </app-drop-down-countries-auto-complete>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mt-2">
                      <app-input [name]="'search-case-company-name' | translate" [(value)]="searchCase.companyName" [highlightBorderOnInputNonEmpty]="!!searchCaseRequestObj.companyName"
                        (saveModel)="modelChanged()">
                      </app-input>
                      <div class="input-field-error" *ngIf="isInputLengthInvalid('companyName')">{{'search-case-minimum-length' | translate: {'min': getValidationLength('companyName')} }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="custom-control custom-radio float-start d-flex">
                      <div class="radio-padding">
                        <input type="radio" class="custom-control-input me-2" id="enduserOrPolicyChecked"
                          name="enduserOrPolicy" (change)='searchEnduserOrPolicyholder(); modelChanged()'
                          [(ngModel)]="enduserOrPolicy" [value]=true>
                      </div>
                      <div>
                        <label class="custom-control-label" for="enduserOrPolicyChecked">{{'search-case-end-user' |
                          translate}}</label>
                      </div>
                    </div>
                    <div class="custom-control custom-radio float-start d-flex">
                      <div class="radio-padding">
                        <input type="radio" class="custom-control-input me-2" id="enduserOrPolicyUnchecked"
                          name="enduserOrPolicy" (change)='searchEnduserOrPolicyholder(); modelChanged()'
                          [(ngModel)]="enduserOrPolicy" [value]=false>
                      </div>
                      <div>
                        <label class="custom-control-label" for="enduserOrPolicyUnchecked">{{'search-case-policy-holder' |
                          translate}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 column">
            <div class="clearfix">
              <div class="fw-bold float-start">{{'search-case-incident' | translate}}</div>
              <div
                [className]="clearIncidentButtonActive() === false ? 'float-end grayed-out' : 'float-end clearFields'"
                (click)="clearIncidentSearchFields(true)">{{'search-case-clear' | translate}}
              </div>
            </div>
            <app-drop-down [name]="'search-case-incident-cause' | translate" [items]="incidentCausesLevel1"
              [(selectedItemId)]="searchCase.incidentCause"
              [highlightBorderOnInputNonEmpty]="true"
              (selectedItemIdChange)="incidentCauseChanged($event);modelChanged()">
            </app-drop-down>
            <div class="mt-2">
              <app-input [name]="'search-case-incident-location' | translate"
                         [(value)]="searchCase.incidentLocation"
                         [highlightBorderOnInputNonEmpty]="!!searchCaseRequestObj.incidentLocation"
                (saveModel)="modelChanged()">
              </app-input>
              <div class="input-field-error" *ngIf="isInputLengthInvalid('incidentLocation')">{{'search-case-minimum-length' | translate: {'min': getValidationLength('incidentLocation')} }}</div>
            </div>
            <div class="mt-2 dateInput">
              <app-input-calendar [withImage]="true" [name]="'search-case-incident-date' | translate"
                [(date)]="incidentDate"
                [highlightBorderOnInputNonEmpty]="true"
                (dateChange)="incidentDateChanged($event)">
              </app-input-calendar>
            </div>
            <div class="mt-2">
              <div style="color:#23AFDC; padding-top: 8px;">
                <span style="vertical-align: top;">
                  <input name="useSmartSearch" type="checkbox" style="height: 10px;" [(ngModel)]="searchCase.useSearchSmartSearch"/>
                </span>
                <span>&nbsp;</span>
                <span style="color:#787878; font-size: 12px;">Use smart search</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row button-row">
          <div class="float-start w-50">
            <button #searchResultButton
              [ngClass]="{ 'lighter': deactivateShowCasesButton, 'float-start result-button': true } "
              (click)="showResultsInList();">{{ this.searchCaseResultText }}
              <img src="../../../../../assets/icons/waitForSearchresult.gif"
                [className]="this.searchCaseResultShowWheel === true ? '' : 'searchWheelDisplayNone'">
            </button>
            <div
              [className]="clearAllButtonActive() === false ? 'clear-all-button grayed-out' : 'clear-all-button clearFields'"
              (click)="clearAll()">{{'search-case-clear-all' | translate}}
            </div>
            <div *ngIf="searchCaseResult.length > MAX_RESULTS" class="max-results">
              {{'search-case-max-results' | translate}}
            </div>
          </div>
          <div class="float-start text-end w-50">
            <div *permission="PermissionEnum.CASE_BASIC_CREATE">
              <button *ngIf="showUpdateExistingCaseButton" class="primary-button me-3" [class.btn-disabled]="isUpdateExistingCaseDisabled()" [disabled]="isUpdateExistingCaseDisabled()"
                (click)="updateExistingCase(selectedSearchCaseResult);$event.preventDefault()">
                <div [disablePopover]="disableUpdateButtonPopover" triggers="hover" [ngbPopover]="'search-case-update-button-hover-text' | translate" container="body">
                  {{'search-case-update-existing-case' | translate}}
                 </div>
              </button>
              <button class="primary-button" [class.btn-disabled]="isCreateCaseDisabled()" [disabled]="isCreateCaseDisabled()"
                (click)="createCase($event);$event.preventDefault()">
                <div [disablePopover]="disableCreateButtonPopover" triggers="hover" [ngbPopover]="'search-case-create-button-hover-text' | translate" container="body">
                  {{'search-case-create-new-case' | translate}}
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="show-more" (click)="toggleFullModeList()">
      <div class="row">
        <div class="col">
          <hr>
        </div>
        <div *ngIf="!searchResultsFullMode" class="col-auto separator-text">
          {{'search-case-hide-search' | translate}}
          <img class="rotate180" src="../../../../../../../assets/icons/ArrowDownBlue.svg" />
        </div>
        <div *ngIf="searchResultsFullMode" class="col-auto separator-text">
          {{'search-case-show-search' | translate}}
          <img src="../../../../../../../assets/icons/ArrowDownBlue.svg" />
        </div>
        <div class="col">
          <hr>
        </div>
      </div>
    </div>
    <div class="cases-container">
      <table class="primary-table">
        <thead>
          <tr style="display: flex;">
            <td sortColumn [sortKey]="'customer'" [data]="filteredCases">{{'search-case-cust' | translate}}
            </td>
            <td sortColumn [sortKey]="'caseNumber'" [data]="filteredCases">{{'search-case-case-number-type' |
              translate}}</td>
            <td sortColumn [sortKey]="'endUserName'" [data]="filteredCases">
              {{'search-case-end-user-name' | translate}}</td>
            <td sortColumn [sortKey]="'endUserAge'" [data]="filteredCases">{{'search-case-age' | translate}}
            </td>
            <td sortColumn [sortKey]="'policyHolderName'" [data]="filteredCases">
              {{'search-case-policy-holder-name' | translate}}</td>
            <td sortColumn [sortKey]="'incidentDate'" [data]="filteredCases">
              {{'search-case-incident-date' | translate}}</td>
            <td sortColumn [sortKey]="'incidentCountry'" [data]="filteredCases">
              {{'search-case-incident-country' | translate}}</td>
            <td sortColumn [sortKey]="'incidentDescription'" [data]="filteredCases" [class.description-long]="!showUpdateExistingCaseButton">
              {{'search-case-incident-description' | translate}}</td>
            <td sortColumn [sortKey]="'incidentCause'" [data]="filteredCases">
              {{'search-case-incident-cause' | translate}}</td>
            <td sortColumn [sortKey]="'caseCreationDate'" [data]="filteredCases">
              {{'search-case-case-creation-date' | translate}}</td>
            <td sortColumn [sortKey]="'teamStatus'" [data]="filteredCases">
              {{'search-case-team-status' | translate}}</td>
            <td sortColumn [sortKey]="'caseType'" [data]="filteredCases">
              {{'search-case-case-type' | translate}}</td>
            <td *ngIf="showUpdateExistingCaseButton"></td>
          </tr>
        </thead>
        <tbody class="defaultMode" [ngClass]="{'fullMode': searchResultsFullMode}">
          <tr *ngFor="let searchCaseResult of filteredCases; let i = index" style="display: flex;"
            [ngClass]="{'highlight': selectedSearchCaseResult?.caseId === searchCaseResult.caseId} ">
            <td class="cu" (click)="highlightCase(searchCaseResult)">
              <img [src]="getBrandImagesUrlForProfile(searchCaseResult.customer)">
            </td>
            <td style="cursor: pointer">
              <div *ngIf="isWebTaskResult(searchCaseResult)" (click)="selectTask(searchCaseResult.taskId)" class="web-task">
                  <img src="/assets/icons/task/external-case-todo.svg">
                  {{ getChannelTypeName(searchCaseResult.channelType) }}
              </div>
              <div *ngIf="!isWebTaskResult(searchCaseResult)" (click)="selectCase(searchCaseResult.caseId)" class="case-number">
                {{searchCaseResult.caseNumber}}
              </div>
            </td>
            <td (click)="highlightCase(searchCaseResult)">
              <div>
                <app-case-new-stakeholder-icon
                  [isMatch]="searchCaseResult.endUserMatch"
                  [stakeholder]="searchCaseResult.endUser"
                  [ssnValidated]="searchCaseResult.endUserSsnValidated"
                  [coordinationCase]="searchCaseResult.caseType == caseTypeCodes.INTERNAL_COORDINATION">
                </app-case-new-stakeholder-icon>
                {{searchCaseResult.endUserName}}
              </div>
            </td>
            <td (click)="highlightCase(searchCaseResult)">{{searchCaseResult.endUserAge}}</td>
            <td (click)="highlightCase(searchCaseResult)">
              <div>
                <app-case-new-stakeholder-icon
                  [isMatch]="searchCaseResult.policyHolderMatch"
                  [stakeholder]="searchCaseResult.policyHolder"
                  [ssnValidated]="searchCaseResult.policyHolderSsnValidated"
                  [coordinationCase]="searchCaseResult.caseType == caseTypeCodes.INTERNAL_COORDINATION">
                </app-case-new-stakeholder-icon>
                {{searchCaseResult.policyHolderName}}
              </div>
            </td>
            <td (click)="highlightCase(searchCaseResult)">{{searchCaseResult.incidentDate |
              date:'dd MMM yyy'}}</td>
            <td (click)="highlightCase(searchCaseResult)"><div>{{searchCaseResult.incidentCountry}}</div></td>
            <td (click)="highlightCase(searchCaseResult)" [class.description-long]="!showUpdateExistingCaseButton">
              <div [disablePopover]="disablePopover" triggers="hover" [ngbPopover]="searchCaseResult.incidentDescription">
                {{searchCaseResult.incidentDescription}}
              </div>
            </td>
            <td (click)="highlightCase(searchCaseResult)">
              <div [disablePopover]="disablePopover" triggers="hover" [ngbPopover]="searchCaseResult.incidentCause">
                {{searchCaseResult.incidentCause}}
              </div>
            </td>
            <td (click)="highlightCase(searchCaseResult)">{{searchCaseResult.caseCreationDate |
              date:'dd MMM yyy'}}</td>
            <td (click)="highlightCase(searchCaseResult)">{{searchCaseResult.teamStatus}}
            </td>
            <td (click)="highlightCase(searchCaseResult)">
              <div class="case-type-div case-info">
              <span class="case-type-span" [ngClass]="'casetype-style-' + searchCaseResult.caseTypeCode"> {{"search-case-" + searchCaseResult.caseTypeCode | translate}}</span>
              </div>
            </td>
            <td *ngIf="showUpdateExistingCaseButton && !isWebTaskResult(searchCaseResult) && canMoveToCase(searchCaseResult)"><input type="checkbox" (click)="onCheckboxClicked(searchCaseResult, $event)"
                [checked]="selectedSearchCaseResult?.caseId === searchCaseResult.caseId"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="showSpinner" class="search-overlay-background"></div>
  </div>
</div>
<div *ngIf="showSpinner" class="overlay d-flex justify-content-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
