import { PhoneNumberType, StakeholderTypeEnum, ValidationTypeEnum } from '@secca/shared/models/enums';
import { AutoCompleteTypeEnum } from 'src/app/shared/components/drop-down-countries-auto-complete/auto-complete-type-enum';
import { CountryDetails } from '../../../../shared/models/country';
import { User } from '../../../../shared/models/user';
import { CaseIncident } from '../../../../shared/models/caseIncident';
import { PersonInsurance } from 'src/app/shared/models/person-insurance';
import { PlannedTravel } from '../../../../shared/models/planned-travel';
import { Case } from '../../../../shared/models/case';
import { CaseService } from 'src/app/core/services/case.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SearchCase } from '../../../../shared/models/searchCase';
import { DropdownDictionary } from '../../../../shared/models/dropdownDictionary';
import { BoardService } from '../../../../core/services/board.service';
import { SearchCaseResult } from '../../../../shared/models/searchCaseResult';
import { CreateCase } from '../../../../shared/models/createCase';
import { PermissionEnum } from 'src/app/shared/models/enums';
import { PhoneService } from '@secca/core/services/phone.service';
import { PhoneNumber } from '@secca/shared/models/phoneNumber';
import { IncidentService } from '@secca/core/services/incident.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { CustomerProfile } from '@secca/shared/models/customerProfile';
import { StakeholderTooltipService } from './stakeholder-tooltip/stakeholder-tooltip.service';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { debounceTime, finalize, tap } from 'rxjs/operators';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { TeamCountryCode } from '@secca/shared/models/mappings';
import { OpenCaseLimitService } from '@secca/core/services/open-case-limit.service';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';
import { HandlingAreaService } from '@secca/core/services/handling-area.service';
import { TaskViewModel } from '@secca/board/components/board-task/models/task-view.model';
import { TaskService } from '@secca/core/services/task.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogStateService } from '@secca/core/state-services/dialog-state.service';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { InputValidationService } from '@secca/core/services/input-validation.service';
import { DialogViewerService } from '@secca/core/services/dialog-viewer.service';
import { Subject, Subscription } from 'rxjs';
import { CaseType, CaseTypeCodes } from '@secca/shared/models/case-type';
import * as _ from 'lodash-es';
import { CaseTypesService } from '@secca/core/services/case-type.service';
import { MasterListService } from '@secca/core/services/masterlist-service';
import { Dictionary } from '@secca/shared/models/dictionary';

export enum ValidationsEnum {
  MIN_LENGTH,
  VALID_EMAIL,
  REG_EXP,
  NOT_EMPTY,
  OTHER_FIELD_VALID
}

@Component({
  selector: 'app-case-new',
  templateUrl: './case-new.component.html',
  styleUrls: ['./case-new.component.scss'],
})
@AutoUnsubscribe
export class CaseNewComponent implements OnInit {
  @Input()
  searchCase: SearchCase = null;

  @Input()
  taskViewModel: TaskViewModel;

  @Input()
  showUpdateExistingCaseButton: boolean = false

  @Input()
  minimizeOnSelectCase: boolean = true;

  @Input()
  isExternalTaskSearch: boolean;

  @Input()
  isUnrelatedTaskSearch: boolean;

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  @Output()
  navigatedToCaseEvent: EventEmitter<void> = new EventEmitter();

  MAX_RESULTS = 249;
  result: any;
  id: string;
  deleteId: string;
  plannedTravel: PlannedTravel;
  personInsurance: PersonInsurance;
  caseIncident: CaseIncident;
  case: Case;
  endUser: User;
  policyHolder: User;
  reporter: User;
  customerProfiles: CustomerProfile[];
  memberProfiles: CustomerProfile[];
  incidentCausesLevel1: DropdownDictionary[];
  selectableChannelTypes: DropdownDictionary[];
  allChannelTypes: DropdownDictionary[];
  searchCaseResult: SearchCaseResult[];
  filteredCases: SearchCaseResult[];
  searchCaseResultLength: number;
  searchCaseResultText: string;
  searchCaseResultShowWheel: boolean;
  enduserOrPolicy: boolean;
  deactivateShowCasesButton: boolean;
  caseId: string;
  nationalIdCountry: CountryDetails;
  homeCountry: CountryDetails;
  phoneNumberCountry: CountryDetails;
  caseCreationDateFrom: Moment;
  caseCreationDateTo: Moment;
  dateOfBirth: Moment;
  incidentDate: Moment;
  searchResultsFullMode = false;
  emptySearchCase: SearchCase;
  showSpinner = false;
  isEmailInvalid = false;
  performInitialSearch = false;
  isAnySearchPerformed = false;
  isCaseCreationDatePreSetValueChanged = false;
  caseType: CaseType;
  changedState = new Subject<void>();
  channelDisabled: boolean;
  defaultChannel: string;
  $changedStateSubscr: Subscription;
  $stakeholderToolTipServiceSubscription: Subscription;
  $loggedInUserSubscr: Subscription;

  private userTeamCountryDetails: CountryDetails;

  selectedSearchCaseResult: SearchCaseResult = null;

  searchCaseRequestObj: SearchCase;

  private inputValidations = {
    'caseNumber': [{validationType: ValidationsEnum.REG_EXP, regExp: /^(?:-?\d){4,9}-?$|^[a-zA-Z]{4}(?:-?\d){0,9}-?$/}],
    'casePhoneNumber':  [{validationType: ValidationsEnum.MIN_LENGTH, minLength: 2}],
    'nationalId': [{validationType: ValidationsEnum.MIN_LENGTH, minLength: 6}],
    'nationalIdCountryCode':[{validationType: ValidationsEnum.NOT_EMPTY},
                             {validationType: ValidationsEnum.OTHER_FIELD_VALID, field: 'nationalId'}],
    'firstName': [{validationType: ValidationsEnum.MIN_LENGTH, minLength: 2}],
    'surname': [{validationType: ValidationsEnum.MIN_LENGTH, minLength: 2}],
    'email': [{validationType: ValidationsEnum.MIN_LENGTH, minLength: 2},
              {validationType: ValidationsEnum.VALID_EMAIL}
    ],
    'phoneNumberCountryCode': [{validationType: ValidationsEnum.NOT_EMPTY},
                               {validationType: ValidationsEnum.NOT_EMPTY, field: 'phoneNumberPrefix'},
                               {validationType: ValidationsEnum.OTHER_FIELD_VALID, field: 'phoneNumber'}],
    'phoneNumberPrefix': [{validationType: ValidationsEnum.NOT_EMPTY},
                          {validationType: ValidationsEnum.NOT_EMPTY, field: 'phoneNumberCountryCode'},
                          {validationType: ValidationsEnum.OTHER_FIELD_VALID, field: 'phoneNumber'}],
    'phoneNumber': [{validationType: ValidationsEnum.MIN_LENGTH, minLength: 2}],
    'companyName': [{validationType: ValidationsEnum.MIN_LENGTH, minLength: 2}],
    'policyRefNo': [{validationType: ValidationsEnum.MIN_LENGTH, minLength: 2}],
    'incidentLocation': [{validationType: ValidationsEnum.MIN_LENGTH, minLength: 2}]
  };

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  constructor(
    private modalService: NgbModal,
    private dialogHelperUtilService: DialogHelperUtilService,
    private dialogStateService: DialogStateService,
    private dialogViewerService: DialogViewerService,
    private router: Router,
    private caseService: CaseService,
    private boardService: BoardService,
    private phoneService: PhoneService,
    private incidentService: IncidentService,
    private stakeholderTooltipService: StakeholderTooltipService,
    private dictionaryService: DictionaryService,
    private openCaseLimitService: OpenCaseLimitService,
    private handlingAreaService: HandlingAreaService,
    private translate: TranslateService,
    private taskService: TaskService,
    private inputValidationService: InputValidationService,
    private caseTypesService: CaseTypesService,
    private masterListService: MasterListService) {
    this.case = new Case();

    this.$loggedInUserSubscr = this.dictionaryService.getLoggedInUser().subscribe(result => {
      if (!!result) {
        this.defaultChannel = result.userTeam?.defaultChannel;
        const teamCountryCode = TeamCountryCode.getCountryCodeByAdtCode(result.userTeam.adtCode);
        if (!!teamCountryCode) {
          this.dictionaryService.getCountryByAlpha2(teamCountryCode).subscribe(item => {
            this.userTeamCountryDetails = item;
            if ( this.searchCase && !this.searchCase.nationalIdCountryCode ) {
              this.nationalIdCountry = new CountryDetails(this.userTeamCountryDetails);
              this.searchCase.nationalIdCountryCode = this.nationalIdCountry.code;
              //this.searchCaseRequestObj.nationalIdCountryCode = this.nationalIdCountry.code;
            }
          });
        }
      }
    });

    this.$stakeholderToolTipServiceSubscription = this.stakeholderTooltipService.getCase().subscribe(
      stakeholderId => this.createCaseCopy(stakeholderId)
    );

    this.$changedStateSubscr = this.changedState.pipe(
      debounceTime(200)
    ).subscribe(
      () => this.searchCases()
    );
  }

  ngOnInit() {
    this.searchCaseResultText = 'Enter search data';
    this.searchCaseResultLength = 0;
    this.deactivateShowCasesButton = true;
    this.enduserOrPolicy = true;
    this.nationalIdCountry = new CountryDetails();
    this.homeCountry = new CountryDetails();
    this.phoneNumberCountry = new CountryDetails();
    this.emptySearchCase = this.createEmptySearchCase();
    this.searchCaseRequestObj = this.createEmptySearchCase();
    this.searchResultsFullMode = false;
    this.searchCaseResultShowWheel = false;
    this.searchCaseResult = [];
    this.memberProfiles = null;

    if (!!this.searchCase) {
      this.searchCase = {...this.emptySearchCase, ...this.searchCase}
      this.nationalIdCountry.code = this.searchCase.nationalIdCountryCode;
      this.phoneNumberCountry.code = this.searchCase.phoneNumberCountryCode;
      this.phoneNumberCountry.phonePrefix = this.searchCase.phoneNumberPrefix;
      this.dateOfBirth = this.searchCase.dateOfBirth;
      this.caseCreationDateFrom = this.searchCase.caseCreationDateFrom;
      this.channelDisabled = !!this.searchCase.channel
      if ( !this.searchCase.channel ) {
        this.searchCase.channel = this.defaultChannel;
      }
      if ( !this.isEmptySearchCase() ) {
        this.performInitialSearch = true;
      }
    }
    else {
      this.searchCase = this.createDefaultSearchCase();
      this.searchCaseRequestObj = this.createSearchRequestObject();
      this.caseCreationDateFrom = this.searchCase.caseCreationDateFrom;
      this.selectedSearchCaseResult = null;
    }

    this.caseService.getCustomerProfiles().subscribe({
      next: result => {
        this.customerProfiles = result.sort(
             (a, b) => ( (a.profileName.replace('(','Ü')  > b.profileName.replace('(','Ü') ) ? 1 : -1) );
      },
      error: error => console.log(error)
    });

    this.incidentService.getIncidentCauses().subscribe({
      next: result => this.incidentCausesLevel1 = result,
      error: error => console.log(error)
    });

    this.masterListService.getAllChannelTypes().subscribe(result => this.allChannelTypes = result.map(item => new Dictionary(item.channelTypeEnumValue, item.channelTypeName)));
    this.dictionaryService.getActiveChannelTypes().subscribe(result => this.selectableChannelTypes = result);

    if ( !!this.taskViewModel?.caseId ) {
      this.caseTypesService.getCaseTypeByCaseId(''+this.taskViewModel?.caseId).subscribe(result => this.caseType = result)
    }

    this.initializePhoneCall();
  }



  public initializePhoneCall(): void {
    this.phoneService.getLatestPhoneCall(true).subscribe({
      next: result => { const isPhoneNumberChanged = result.phoneNumber && this.searchCase?.casePhoneNumber !== result.phoneNumber;
                  this.searchCase.casePhoneNumber = result.phoneNumber ? result.phoneNumber : '';
                  if (isPhoneNumberChanged || this.performInitialSearch) {
                    this.performInitialSearch = false;
                    this.searchCases();
                  }
      },
      error: () => {
        console.log('Didn\'t find active call not yet linked to any case');
        this.searchCase.casePhoneNumber = '';
      }})
  }

  closeModal(): void {
    this.closed.emit();
    this.dialogHelperUtilService.closeModal();
  }

  nationalIdCountryChanged(country: CountryDetails) {
    this.searchCase.nationalIdCountryCode = country ? country.code : null;
  }

  phoneNumberCountryChanged(country: CountryDetails): void {
    this.searchCase.phoneNumberCountryCode = country ? country.code : null;
  }

  get caseTypeCodes() {
    return CaseTypeCodes;
  }

  homeCountryChanged(country: CountryDetails) {
    this.searchCase.homeCountryCode = country ? country.code : null;
  }

  customerProfileChanged(customerProfileId: number) {
    this.searchCase.customer = customerProfileId;
    this.caseService.getCustomerProfile(customerProfileId.toString()).subscribe(
      result => {
        if (result.isGroupProfile) {
          this.caseService.getMemberProfiles(customerProfileId.toString()).subscribe(
            members => {
              this.memberProfiles = members;
              this.memberProfiles.splice(0, 0, new CustomerProfile({profileName: this.translate.instant('search-case-customer-profile-group-profile'), noInsuranceWithThisCustomerGroup: false, profileId: ''}));
            }
          );
        } else {
          this.memberProfiles = null;
          this.searchCase.memberProfile = null;
        }
        this.modelChanged();
      }
    );
  }

  memberProfileChanged(memberProfileId: number) {
    this.searchCase.memberProfile = memberProfileId;
    this.modelChanged();
  }

  incidentCauseChanged(incidentCause: string) {
   this.searchCase.incidentCause = incidentCause;
  }

  /** Prevents spaces to be added in caseNumber field */
  caseNumberKeyDown(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault();
      return;
    }
  }

  /**
   * Trims spaces in caseNumber field on paste by manipulating clipboard text, and manually inserting into caseNumber
   * OBS: This feature does not paste data, however overrides all existing data with the pasted value
   */
  caseNumberPaste(event: ClipboardEvent) {
    event.preventDefault();
    if (!event.clipboardData.types.find((value) => value === 'text/plain')) {
      return;
    }
    this.searchCase.caseNumber = event.clipboardData.getData('text/plain').trim();
  }

  caseNumberChanged(caseNumber: string): void {
    // SEC-14457 - when entering at least 4 digits in the Case ID field the pre-set Case created date should be removed and ignored in the search
    //           - when deleting all the entered data in the Case ID field then the Case created date should be set again and be included in the search
    if ( !this.isCaseCreationDatePreSetValueChanged ) {
      if ( caseNumber.length < 4 && this.searchCase.caseCreationDateFrom === null && !!this.caseCreationDateFrom) {
        this.caseCreationDateFrom = moment().subtract(6, 'months');
        this.searchCase.caseCreationDateFrom = this.caseCreationDateFrom;
      }
      else if ( caseNumber.length >= 4 && !caseNumber.match('^[a-zA-Z]{1,4}-?$')) {
        this.searchCase.caseCreationDateFrom = null;
      }
    }
  }

  caseCreationDateFromChanged(date: Moment): void {
    this.searchCase.caseCreationDateFrom = date;
    this.isCaseCreationDatePreSetValueChanged = true;
    this.modelChanged();
  }

  caseCreationDateToChanged(date: Moment): void {
    this.searchCase.caseCreationDateTo = date;
    this.modelChanged();
  }

  dateOfBirthChanged(date: Moment): void {
    this.searchCase.dateOfBirth = date;
    this.modelChanged();
  }

  incidentDateChanged(date: Moment): void {
    this.searchCase.incidentDate = date;
    this.modelChanged();
  }

  modelChanged() {
    const ignoredFields = ['stakeholderSearch', 'nationalIdCountryCode', 'phoneNumberCountryCode', 'casePhoneNumber', 'channel'];
    if (this.isExternalTaskSearch){ ignoredFields.push('customer', 'memberProfile') };

    const isEmpty = _.isEqual(_.omit(this.emptySearchCase, ignoredFields),
                            _.omit(this.searchCase, ignoredFields));
    if ( !isEmpty && !!this.searchCase.casePhoneNumber ) {
      this.searchCase.casePhoneNumberIncludedInSearch = false;
    }

    this.filteredCases = [];
    this.changedState.next();
  }

  showResultsInFullModeList(searchResultsFullMode: boolean): void {
    this.searchResultsFullMode = searchResultsFullMode;
  }

  toggleFullModeList(): void {
    this.searchResultsFullMode = !this.searchResultsFullMode;
  }

  isCreateCaseDisabled() {
    return this.openCaseLimitService.isOpenedCasesLimitReached() || this.isEmailInvalid || this.isEmptySearchCase() || !this.isAnySearchPerformed;
  }

  isUpdateExistingCaseDisabled() {
    return !this.selectedSearchCaseResult;
  }

  createCase(evnt: Event) {
    if ( this.isExternalTaskSearch ) {
      this.showSpinner = true;
      this.caseService.userCreateExternalCase(this.taskViewModel.caseId).pipe(
        tap(() => this.navigateToCase(this.taskViewModel.caseId.toString(), false)),
        finalize(() => this.showSpinner = false)
      )
      .subscribe();
      return;
    }

    if (this.isUnrelatedTaskSearch) {
      this.showSpinner = true;
      this.caseService.userCreateUnrelatedCase(this.taskViewModel.caseId).pipe(
        tap(() => this.navigateToCase(this.taskViewModel.caseId.toString(), false)),
        finalize(() => this.showSpinner = false)
      )
        .subscribe();
      return;
    }

    const phonenumbers: PhoneNumber[] = [];
    let seccaCase: SeccaCase;
    let fixedNumber: PhoneNumber;
    if (this.searchCase.phoneNumber !== '') {
      fixedNumber = new PhoneNumber();
      fixedNumber.number = this.searchCase.phoneNumber;
      fixedNumber.type = PhoneNumberType.Fixed;
      fixedNumber.countryCode = this.searchCase.phoneNumberCountryCode;
      phonenumbers.push(fixedNumber);
    }

    const createCase: CreateCase = {
      customer: this.searchCase.customer,
      policyRefNo: this.searchCase.policyRefNo,
      customerRefNo: this.searchCase.customerRefNo,
      customerProduct: this.searchCase.customerProduct,
      incidentCause: this.searchCase.incidentCause,
      incidentLocation: this.searchCase.incidentLocation,
      incidentDate: this.searchCase.incidentDate,
      channelType: this.searchCase.channel,
      caseStakeholders: [
        {
          homeCountry: this.searchCase.homeCountryCode,
          nationalIdCountry: this.searchCase.nationalIdCountryCode,
          nationalId: this.searchCase.nationalId,
          phoneNumbers: phonenumbers,
          firstName: this.searchCase.firstName,
          surname: this.searchCase.surname,
          email: this.searchCase.email,
          dateOfBirth: this.searchCase.dateOfBirth,
          companyName: this.searchCase.companyName,
          stakeholderType: this.searchCase.stakeholderSearch,
        },
      ],
      casePhonenumberCountryCode: this.searchCase.homeCountryCode,
    } as CreateCase;

    this.showSpinner = true;
    this.caseService.createCase(createCase).pipe(
      finalize(() => this.showSpinner = false)
    )
    .subscribe(
      result => {
        seccaCase = result;
        this.id = seccaCase.caseNumber;
        this.caseId = seccaCase.id;
        this.navigateToNewlyCreatedCase(this.caseId);
      },
      error => console.log(error)
    );
  }

  get title(): string {
    if ( this.isUnrelatedTaskSearch ) {
      return 'task-modal-handle-unrelated-task';
    }

    if ( !!this.taskViewModel?.channelType ) {
      const channelTypeName = this.getChannelTypeName(this.taskViewModel?.channelType);
      return this.translate.instant('task-web-modal-handle-task', {taskName: channelTypeName});
    }
    else {
      return '';
    }
  }

  createCaseCopy(stakeholderId: string): void {
    this.showSpinner = true;
    this.caseService.addCaseWithCopyOfStakeholder(stakeholderId).pipe(
      tap(result => this.navigateToNewlyCreatedCase(result.id)),
      finalize(() => this.showSpinner = false)
    )
    .subscribe();
  }

  clearAll() {
    this.clearCaseSearchFields(false, true);
    this.clearCustomerSearchFields();
    this.clearStakeholderSearchFields();
    this.clearIncidentSearchFields();
    this.searchCases();
    this.deactivateShowCasesButton = true;
    this.selectedSearchCaseResult = null;
  }

  clearCaseSearchFields(updateSearchCases = false, clearCasePhone = true) {
    this.selectedSearchCaseResult = null;
    if ( clearCasePhone ) {
      this.searchCase.casePhoneNumber = '';
    }
    this.searchCase.caseNumber = '';
    this.caseCreationDateFrom = null;
    this.caseCreationDateTo = null;
    this.searchCase.caseCreationDateFrom = null;
    this.searchCase.caseCreationDateTo = null;
    this.searchCase.casePhoneNumberIncludedInSearch = true;
    this.isCaseCreationDatePreSetValueChanged = true;
    if (!this.channelDisabled) {
      this.searchCase.channel = null;
    }
    if (updateSearchCases) {
      this.searchCases();
    }
  }

  clearCustomerSearchFields(updateSearchCases = false) {
    this.selectedSearchCaseResult = null;
    this.searchCase.customer = null;
    this.searchCase.memberProfile = null;
    this.searchCase.policyRefNo = '';
    this.searchCase.customerRefNo = '';
    this.searchCase.customerProduct = null;
    this.memberProfiles = null;
    if (updateSearchCases) {
      this.searchCases();
    }
  }

  clearStakeholderSearchFields(updateSearchCases = false) {
    this.selectedSearchCaseResult = null;
    this.nationalIdCountry = !!this.userTeamCountryDetails ?
      new CountryDetails(this.userTeamCountryDetails) : new CountryDetails();
    this.homeCountry = new CountryDetails();
    this.phoneNumberCountry = new CountryDetails();
    this.searchCase.nationalIdCountryCode = !!this.nationalIdCountry.code ?
      this.nationalIdCountry.code : null;
    this.searchCase.nationalId = '';
    this.searchCase.firstName = '';
    this.searchCase.email = '';
    this.searchCase.homeCountryCode = null;
    this.dateOfBirth = null;
    this.searchCase.dateOfBirth = null;
    this.searchCase.surname = '';
    this.searchCase.phoneNumber = '';
    this.searchCase.phoneNumberCountryCode = null;
    this.searchCase.companyName = '';
    this.searchCase.stakeholderSearch = StakeholderTypeEnum.endUser;
    this.enduserOrPolicy = true;
    if (updateSearchCases) {
      this.searchCases();
    }
  }

  clearIncidentSearchFields(updateSearchCases = false) {
    this.selectedSearchCaseResult = null;
    this.searchCase.incidentCause = '';
    this.searchCase.incidentLocation = '';
    this.incidentDate = null;
    this.searchCase.incidentDate = null;
    if (updateSearchCases) {
      this.searchCases();
    }
  }

  clearAllButtonActive() {
    return (
      this.clearCustomerButtonActive() ||
      this.clearStakeholderButtonActive() ||
      this.clearCaseButtonActive() ||
      this.clearIncidentButtonActive()
    );
  }

  clearCaseButtonActive() {
    if (this.searchCase.caseNumber === '' &&
        this.searchCase.caseCreationDateFrom === null &&
        this.searchCase.caseCreationDateTo === null &&
        this.searchCase.casePhoneNumber === '' &&
        this.searchCase.casePhoneNumberIncludedInSearch &&
        (this.searchCase.channel === null || this.channelDisabled)) {
      return false;
    } else {
      return true;
    }
  }

  clearCustomerButtonActive() {
    if (
      this.searchCase.customer === null &&
      this.searchCase.policyRefNo === '' &&
      this.searchCase.customerRefNo === '' &&
      this.searchCase.customerProduct === null
    ) {
      return false;
    } else {
      return true;
    }
  }

  clearStakeholderButtonActive() {
    if (
      (this.searchCase.nationalIdCountryCode === null  ||
        (!!this.userTeamCountryDetails && this.searchCase.nationalIdCountryCode === this.userTeamCountryDetails.code)) &&
      this.searchCase.nationalId === '' &&
      this.searchCase.firstName === '' &&
      this.searchCase.email === '' &&
      this.searchCase.homeCountryCode === null &&
      this.searchCase.dateOfBirth == null &&
      this.searchCase.surname === '' &&
      this.searchCase.phoneNumberCountryCode === null &&
      this.searchCase.phoneNumber === '' &&
      this.searchCase.companyName === '' &&
      this.searchCase.stakeholderSearch === StakeholderTypeEnum.endUser
    ) {
      return false;
    } else {
      return true;
    }
  }

  clearIncidentButtonActive() {
    if (this.searchCase.incidentCause === '' && this.searchCase.incidentLocation === '' && this.searchCase.incidentDate === null) {
      return false;
    } else {
      return true;
    }
  }

  searchEnduserOrPolicyholder() {
    if (this.enduserOrPolicy) {
      this.searchCase.stakeholderSearch = StakeholderTypeEnum.endUser;
    } else {
      this.searchCase.stakeholderSearch = StakeholderTypeEnum.policyHolder;
    }
  }

  getBrandImagesUrlForProfile(profileId: string) {
    return this.caseService.getBrandImagesUrlForProfile(profileId);
  }

  searchCases() {
    this.searchCaseResult = [];
    this.filteredCases = [];
    if (this.isEmptySearchCase()) {
      this.resetSearchResults();
    } else {
      const ignoredFields = [];
      const newSearchCaseRequestObj: SearchCase = this.createSearchRequestObject();
      if ( this.isEmptySearchRequest(newSearchCaseRequestObj) ) {
        this.resetSearchResults();
      }
      else if ( !_.isEqual(_.omit(this.searchCaseRequestObj, ignoredFields), _.omit(newSearchCaseRequestObj, ignoredFields)) ) {
        this.searchCaseResultText = '';
        this.searchCaseResultShowWheel = true;
        this.formatCaseNumber();
        this.searchCaseRequestObj = newSearchCaseRequestObj;
        this.boardService.getSearchCase(this.searchCaseRequestObj).subscribe(
          result => {
            this.searchCaseResult = this.isExternalTaskSearch ? result.filter(s => s.taskId !== +this.taskViewModel?.id) : result;
            this.searchCaseResultShowWheel = false;
            this.searchCaseResultText = 'Show ' + this.searchCaseResult.length + ' results';
            this.searchCaseResultLength = this.searchCaseResult.length;
            this.deactivateShowCasesButton = (this.searchCaseResult.length <= 10);
            this.isAnySearchPerformed = (this.searchCaseResult.length <= 10);
            if (this.searchCaseResult.length <= 10) {
              this.filterList();
            }
          },
          error => {
            this.searchCaseResultShowWheel = false;
            this.searchCaseResultText = 'Show 0 results';
            throw error;
          }
        );
      }
    }
  }

  private resetSearchResults(): void {
    this.searchCaseResultText = 'Enter search data';
    this.searchCaseRequestObj = this.createSearchRequestObject();
    this.filterList();
    this.deactivateShowCasesButton = true;
  }

  private createEmptySearchRequestObject(): SearchCase {
    const searchObj = new SearchCase();
    Object.keys(this.emptySearchCase).forEach(key => {
      searchObj[key] = this.checkInputInvalid(key) ? '' : this.emptySearchCase[key];
    });

    return searchObj;
  }

  private createSearchRequestObject(): SearchCase {
    const searchObj = new SearchCase();
    Object.keys(this.searchCase).forEach(key => {
        if(this.isExternalTaskSearch) {
          if(key !== 'customer' && key !== 'memberProfile') {
            searchObj[key] = this.checkInputInvalid(key) ? null : this.searchCase[key];
          } if (key == 'customer' || key == 'memberProfile')  {
            searchObj[key] = null;
          }
        } else {
          searchObj[key] = this.checkInputInvalid(key) ? '' : this.searchCase[key];
        }
    });
    return searchObj;
  }

  getValidationLength(fieldName: string) {
    const validations = !!this.inputValidations && this.inputValidations[fieldName];
    const lengthValidation = validations?.find(validation => validation.validationType === ValidationsEnum.MIN_LENGTH);
    return !!lengthValidation ? lengthValidation.minLength : 0;
  }


  checkInputInvalid(fieldName: string, validationType?: ValidationsEnum): boolean {
    let isInvalid = false;
    const validations = !!this.inputValidations && this.inputValidations[fieldName];
    if (!!validations) {
      validations.forEach(validation => {
        const field = !!validation.field ? validation.field : fieldName;
        if ( validationType === undefined || validationType === validation.validationType ) {
          switch(validation.validationType) {
            case ValidationsEnum.MIN_LENGTH:
              isInvalid = isInvalid || !this.searchCase[field] || this.searchCase[field].length < validation.minLength;
              break;

            case ValidationsEnum.REG_EXP:
              isInvalid = isInvalid || !validation.regExp.test(this.searchCase[field]);
              break;

            case ValidationsEnum.VALID_EMAIL:
              isInvalid = isInvalid || this.inputValidationService.isValidated(this.searchCase[field], ValidationTypeEnum.emailAndValidOrEmpty);
              break;

            case ValidationsEnum.NOT_EMPTY:
              isInvalid = isInvalid || !this.searchCase[field];
              break;

            case ValidationsEnum.OTHER_FIELD_VALID:
              isInvalid = isInvalid || this.checkInputInvalid(validation.field);
              break;
          }
        }
      });
    }

    return isInvalid;
  }

  isInputInvalid(fieldName: string): boolean {
    return !!this.searchCase[fieldName] && this.checkInputInvalid(fieldName);
  }

  isInputLengthInvalid(fieldName: string): boolean {
    return !!this.searchCase[fieldName] && this.checkInputInvalid(fieldName, ValidationsEnum.MIN_LENGTH);
  }

  isEmptySearchCase(): boolean {
    const ignoredFields =  ['stakeholderSearch', 'nationalIdCountryCode', 'phoneNumberCountryCode', 'useSearchSmartSearch', 'channel'];
    if (this.isExternalTaskSearch){ ignoredFields.push('customer', 'memberProfile') };

    let isEmpty = _.isEqual(_.omit(this.emptySearchCase, ignoredFields),
                            _.omit(this.searchCase, ignoredFields));

    if ( !isEmpty && !this.searchCase.casePhoneNumberIncludedInSearch) {
      isEmpty = _.isEqual(_.omit(this.emptySearchCase, ignoredFields, ['casePhoneNumber', 'casePhoneNumberIncludedInSearch']),
                          _.omit(this.searchCase, ignoredFields, ['casePhoneNumber', 'casePhoneNumberIncludedInSearch']));
    }

    if ( isEmpty ) {
      this.isAnySearchPerformed = false;
    }

    return isEmpty;
  }

  isEmptySearchRequest(searchRequestObj: SearchCase): boolean {
    const ignoredFields = ['stakeholderSearch', 'nationalIdCountryCode', 'phoneNumberCountryCode', 'useSearchSmartSearch', 'caseCreationDateFrom', 'caseCreationDateTo', 'channel'];
    if (this.isExternalTaskSearch){ ignoredFields.push('customer', 'memberProfile') };

    const emptySearchRequestObj = this.createEmptySearchRequestObject();

    let isEmpty = _.isEqual(_.omit(emptySearchRequestObj, ignoredFields),
                            _.omit(searchRequestObj, ignoredFields));

    if ( !isEmpty && !searchRequestObj.casePhoneNumberIncludedInSearch) {
      isEmpty = _.isEqual(_.omit(emptySearchRequestObj, ignoredFields, ['casePhoneNumber', 'casePhoneNumberIncludedInSearch']),
                          _.omit(searchRequestObj, ignoredFields, ['casePhoneNumber', 'casePhoneNumberIncludedInSearch']));
    }

    return isEmpty;
  }

  get showMandatoryColor(): boolean {
    return !this.searchCase.caseNumber && !this.searchCase.customer;
  }

  get disablePopover(): boolean {
    return false;
  }

  onSubmit(): void {
    this.searchCases();
  }

  showResultsInList() {
    this.filterList();
    this.isAnySearchPerformed = true;
    this.deactivateShowCasesButton = true;
  }

  compareValues(key, order = 'asc') {
    return (a, b) => {
      if ( this.isWebTaskResult(a) ) {
        return -1;
      }
      else if ( this.isWebTaskResult(b) ) {
        return 1;
      }

      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }
      const varA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];

      let comparison = 0;
      if (varA < varB) {
        comparison = 1;
      } else if (varA > varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  navigateToNewlyCreatedCase(caseId: string) {
    this.navigateToCase(caseId, true);
  }

  navigateToCase(caseId: string, newlyCreated: boolean) {
    this.router.navigate(['/case/', caseId], { state: { newCase: newlyCreated } });
    this.closed.emit();
    this.navigatedToCaseEvent.emit();
    this.dialogHelperUtilService.closeModal();
    this.modalService.dismissAll();
  }



  onCheckboxClicked(caseResult: SearchCaseResult, event: any) {
    if (this.showUpdateExistingCaseButton && !this.isWebTaskResult(caseResult)) {
      this.selectedSearchCaseResult = event.target.checked ? caseResult : null;
    }
  }

  highlightCase(caseResult: SearchCaseResult) {
    if (this.showUpdateExistingCaseButton && !this.isWebTaskResult(caseResult)) {
      this.selectedSearchCaseResult = caseResult;
    }
  }

  canMoveToCase(caseResult: SearchCaseResult) {
    return !this.isWebTaskResult(caseResult) && (!!this.caseType?.allowableCaseTypes?.find(allowed => allowed.code === caseResult.caseTypeCode) || this.caseType?.code === caseResult.caseTypeCode);
  }

  selectCase(caseId: string) {
    this.router.navigate(['/case/', caseId]);
    this.navigatedToCaseEvent.emit();
    if ( this.minimizeOnSelectCase ) {
      const dialogRef = this.dialogHelperUtilService.getOpenDialogWithCaseIdAndType(null, MinimizableDialogType.CaseNew);
      if ( dialogRef ) {
        this.dialogStateService.minimize(dialogRef.id);
      }
    }
    else {
      this.closed.emit();
      this.dialogHelperUtilService.closeModal();
      this.modalService.dismissAll();
    }
  }

  selectTask(taskId: number) {
    this.taskService.getWebTaskById(taskId).subscribe((taskViewModel: any) => {
      const dialogRef = this.dialogViewerService.openTaskExternalDialog(taskViewModel, true, true, true, true, () => this.closed.emit());
      if ( dialogRef ) {
        setTimeout(() => {
          this.dialogStateService.sendToFront(dialogRef.id);
        }, 0);
      }
    });
  }

  onEmailValueChange(value: string): void {
    this.isEmailInvalid = this.isExternalTaskSearch ? false : this.inputValidationService.isValidated(value, ValidationTypeEnum.emailAndValidOrEmpty);
  }

  get disableUpdateButtonPopover(): boolean {
    return !this.isUpdateExistingCaseDisabled();
  }

  get disableCreateButtonPopover(): boolean {
    return !this.isEmptySearchCase() && this.isAnySearchPerformed;
  }

  isWebTaskResult(searchCaseResult: SearchCaseResult): boolean {
    return !!searchCaseResult.taskId && !searchCaseResult.webTaskCaseValidated;
  }

  private formatCaseNumber(): void {
    if ( this.searchCase.caseNumber ) {
      const match = this.searchCase.caseNumber.match(/^([A-Za-z]{4})?-?(\d{3})?-?(\d{3})?-?(\d{3})?$/i);
      if (match && match[2] && match[3] && match[4]) {
        let str = match[1] ? match[1].toUpperCase() : '';
        str = str + (str ? '-' : '') + (!match[2] ? '000' : this.zeroPad(match[2]));
        str = str + (str ? '-' : '') + (!match[3] ? '000' : this.zeroPad(match[3]));
        str = str + (str ? '-' : '') + (!match[4] ? '000' : this.zeroPad(match[4]));

        this.searchCase.caseNumber = str;
      }
    }
  }

  private zeroPad(input: string) {
    return ('000' + input).slice(-3);
  }

  private filterList() {
    this.filteredCases = [];
    this.filteredCases = this.searchCaseResult.filter(
      searchCaseResult =>
        searchCaseResult.caseCreationDate ||
        searchCaseResult.caseNumber ||
        searchCaseResult.endUserAge ||
        searchCaseResult.policyHolderName ||
        searchCaseResult.endUserName
    );
    this.filteredCases.sort(this.compareValues('caseCreationDate', 'asc'));
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  private createDefaultSearchCase(): SearchCase {
    const searchCase = this.createEmptySearchCase();
    searchCase.caseCreationDateFrom = moment().subtract(6, 'months');
    searchCase.channel = this.defaultChannel;
    return searchCase;
  }

  private createEmptySearchCase(): SearchCase {
    return new SearchCase({
      customer: null,
      policyRefNo: '',
      customerRefNo: '',
      customerProduct: null,
      nationalIdCountryCode: null,
      nationalId: '',
      dateOfBirth: null,
      firstName: '',
      surname: '',
      email: '',
      phoneNumberCountryCode: null,
      phoneNumberPrefix: '',
      phoneNumber: '',
      homeCountryCode: null,
      companyName: '',
      stakeholderSearch: StakeholderTypeEnum.endUser,
      caseNumber: '',
      casePhoneNumber: '',
      caseCreationDateFrom: null,
      caseCreationDateTo: null,
      casePhoneNumberIncludedInSearch: true,
      incidentCause: '',
      incidentLocation: '',
      incidentDate: null
    });
  }

  updateExistingCase(selectedCaseResult: SearchCaseResult) {
    this.showSpinner = true;
    this.taskService.moveTask(+this.taskViewModel.id, selectedCaseResult.caseNumber).pipe(
      finalize(() => this.showSpinner = false)
    )
    .subscribe(
      () => {
        this.selectCase(selectedCaseResult.caseId);
      }
    );
  }

  phoneNumberChanged($event: {phoneCountryCode: string; phonePrefix: string; phoneNumber: string}) {
    this.searchCase.phoneNumber = $event.phoneNumber;
    this.searchCase.phoneNumberCountryCode = $event.phoneCountryCode;
    this.searchCase.phoneNumberPrefix = $event.phonePrefix;

    this.phoneNumberCountry.code = $event.phoneCountryCode;
    this.phoneNumberCountry.phonePrefix = $event.phonePrefix;
  }

  private getChannelTypeName(channelTypeEnumValue: string) {
    return this.allChannelTypes?.find(c => c.id === channelTypeEnumValue)?.name;
  }

}

interface SeccaCase {
  id: string;
  caseNumber: string;
  stakeholders: any;
}
