import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CaseStateService} from '@secca/core/state-services/case-state.service';
import {CaseDialogViewerService} from '@secca/case/case-dialog-viewer.service';
import {Subscription, timer} from 'rxjs';
import {
  PermissionEnum,
  PermissionHideTypeEnum,
  RecoveryStatus,
  ShortcutEnum,
  SupplierInvoiceStatus
} from 'src/app/shared/models/enums';
import {SortDirective} from '@secca/shared/directives/sort.directive';
import {EconomyStatusBarValues} from '@secca/shared/models/economyStatusBarValues';
import {Case} from '@secca/shared/models/case';
import {DataService} from '@secca/core/services/data.service';
import {InsuranceService} from '@secca/core/services/insurance.service';
import {PermissionService} from '@secca/core/services/permission.service';
import {SettingsService} from '@secca/core/services/settings.service';
import {AutoUnsubscribe} from '@secca/shared/decorators/auto-unsubscribe';
import {MenuService} from '@secca/core/services/menu.service';
import {ShortcutService} from '@secca/core/services/shortcut.service';
import {CaseSelectedMenuEnum} from '@secca/shared/enums/case-selected-menu-enum.component';
import { RecoveryService } from '@secca/core/services/recovery.service';
import { Recovery } from '@secca/shared/models/recovery';
import { RegresService } from '@secca/core/services/regres.service';
import { RecoveryEntryHistory } from '@secca/shared/models/recoveryEntryHistory';
import { PlanService } from '@secca/core/services/plan.service';

@AutoUnsubscribe
@Component({
  selector: 'app-case-economy-recovery',
  templateUrl: './case-economy-recovery.component.html',
  styleUrls: ['./case-economy-recovery.component.scss'],
})
export class CaseEconomyRecoveryComponent implements OnInit, OnDestroy {
  @ViewChild(SortDirective) sortDirective;

  recoveredAmount: number;
  appliedAmount: number;
  customerProfileId: number;
  caseCurrency = 'DKK';
  numberOfStatus = 6;
  sortKey: string;
  recoveryList: Recovery[] = [];
  filteredRecoveryList: Recovery[] = [];
  $caseStakeholderSubscr: Subscription;

  @Input()
  set case(newCase: Case) {
    if(newCase) {
      this.theCase = newCase;
      this.onRefresh();
    }
  }
  get case(): Case {
    return this.theCase;
  }
  private theCase: Case;
  private regresConfiguration;
  private selectedMenuId: number;
  private shortcutSubscription: Subscription;

  constructor(
    private dataService: DataService,
    private permissionService: PermissionService,
    private recoveryService: RecoveryService,
    private dialogViewerService: CaseDialogViewerService,
    public settingsService: SettingsService,
    private insuranceService: InsuranceService,
    private caseStateService: CaseStateService,
    private menuService: MenuService,
    private shortcutService: ShortcutService,
    private regresService: RegresService,
    private planService: PlanService,
  ) {
    this.shortcutSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.AddEconomyRecovery }).subscribe(a => {
      this.selectedMenuId = this.menuService.getSelectedMenuId(parseInt(this.case.id, 10));
      if (this.selectedMenuId === CaseSelectedMenuEnum.CaseEconomy) {
        if (!this.disableAddRecovery()) {
          this.addNewRecovery();
        }
      }
    });
  }

  ngOnInit() {
    this.insuranceService.getPersonInsurance(this.case.id).subscribe(
      result => {
        if (result) {
          if (!!result.customerProfileId) {
            this.customerProfileId = result.customerProfileId;
            this.regresService.getCustomerProfileRegresConfiguration(this.customerProfileId).subscribe(configuration => {
              this.regresConfiguration = configuration;
            });
          }
        }
      },
      error => console.log(error)
    );

    this.planService.getBillingCurrencyForCase(+this.case.id).subscribe(result => {
      this.caseCurrency = result.billingCurrency;
    });
  }

  ngOnDestroy(): void {
    this.shortcutSubscription.unsubscribe();
  }

  onRefresh() {
    if (this.theCase) {
      if (this.permissionService.checkUserPermission(PermissionEnum.RECOVERY_READ)) {
        this.reset();
        this.recoveryService.getRecoveryByCaseId(parseInt(this.theCase.id)).subscribe(recoveryList => {
          this.reset();
          for (const recovery of recoveryList) {
            this.filteredRecoveryList.push(recovery);
            this.recoveryList.push(recovery);
          }
          setTimeout(() => {
            this.filteredRecoveryList.sort(function (a, b) {
              return a.id - b.id;
            });
          }, 500);

          if (this.dataService.navigateRecoveryId) {
            const taskRecoveryId = this.dataService.navigateRecoveryId;
            const recoveryClicked = this.recoveryList.filter(entry => entry.id === taskRecoveryId)[0];
            this.dataService.navigateRecoveryId = null;
            if ( !!recoveryClicked ) {
              timer(300).subscribe(t => this.openManageRecovery(recoveryClicked));
            }
          }
        });
      }
    }
  }

  getGreenIndexHistoryMap(entry: Recovery): Map<number, RecoveryEntryHistory> {
    const indexMap = new Map<number, RecoveryEntryHistory>();
    if (!!entry?.history) {
      for (const history of entry.history) {
        const statusValues = this.getStatusIndexByStatus(history.status);
        if (statusValues.statusIndex > 0) {
          indexMap.set(statusValues.statusIndex, history);
        }
      }
    }
    return indexMap;
  }

  getRedIndexHistoryMap(entry: Recovery): Map<number, RecoveryEntryHistory> {
    const indexMap = new Map<number, RecoveryEntryHistory>();
    if (!!entry?.history) {
      for (const history of entry.history) {
        const statusValues = this.getStatusIndexByStatus(history.status);
        if (!!statusValues.redIconIndex) {
          indexMap.set(statusValues.redIconIndex, history);
        }
      }
    }
    return indexMap;
  }

  getStatusIndexByStatus(status: string): EconomyStatusBarValues {
    const statusValues = new EconomyStatusBarValues();
    statusValues.redIconIndex = null;
    statusValues.warnIconIndex = null;
    statusValues.statusIndex = -1;
    Recovery.setStatusIndex(statusValues, status);
    return statusValues;
  }

  getStatusValues(entry: Recovery): EconomyStatusBarValues {
    return this.getStatusIndexByStatus(entry.recoveryStatus);
  }

  openManageRecovery(recovery: Recovery) {
    let readOnly = false;
    if (!this.permissionService.checkUserPermission(PermissionEnum.RECOVERY_UPDATE)) {
      readOnly = true;
    }

    this.dialogViewerService.openManageRecoveryDialog(
      this.case.id,
      this.customerProfileId.toString(),
      recovery.id.toString(),
      readOnly,
      this.case.caseCreatedDate,
      () => this.recoverySaved(),
      () => this.recoveryDeleted()
    );
  }

  recoverySaved() {
    this.onRefresh();
  }

  recoveryDeleted() {
    this.onRefresh();
  }

  setSortKey(key: string) {
    this.sortKey = key;
  }

  addNewRecovery() {
    this.openCreateNewRecoveryDialog(false);
  }

  openCreateNewRecoveryDialog(objection: boolean){
    this.dialogViewerService.openManageRecoveryDialog(
      this.case.id,
      this.customerProfileId.toString(),
      null,
      false,
      this.case.caseCreatedDate,
      () => this.recoverySaved(),
      () => this.recoveryDeleted()
    );
  }

  disableAddRecovery() {
    return !this.hasRegresConfiguration || this.caseStateService.isCaseClosed || this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer;
  }

  disableAddRecoveryHover() {
    return this.hasRegresConfiguration || this.caseStateService.isCaseClosed || this.caseStateService.isInvoiceClosedWithNoInvoicingToCustomer;
  }

  get totalAppliedAmount() {
    return this.filteredRecoveryList.reduce((total, current) => total + current.totalAppliedAmount, 0);
  }

  get totalRecoveredAmount() {
    return this.filteredRecoveryList.reduce((total, current) => total + current.totalRecoveredAmount, 0);
  }

  get hasRegresConfiguration() {
    return !!this.regresConfiguration;
  }

  get SupplierInvoiceStatus() {
    return SupplierInvoiceStatus;
  }

  get RecoveryStatus() {
    return RecoveryStatus;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }

  private reset() {
    this.recoveryList = [];
    this.filteredRecoveryList = [];
    this.recoveredAmount = null;
    this.appliedAmount = null;
  }

}
