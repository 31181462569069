import { LocationService } from '@secca/core/services/location.service';
import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { CaseService } from '@secca/core/services/case.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StakeholderType } from 'src/app/modules/case/components/case-stakeholders/stakeholderType';
import { ProviderService } from '@secca/core/services/providerService';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { ProviderSearch } from '@secca/shared/models/ProviderSearch';
import { Case } from '@secca/shared/models/case';
import { UserDto } from '@secca/shared/models/userDto';
import { ProviderResponse } from '@secca/shared/models/ProviderResponse';
import { ProviderHelper } from '@secca/shared/helpers/provider-helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import {combineLatest, interval, Subscription, throwError, timer} from 'rxjs';
import { switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { ProviderModifiedResponseDto } from '@secca/shared/models/provider-modified-status';
import { TableCaseStakeholder } from '@secca/case/components/case-stakeholders/case-stakeholders-table/tableCaseStakeholder';
import { CasePrimaryContactService } from '@secca/case/components/case-primary-contact/case-primary-contact.service';
import { EndUserStateService } from '@secca/core/state-services/enduser-state-service';
import { StakeholderSortOnType } from '@secca/shared/models/stakeholderEnumSort';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { SettingsService } from '@secca/core/services/settings.service';
import { CaseTypeCodes } from '@secca/shared/models/case-type';
import {CoordinationDetailsService} from '@secca/core/services/coordination-details.service';
import {Person} from '@secca/shared/models/person';
import { CaseDialogViewerService } from "@secca/case/case-dialog-viewer.service";
import { DialogStateService } from "@secca/core/state-services/dialog-state.service";
import {
  SupplierDialogData
} from "@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/SupplierDialogData";

@Component({
  selector: 'app-case-stakeholders-table',
  templateUrl: './case-stakeholders-table.component.html',
  styleUrls: ['./case-stakeholders-table.component.scss']
})
@AutoUnsubscribe
export class CaseStakeholdersTableComponent implements OnInit, OnDestroy {
  tableCaseStakeholders: TableCaseStakeholder[];
  filteredStakeholders: TableCaseStakeholder[];
  expanded: boolean[];
  filteringType: StakeholderType[];
  isLegalGuardianAvailable: boolean;
  numberOfStakeholder: number;
  providerURL: string;
  case: Case;
  isEndUserCurrentLocation: boolean;
  private loggedInUser: UserDto;
  private stakeholderTypes: StakeholderType[];
  private providerResponse: ProviderResponse;
  isStakeholderDeletable = [];
  private providerHelper: ProviderHelper;
  newCaseStakeholderId: string;
  private pollingActive: boolean;
  private pollingCheckTime: Date;
  private polledStakeholders: number[];
  private pollingInterval = 1500;
  private pollingTimeoutMillis = 5 * 60 * 1000;
  private $stakeholderPollSubscr: Subscription;
  coordinationStakeholder: TableCaseStakeholder;
  working = false;

  @Input()
  set caseId(newCaseId: string) {
    this.theCaseId = newCaseId;
  }

  get caseId(): string {
    return this.theCaseId;
  }

  private theCaseId: string;
  private providerText: ProviderSearch;

  constructor(
    private caseService: CaseService,
    private dictionaryService: DictionaryService,
    private stakeholderService: StakeholderService,
    private providerService: ProviderService,
    private locationService: LocationService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private primaryContactService: CasePrimaryContactService,
    private endUserStateService: EndUserStateService,
    private coordinationService: CoordinationDetailsService,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    public caseStateService: CaseStateService,
    public settingsService: SettingsService,
    private dialogViewerService: CaseDialogViewerService,
    private dialogStateService: DialogStateService
  ) {
    this.providerURL = this.providerService.getProviderURL();
  }

  ngOnInit() {
    this.reloadData();
    this.getLoggedInUser();
    this.polledStakeholders = [];
    this.pollingActive = false;
  }

  ngOnDestroy(): void {
    this.pollingActive = false;
  }

  private getLoggedInUser() {
    // setTimeout(() => {
      this.dictionaryService.getLoggedInUser().subscribe(result => {
        this.loggedInUser = result;
      });
    // }, 50);
  }

  reloadData() {
    this.expanded = [];
    combineLatest([
      this.caseService.getAllStakeholdersOnCase(this.caseId),
      this.dictionaryService.getStakeholdersTypes(),
      this.caseService.getCase(this.caseId),
    ]).subscribe(res => {
      const result = res[0];
      this.stakeholderTypes = res[1];
      this.case = res[2];
      if (this.case.caseTypeCode === CaseTypeCodes.INTERNAL_COORDINATION) {
        this.coordinationStakeholder = new TableCaseStakeholder({
          tableRole: 'ICC', sortId: 0,
          caseStakeholder: new CaseStakeholder({person: new Person()})
        });
        this.fillInCoordinationCaseStakeholder(this.caseId);
      }
      this.isStakeholderDeletable = [];
      this.handleStakeholders(result);
      this.checkIsLegalGuardianAvailable();
      this.determineRegionStateAndLocalTimeZone();
      this.filterList();
    });
  }

  private handleStakeholders(stakeholders: CaseStakeholder[]) {
    this.tableCaseStakeholders = stakeholders.map(sh => new TableCaseStakeholder({caseStakeholder: sh, tableRole: sh.stakeholderType}));
    if (this.coordinationStakeholder) {
      this.tableCaseStakeholders = this.tableCaseStakeholders.filter(
          tsh => !['END_USER', 'POLICYHOLDER', 'REPORTER'].includes(tsh.tableRole)
      );
      this.tableCaseStakeholders.push(this.coordinationStakeholder);
      return;
    }


    const basics = this.tableCaseStakeholders.filter(
      sh => ['END_USER', 'POLICYHOLDER', 'REPORTER'].indexOf(sh.caseStakeholder.stakeholderType) > -1
    );
    if (basics.length < 3) {
      basics.forEach(sh => {
        const props = sh.caseStakeholder.properties || [];
        if (props.indexOf('END_USER') > -1) {
          this.tableCaseStakeholders.push(new TableCaseStakeholder({tableRole: 'END_USER', caseStakeholder: sh.caseStakeholder}));
        }
        if (props.indexOf('POLICYHOLDER') > -1) {
          this.tableCaseStakeholders.push(new TableCaseStakeholder({tableRole: 'POLICYHOLDER', caseStakeholder: sh.caseStakeholder}));
        }
        if (props.indexOf('REPORTER') > -1) {
          this.tableCaseStakeholders.push(new TableCaseStakeholder({tableRole: 'REPORTER', caseStakeholder: sh.caseStakeholder}));
        }
      });
    }
  }

  merge(stakeholder: TableCaseStakeholder, mergeWithType: string) {
    this.working = true;
    let absorbing: string;
    let absorbed: string;
    if (stakeholder.tableRole !== stakeholder.caseStakeholder.stakeholderType) {
      absorbing = mergeWithType;
      absorbed = stakeholder.tableRole;
    } else {
      absorbing = stakeholder.tableRole;
      absorbed = mergeWithType;
    }
    this.caseService.mergeStakeholders(this.caseId, absorbing, absorbed).subscribe(
      result => {
        if (mergeWithType === 'POLICYHOLDER') {
          this.caseService.publishPolicyholder(result);
        }
        if (mergeWithType === 'END_USER') {
          this.endUserStateService.sendEndUser(result);
        }
        this.caseStateService.updatedStakeholder(result);
        this.reloadData();
      }
    );
  }

  unmerge(stakeholder: TableCaseStakeholder, unmergeType: string) {
    this.working = true;
    let existing;
    let emerging;
    if (stakeholder.tableRole !== stakeholder.caseStakeholder.stakeholderType) {
      existing = unmergeType;
      emerging = stakeholder.tableRole;
    } else {
      existing = stakeholder.tableRole;
      emerging = unmergeType;
    }
    this.caseService.unmergeStakeholders(this.caseId, existing, emerging).subscribe(
      ignore => {
        this.reloadData();
        this.caseService.getAllStakeholdersOnCase(this.caseId).subscribe(
          stakeholders => {
            stakeholders = stakeholders.filter(item => item.hasRole(emerging));
            this.caseStateService.updatedStakeholder(stakeholders[0]);
            this.working = false;
          }, error => {
            this.working = false;
            throw error;
          }
        );
      }
    );
  }

  searchSupplier(stakeholder: CaseStakeholder) {
    this.contentForProvider(stakeholder);
  }

  determineRegionStateAndLocalTimeZone() {
    this.tableCaseStakeholders.forEach(tableStakeholder => {
      const stakeholder = tableStakeholder.caseStakeholder;
      if (stakeholder?.address?.latitude && stakeholder?.address?.longitude) {
        this.locationService.requestTimeZoneId(stakeholder.address.latitude, stakeholder.address.longitude).subscribe(
          result => {
            stakeholder.timezone = result.rawOffset / 3600;
          }
        );
      }
    });
  }

  togglePrimaryContact(stakeholder: TableCaseStakeholder): void {
    this.checkIsPrimaryContactAvailable(stakeholder);
    this.caseService.updatePrimaryContact(this.caseId, stakeholder.caseStakeholder.id).subscribe(
      ignore => this.reloadData()
    );
  }

  toggleLegalGuardian(stakeholder: TableCaseStakeholder): void {
    this.caseService.updateLegalGuardian(this.caseId, stakeholder.caseStakeholder.id).subscribe(
      ignore => {
        this.reloadData();
        this.caseStateService.sendLegalGuardian();
      }
    );
  }

  toggleCoTraveller(stakeholder: TableCaseStakeholder): void {
    this.caseService.updateCoTraveler(this.caseId, stakeholder.caseStakeholder.id).subscribe(
      ignore => this.reloadData()
    );
  }

  stakeholderNationalIdChange(caseStakeholder: CaseStakeholder) {
    const oldStakeholder = this.tableCaseStakeholders.find(a => a.caseStakeholder.id === caseStakeholder.id).caseStakeholder;
    oldStakeholder.person.nationalId = caseStakeholder.person.nationalId;
    oldStakeholder.person.dateOfBirth = caseStakeholder.person.dateOfBirth;
    oldStakeholder.person.firstName = caseStakeholder.person.firstName;
    oldStakeholder.person.surname = caseStakeholder.person.surname;
    oldStakeholder.person.gender = caseStakeholder.person.gender;
  }

  checkIsLegalGuardianAvailable() {
    this.isLegalGuardianAvailable = this.tableCaseStakeholders
      .filter(a => a.caseStakeholder?.legalGuardian && !a.caseStakeholder.company)
      .length === 0;
  }

  checkIsPrimaryContactAvailable(selectedStakeholder: TableCaseStakeholder) {
    if (selectedStakeholder) {
      selectedStakeholder.caseStakeholder.primaryContact = !selectedStakeholder.caseStakeholder.primaryContact;
      this.primaryContactService.updatePrimary(selectedStakeholder.tableRole, selectedStakeholder.caseStakeholder);
    }
  }

  rowClick(index: number) {
    this.expanded[index] = !this.expanded[index];
    if (!this.expanded[index]) {
      this.reloadData();
    }
    if (this.newCaseStakeholderId) {
      setTimeout(() => {
        if (this.settingsService.isZoomed()) {
          document.querySelector('body').style.removeProperty('zoom');
        }
        document.getElementById('newCaseStakeholder').scrollIntoView();
        this.newCaseStakeholderId = null;
        if (this.settingsService.isZoomed()) {
          document.querySelector('body').style.setProperty('zoom', '110%');
        }
      }, 500);
    }
  }

  checkIfStakeholderDeletable(caseId: string, stakeholderId: string, idx: number) {
    if ('ICC' === stakeholderId) {
      return false;
    }
    this.caseService.isStakeholderDeletable(caseId, stakeholderId).subscribe(
      result => {
        const isDeletable = !!result;
        this.isStakeholderDeletable[idx] = isDeletable;
        return isDeletable;
      },
      error => console.log(error)
    );
  }

  deleteStakeholder(index: number, event: any) {
    if (this.isStakeholderDeletable[index]) {
      event.preventDefault();
      event.stopPropagation();
      const stakeholder = this.tableCaseStakeholders[index].caseStakeholder;
      this.removeFromPolledStakeholders(+stakeholder.id);
      this.caseService.deleteStakeholderOnCase(this.caseId, stakeholder.id).subscribe(
        ignore => {
          this.expanded[index] = false;
          this.tableCaseStakeholders.splice(index, 1);
          this.caseStateService.updatedStakeholder(stakeholder);
          this.reloadData();
          this.caseStateService.sendProvidersUpdated();
        },
        error => console.log(error)
      );
    }
  }
  canChangeActiveStateOnStakeholder(index: number): boolean {
    const stakeholder= this.tableCaseStakeholders[index].caseStakeholder;
    return (stakeholder.properties != null && stakeholder
      .properties.filter(value => ['END_USER', 'POLICYHOLDER', 'REPORTER'].includes(value)).length == 0)
      && !['END_USER', 'POLICYHOLDER', 'REPORTER'].includes(stakeholder.stakeholderType);
  }
  changeActiveStateOnStakeholder(index: number, event: any, disable: boolean) {
    const stakeholder = this.tableCaseStakeholders[index].caseStakeholder;
    stakeholder.disabled = disable;
    event.preventDefault();
    event.stopPropagation();
    this.caseService.updateStakeholderForCase(stakeholder);
  }

  getStakeholderName(stakeholderType: string): string {
    return this.translateService.instant('case-stakeholder-type.' + stakeholderType);
  }

  getIconName(stakeholderType: string): string {
    if ('ICC' === stakeholderType) {
      return 'service-order-type/Coordination transport.svg';
    }
    return this.stakeholderService.addIconToCaseStakeholder(stakeholderType) + '.svg';
  }

  private filterList() {
    this.filteredStakeholders = [];
    this.tableCaseStakeholders.forEach(tableCaseStakeholder => {
      if (tableCaseStakeholder.tableRole === 'ICC') { return; }
      const theType = this.stakeholderTypes.find(type => type.stakeholderTypeEnum === tableCaseStakeholder.tableRole);
      tableCaseStakeholder.sortId = StakeholderSortOnType.get(theType.stakeholderTypeEnum);
    });

    this.tableCaseStakeholders.sort((a, b) => (a.sortId >= b.sortId || a.caseStakeholder.deactivated ? 1 : -1));

    if (this.filteringType) {
      this.filteringType.forEach(type => {
        const typeStakeholders = this.tableCaseStakeholders.filter(
          theStakeholder => theStakeholder.tableRole === type.stakeholderTypeEnum
        );

        typeStakeholders.forEach(caseStakeholder => {
          this.filteredStakeholders.push(caseStakeholder);
        });
      });

    } else {
      this.filteredStakeholders = this.tableCaseStakeholders;
    }
    if (this.newCaseStakeholderId) {
      const indexOfNewStakeholder = this.filteredStakeholders.findIndex(x => x.caseStakeholder.id === this.newCaseStakeholderId);
      this.rowClick(indexOfNewStakeholder);
    }
    this.working = false;
  }

  private getStakeholderType(stakeholder: CaseStakeholder): number {
    const stakeholderType = this.stakeholderTypes.find(value => {
      return value.stakeholderTypeEnum === stakeholder.stakeholderType;
    });
    return stakeholderType.providerTypeId;
  }

  addNewType(type: string, openProviders: boolean) {
    if (!type) {
      if (this.settingsService.isNewServiceProviderEnabled()) {
        const data: SupplierDialogData = {caseId: +this.case.id, incidentId: this.case.incidentId} as SupplierDialogData;
        const matDialogRef = this.dialogViewerService.openSupplierSearchDialog(data);
        if ( matDialogRef ) {
          setTimeout(() => {
            this.dialogStateService.sendToFront(matDialogRef.id);
          }, 0);
          matDialogRef.afterClosed().subscribe(result =>  {
            this.reloadData();
          });
        }
      }
    } else {
      this.caseService.addStakeholderToCaseByType(this.caseId, type).subscribe(
        result => {
          this.numberOfStakeholder = this.tableCaseStakeholders.length;
          const caseStakeholder: CaseStakeholder = result;
          this.newCaseStakeholderId = caseStakeholder.id;
          this.caseStateService.updatedStakeholder(caseStakeholder);
          this.reloadData();
          if (openProviders) {
            this.callSearchProviderIfStakeholderIsCompany(Number(caseStakeholder.id));
          }
        },
        error => console.log(error)
      );
    }
  }

  callSearchProviderIfStakeholderIsCompany(stakeholderId: number) {
    this.stakeholderService.getStakeholder(stakeholderId).subscribe(result => {
      const caseStakeholder: CaseStakeholder = result;
      this.caseStateService.updatedStakeholder(caseStakeholder);
      if (caseStakeholder && caseStakeholder.isCompany && this.getStakeholderType(caseStakeholder)) {
        const stakeholderType: StakeholderType = this.stakeholderTypes.find(stk => {
          return stk.stakeholderTypeEnum === caseStakeholder.stakeholderType;
        });
        caseStakeholder.company.providerTypeId = stakeholderType.providerTypeId;
        this.searchSupplier(caseStakeholder);
      }
    });
  }

  private getCase() {
      this.caseService.getCase(this.caseId).subscribe(result => {
        this.case = result;
        if (result.caseTypeCode === CaseTypeCodes.INTERNAL_COORDINATION) {
          this.fillInCoordinationCaseStakeholder(this.caseId);
        }
      });
  }

  private openProviderModal(error: any) {
    this.providerHelper = new ProviderHelper(this.modalService);
    this.providerHelper.messageError(error, 'provider-error-modal-header', 'provider-error-title', 'provider-error-message');
  }

  private contentForProvider(caseStakeholder: CaseStakeholder) {
    this.isEndUserCurrentLocation = this.case.incident.currentCountry !== null && this.case.incident.currentCountry.length !== 0;

    this.providerText = this.createJSONProviderSearchParameter(caseStakeholder);
    this.providerService.createProviderSearch(this.providerText).subscribe(
      searchString => {
        if (searchString !== null) {
          this.providerResponse = searchString;
          this.pollUntilStakeholderUpdated(+caseStakeholder.id);
          window.open(this.providerURL + this.providerResponse.uniqueId);
        }
      },
      error => {
        this.openProviderModal(error);
      }
    );
  }

  private pollUntilStakeholderUpdated(caseStakeholderId: number) {
    this.addToPolledStakeholders(caseStakeholderId);
    if ( this.pollingActive ) {
      return;
    }
    this.pollingActive = true;
    this.pollingCheckTime = null;
    this.$stakeholderPollSubscr = interval(this.pollingInterval).pipe(
      switchMap(() => this.providerService.providerModified(+this.theCaseId, this.pollingCheckTime, this.polledStakeholders)),
      tap(result => this.handlePollResult(result)),
      takeWhile(() => this.pollingActive),
      takeUntil(timer(this.pollingTimeoutMillis).pipe(switchMap(() => throwError('Provider polling timeout!'))))
    )
    .subscribe(ignore => {},
      error => {
        console.log(error);
        this.polledStakeholders = [];
        this.pollingActive = false;
    });
  }

  private handlePollResult(response: ProviderModifiedResponseDto): void {
    if (response.modifiedStakeholders.length !== 0) {
      const modifiedStakeholders = response.modifiedStakeholders;
      const beforeLen = this.polledStakeholders.length;
      this.polledStakeholders = this.polledStakeholders.filter(p => !modifiedStakeholders.find(m => m.caseStakeholderId === p) );

      if ( this.polledStakeholders.length !== beforeLen ) {
        modifiedStakeholders.forEach(modifiedStakeholder => {
          const stakeholder = this.tableCaseStakeholders.find(a => +a.caseStakeholder.id === modifiedStakeholder.caseStakeholderId).caseStakeholder;
          this.caseStateService.updatedStakeholder(stakeholder);
        });
        this.reloadData();

        this.filteredStakeholders.forEach((s, index) => {
          if (modifiedStakeholders.find(m => m.caseStakeholderId === +s.caseStakeholder.id)) {
            this.expanded[index] = true;
          }
        });
      }
    }

    this.pollingCheckTime = response.lastCheckTime;

    if ( this.polledStakeholders.length === 0 ) {
      this.pollingActive = false;
    }
  }

  private addToPolledStakeholders(caseStakeholderId: number): void {
    if ( !this.polledStakeholders.find(p => p === caseStakeholderId) ) {
      this.polledStakeholders.push(caseStakeholderId);
    }
  }

  private removeFromPolledStakeholders(caseStakeholderId: number): void {
    this.polledStakeholders = this.polledStakeholders.filter(p => p !== caseStakeholderId);
  }

  private createJSONProviderSearchParameter(caseStakeholder: CaseStakeholder) {
   return new ProviderSearch({
      caseNumber: null !== this.case.caseNumber ? this.case.caseNumber : this.translateService.instant('provider-call-without-case-number'),
      caseId: this.case.id,
      stakeholderId: caseStakeholder.id,
      webUser: this.loggedInUser.initials,
      clientLocation: {
        cityName: this.isEndUserCurrentLocation ? this.case.incident.currentCity : this.case.incident.incidentCity,
        country: {
          name: this.isEndUserCurrentLocation ? this.case.incident.currentCountry : this.case.incident.incidentCountry,
        },
      },
      searchParameters: {
        providerType: (caseStakeholder.company && caseStakeholder.company.providerTypeId) ?
                      caseStakeholder.company.providerTypeId.toString() : '',
        name: '',
        service: '',
        contactDetail: '',
        city: '',
        countryCode: '',
      },
      integrationInformation: {
        showIntegration: true,
        WebfileWaitForBrowser: false,
        showSearch: true,
        textHeader: this.isEndUserCurrentLocation
          ? this.translateService.instant('provider-patient-current-location')
          : this.translateService.instant('provider-patient-incident-location'),
        textBody: this.translateService.instant('provider-please-choose-the-required-provider'),
      },
    });
  }

  filterByType(types: StakeholderType[]) {
    this.filteringType = types;
    this.filterList();
  }

  getHospitalIconPrivate(privateCompany) {
    if (privateCompany !== null && privateCompany !== undefined && privateCompany !== false) {
      return '/assets/icons/provider/hosp_private_' + privateCompany + '_24x24.svg';
    } else {
      return '/assets/icons/provider/hosp_private_false_24x24.svg';
    }
  }

  getHospitalIconPublic(publicCompany) {
    if (publicCompany !== null && publicCompany !== undefined) {
      return '/assets/icons/provider/hosp_public_' + publicCompany + '_24x24.svg';
    } else {
      return '/assets/icons/provider/hosp_public_false_24x24.svg';
    }
  }

  setOwnerTypePublic(stakeholder: CaseStakeholder) {
    if (stakeholder.company.publicCompany === false || stakeholder.company.publicCompany === null) {
      stakeholder.company.publicCompany = true;
    } else {
      stakeholder.company.publicCompany = false;
    }
    this.caseService.updateStakeholderForCase(stakeholder);
  }

  setOwnerTypePrivate(stakeholder: CaseStakeholder) {
    if (stakeholder.company.privateCompany === false || stakeholder.company.privateCompany === null) {
      stakeholder.company.privateCompany = true;
    } else {
      stakeholder.company.privateCompany = false;
    }
    this.caseService.updateStakeholderForCase(stakeholder);
  }

  clickPrivateCompany(stakeholder: CaseStakeholder) {
    event.preventDefault();
    event.stopPropagation();
    this.setOwnerTypePrivate(stakeholder);
  }

  clickPublicCompany(stakeholder: CaseStakeholder) {
    event.preventDefault();
    event.stopPropagation();
    this.setOwnerTypePublic(stakeholder);
  }

  viewTreatmentIcons(stakeholder: CaseStakeholder) {
    return this.stakeholderService.displayTreatmentAndProviderRatingIcons(stakeholder);
  }

  private fillInCoordinationCaseStakeholder(caseId: string) {
    return this.coordinationService.getCoordinationDetails(caseId).subscribe(result => {
      this.coordinationStakeholder.caseStakeholder.id = 'ICC';
      this.coordinationStakeholder.caseStakeholder.person.firstName = result.name;
      this.coordinationStakeholder.caseStakeholder.person.remarks = result.remarks;
    });
  }
}
